<p-dialog header="Dados Adicionais do Parcelamento" [(visible)]="visible" [style]="{ width: '50vw', height: '80vw' }"
    [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <div class="dialog-content p-fluid p-grid" style="margin-top: 1rem;">
        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="ruc">RUC solicitante do Parcelamento</label>
                <input id="ruc" type="text" class="p-inputtext p-component" value="999999999" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="numero-parcelamento">Número do Parcelamento</label>
                <input id="numero-parcelamento" type="text" class="p-inputtext p-component" value="999999999" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="data-geracao">Data de Geração do Parcelamento</label>
                <input id="data-geracao" type="text" class="p-inputtext p-component" value="11/10/2024" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="valor-parcelado">Valor Parcelado</label>
                <input id="valor-parcelado" type="text" class="p-inputtext p-component" value="999,99" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="valor-debito">Valor do Débito de Origem</label>
                <input id="valor-debito" type="text" class="p-inputtext p-component" value="999,99" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="quantidade-parcelas">Quantidade de Parcelas</label>
                <input id="quantidade-parcelas" type="text" class="p-inputtext p-component" value="10" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="usuario-que-gerou">Usuário que Gerou o Parcelamento</label>
                <input id="usuario-que-gerou" type="text" class="p-inputtext p-component" value="xxxxxxxx" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="tipo-parcelamento">Tipo de Parcelamento</label>
                <input id="tipo-parcelamento" type="text" class="p-inputtext p-component" value="xxxxxxxx" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="valor-multa">Valor da Multa Parcelada</label>
                <input id="valor-multa" type="text" class="p-inputtext p-component" value="999,99" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="valor-juros">Valor do Juros Parcelado</label>
                <input id="valor-juros" type="text" class="p-inputtext p-component" value="999,99" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="valor-honorarios">Valor Total de Honorários</label>
                <input id="valor-honorarios" type="text" class="p-inputtext p-component" value="999,99" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="origem-parcelamento">Origem do Parcelamento</label>
                <input id="origem-parcelamento" type="text" class="p-inputtext p-component" value="xxxxxxxx" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="localizacao-parcelamento">Localização de Parcelamento</label>
                <input id="localizacao-parcelamento" type="text" class="p-inputtext p-component" value="999,99" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="codigo-autenticidade">Código de Autenticidade</label>
                <input id="codigo-autenticidade" type="text" class="p-inputtext p-component" value="xxxxxxxx" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="divida-ativa">Dívida Ativa</label>
                <input id="divida-ativa" type="text" class="p-inputtext p-component" value="xxxxxxxx" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="numero-documento">Número de Documento Parcelado</label>
                <input id="numero-documento" type="text" class="p-inputtext p-component" value="99999999" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="numero-conta">Número da Conta Parcelada</label>
                <input id="numero-conta" type="text" class="p-inputtext p-component" value="99999999" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="data-vencimento">Data de Vencimento da Conta Parcelada</label>
                <input id="data-vencimento" type="text" class="p-inputtext p-component" value="14/10/2024" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="valor-principal">Valor Principal da Conta Parcelada</label>
                <input id="valor-principal" type="text" class="p-inputtext p-component" value="999,99" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="valor-multa-conta">Valor da Multa da Conta Parcelada</label>
                <input id="valor-multa-conta" type="text" class="p-inputtext p-component" value="999,99" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="valor-juros-conta">Valor dos Juros da Conta Parcelada</label>
                <input id="valor-juros-conta" type="text" class="p-inputtext p-component" value="999,99" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="percentual-honorarios">Percentual dos Honorários</label>
                <input id="percentual-honorarios" type="text" class="p-inputtext p-component" value="999,99" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="juros-anterior">Valor de Juros Anterior</label>
                <input id="juros-anterior" type="text" class="p-inputtext p-component" value="999,99" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="multa-anterior">Valor de Multa Anterior</label>
                <input id="multa-anterior" type="text" class="p-inputtext p-component" value="999,99" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="numero-identidade">Número Identidade</label>
                <input id="numero-identidade" type="text" class="p-inputtext p-component" value="999,99" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="percentual-ctate">Percentual CTATE</label>
                <input id="percentual-ctate" type="text" class="p-inputtext p-component" value="99" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="numero-parcela">Número da Parcela</label>
                <input id="numero-parcela" type="text" class="p-inputtext p-component" value="10" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="data-vencimento-parcela">Data de Vencimento da Parcela</label>
                <input id="data-vencimento-parcela" type="text" class="p-inputtext p-component" value="15/10/2024" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="numero-parcelamento-2">Número do Parcelamento</label>
                <input id="numero-parcelamento-2" type="text" class="p-inputtext p-component" value="99999999" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="valor-original-principal">Valor Original Principal da Parcela</label>
                <input id="valor-original-principal" type="text" class="p-inputtext p-component" value="999,99" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="valor-original-multa-parcela">Valor Original da Multa da Parcela</label>
                <input id="valor-original-multa-parcela" type="text" class="p-inputtext p-component" value="999,99" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="valor-original-juros-parcela">Valor Original dos Juros da Parcela</label>
                <input id="valor-original-juros-parcela" type="text" class="p-inputtext p-component" value="999,99" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="numero-identificacao-parcela">Número de Identificação da Parcela</label>
                <input id="numero-identificacao-parcela" type="text" class="p-inputtext p-component" value="999,99" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="valor-honorarios-parcela">Valor dos Honorários da Parcela</label>
                <input id="valor-honorarios-parcela" type="text" class="p-inputtext p-component" value="999,99" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="estado-conta">Estado da Conta Parcelada na Data do Parcelamento</label>
                <input id="estado-conta" type="text" class="p-inputtext p-component" value="xxxxxxxx" readonly>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-field">
                <label for="data-vencimento-conta-parcela">Data de Vencimento da Conta Parcelada</label>
                <input id="data-vencimento-conta-parcela" type="text" class="p-inputtext p-component" value="15/10/2024" readonly>
            </div>
        </div>

    </div>

    <ng-template pTemplate="footer">
        <p-button (click)="hiddenDialog()" icon="pi pi-angle-left" label="Voltar" style="position: absolute; left: 10px; bottom: 10px;"></p-button>
    </ng-template>
</p-dialog>

<div class="card">
    <p-steps
        [model]="items"
        [(activeIndex)]="activeIndex"
        class="time-line"
    ></p-steps>
</div>

<p-card [style]="{ 'margin-top': '10px' }">
    <ng-template pTemplate="title">
        {{ tituloPage }}
    </ng-template>

    <p-table
        #dt1
        [value]="debitosList"
        [scrollable]="true"
        [globalFilterFields]="[
            'contaCorrenteId',
            'numeroDocumento',
            'periodoReferencia',
            'dataVencimento',
            'estadoContaDescricao',
            'saldoTotalAtualizado',
            'situacaoCreditoDesc',
            'situacaoCobrancaDesc'
        ]"
        dataKey="contaCorrenteId"
    >
        <ng-template pTemplate="caption">
            <div class="p-d-flex">
                <span class="p-input-icon-left p-ml-auto">
                    <i class="pi pi-search"></i>
                    <input
                        pInputText
                        type="text"
                        (input)="
                            dt1.filterGlobal($event.target.value, 'contains')
                        "
                        placeholder="Pesquisar na lista"
                    />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th class="td-icon"></th>
                <th pSortableColumn="contaCorrenteId">
                    Conta <p-sortIcon field="contaCorrenteId"></p-sortIcon>
                </th>
                <th pSortableColumn="numeroDocumento">
                    Nº do Documento
                    <p-sortIcon field="numeroDocumento"></p-sortIcon>
                </th>
                <th pSortableColumn="periodoReferencia">
                    Período de Referência
                    <p-sortIcon field="periodoReferencia"></p-sortIcon>
                </th>
                <th pSortableColumn="dataVencimento">
                    Vencimento <p-sortIcon field="dataVencimento"></p-sortIcon>
                </th>
                <th pSortableColumn="estadoContaDesc">
                    Estado da Conta
                    <p-sortIcon field="estadoContaDesc"></p-sortIcon>
                </th>
                <th pSortableColumn="saldoTotalAtualizado">
                    Saldo da Conta
                    <p-sortIcon field="saldoTotalAtualizado"></p-sortIcon>
                </th>
                <th pSortableColumn="situacaoCreditoDesc">
                    Situação do Crédito
                    <p-sortIcon field="situacaoCreditoDesc"></p-sortIcon>
                </th>
                <th pSortableColumn="situacaoCobrancaDesc">
                    Situação da Cobrança
                    <p-sortIcon field="situacaoCobrancaDesc"></p-sortIcon>
                </th>
                <th>Ações</th>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="body"
            let-item
            let-expanded="expanded"
            let-rowIndex="rowIndex"
        >
            <tr>
                <td class="td-icon">
                    <button
                        type="button"
                        pButton
                        pRipple
                        [pRowToggler]="item"
                        class="p-button-text p-button-rounded p-button-plain"
                        [icon]="
                            expanded
                                ? 'pi pi-chevron-down'
                                : 'pi pi-chevron-right'
                        "
                        (click)="expandeEnvento(rowIndex)"
                    ></button>
                </td>
                <td>
                    {{ item.configContaId }} -
                    {{ item?.configContaNomeResumido }}
                </td>
                <td>{{ item.numeroDocumento }}</td>
                <td>{{ converteMesAno(item.periodoReferencia) }}</td>
                <td>{{ item.dataVencimento | date : "dd/MM/yyyy" }}</td>
                <td>{{ item.estadoContaDesc }}</td>
                <td>{{ item.saldoTotalAtualizado | currency : "BRL" }}</td>
                <td>{{ item.situacaoCreditoDesc }}</td>
                <td>{{ item.situacaoCobrancaDesc }}</td>
                <td>
                    <i
                        (click)="openModal(valueContribuinte, item)"
                        pTooltip="Gerar Extrato"
                        tooltipPosition="top"
                        class="fa-solid fa-file-pdf selecao"
                        style="font-size: large; margin-right: 8px"
                    ></i>
                    <i
                        *ngIf="item?.dareBloqueado == 'NAO'"
                        (click)="lancarDare(item)"
                        pTooltip="Emitir DARE"
                        tooltipPosition="top"
                        class="fa-regular fa-file selecao"
                        aria-hidden="true"
                        style="font-size: large; margin-right: 8px"
                    ></i>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="rowexpansion" class="p-text-center">
            <ccp-detalhe-calculo-juros
                [conta]="debitosList[rowIndexConta]"
            ></ccp-detalhe-calculo-juros>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td class="p-text-center" colspan="10">
                    Nenhum registro encontrado
                </td>
            </tr>
        </ng-template>
    </p-table>

    <!-- <p-paginator
        [rows]="20"
        [rowsPerPageOptions]="[20, 50, 100]"
        [totalRecords]="pagination.totalElements"
        (onPageChange)="onPageChange($event)"
    ></p-paginator> -->

    <div
        class="grid grid-nogutter justify-content-end margin-bt"
        style="margin-top: 20px"
    >
        <button
            pButton
            label="Fechar"
            icon="pi pi-angle-left"
            class="p-mr-2"
            routerLink="/consultar-conta-veiculo"
        ></button>
    </div>
</p-card>

<modal-extrato-conta-corrente
    [abaSelecionada]="abaSelecionada"
></modal-extrato-conta-corrente>

<modal-alerta-honorario [(contaCorrenteId)]="contaCorrenteIdDare"></modal-alerta-honorario>

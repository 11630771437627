import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TransacaoService } from "src/app/component/transacao-financeira/services/transacao.service";
import { MemoriaCalculoModel } from "src/app/models/memoria-calculo.model";
import { UtilsService } from "src/app/shared/utils.service";
import { AlertsUtil } from "src/app/utils/alerts/alerts.util";
import { CriacaoContaCorrenteService } from "../../services/criacao-conta-corrente.service";
import { ConfigurarContaCorrenteService } from "src/app/component/configurar-conta-corrente/configurar-conta-corrente.service";

@Component({
    selector: "app-registrar-memoria-calculo",
    templateUrl: "./registrar-memoria-calculo.component.html",
    styleUrls: ["./registrar-memoria-calculo.component.scss"],
})
export class RegistrarMemoriaCalculoComponent implements OnInit {
    @Input() hiddenItensConfirm;
    @Output() outEtapas = new EventEmitter();

    memoriaCalculoModel: MemoriaCalculoModel = {
        configContaId: null,
        tipoCadastroId: null,
        lancarConta: null,
        codigoCadastro: null,
        criarCotaUnica: null,
        quantidadeParcelas: null,
        valorMinimoParcela: null,
        valorMinimoParcelamento: null,
        valorHonorario: null,
        numeroParcela: null,
        situacao: null,
        origemInformacaoId: null,
        datasReferencia: [],
        documento: {
            tipoDocumentoId: null,
            numeroDocumento: null,
        },
        anexo: {
            nomeAnexo: null,
            formato: null,
            conteudo: null,
        },
        configuracaoEventoId: null,
        naturezasCalculo: [],
        contasCorrentesVinculo: [] = []
    };
    eventos = [
        { chave: "1" },
        { chave: "2" }
    ];
    formatoDocumentoEnum = [
        {
            chave: "JSON",
            valor: "json",
        },
        {
            chave: "PDF",
            valor: "pdf",
        },
    ];
    datasReferenciaVisualizacao: any[]=[];
    title: string = "Configuração da Conta e Memória de Cálculo";
    dataReferencia: any;
    tipoCadastro: boolean = true;
    dataSelecionada: any;
    naturezaSelecionada: any;
    docSelecionado: any;
    formatoDocumetno;
    criarCota: any;
    labelBtnProximaESalvar: string = "Próxima";
    blockedDocument: boolean;
    memoriaCalculoForm: FormGroup;
    anexos: any[]=[];
    submitted: boolean;
    itensComposicaoMemoriaDeCalculo: any[] = [];
    submittedDatasReferencia: boolean;
    datasReferencia: any[] = [];
    validacaoDataReferencia: boolean;
    validacaoNaturezaCalculo: boolean;
    uploadedFiles: any[] = [];
    consultaContaObj: any = null;
    enumSimNao: any[] = [];
    arquivoUploadBasse64: any;
    tiposDatasReferenciaList: any[] = [];
    documentoList: any[] = [];
    naturezaList: any[] = [];
    /**conversao moeda  BR */
    mode: any;
    currency: string;
    locale: string;
    maskCustom: any = '999999999';
    maskInputOrigemCustom: any = '';
    labelCustom: string;
    habilitaTipoPessoa: boolean;
    labelCustomInput: string = '';
    habilitaInputCalendario: boolean;
    maxValueInputOrigem: any = 1;
    removeMask: boolean;
    regexNumberAndLetter: any;
    somenteLetrasNumeros: RegExp = /^[a-zA-Z0-9._\b]+$/;
    inputnotFormat: boolean;
    placeholderCustom: string;
    disabledOcorreuEnvento: boolean;
    validacaoOcorreuEvento: boolean;
    tipoCadastroList: any[];
    memoriaCalculoFormObj: any;
    contaAssociadaList: any;
    formatoArquivo: any;
    nomeArquivo: any;
    opcaoSelecionada: any;
    valueMask: boolean;
    objTpCadastroParam: { tpCadastroId: any; tpCadastroValue: any; };
    valorHonorario: number;
    numeroParcela: any;

    placeholderTipoCadastro='';
    maxLengthTipoCadastro=0;

    constructor(
        private utils: UtilsService,
        private alertUtil: AlertsUtil,
        private formbuilder: FormBuilder,
        private transacaoService: TransacaoService,
        private criacaoContaCorrenteService: CriacaoContaCorrenteService,
        private configurarContaCorrenteService: ConfigurarContaCorrenteService,
    ) {}

    ngOnInit(): void {
        this.mode = "currency";
        this.currency = "BRL";
        this.locale = "pt-BR";
        this.iniciaForm(this.memoriaCalculoFormObj);
        this.getEnunsEDominios();
        this.populaCampos();
    }

    populaCampos(){
        this.consultaContaObj = JSON.parse(localStorage.getItem("consultaContaObj"));
        this.memoriaCalculoFormObj = JSON.parse(localStorage.getItem("memoriaCalculoFormObj"));
        this.getTipoCadastro(this.consultaContaObj);

        /**Renderização Botão Próxima/Salvar */
        if(this.hiddenItensConfirm == 3){
            this.labelBtnProximaESalvar = "Salvar";
        } else {
            this.labelBtnProximaESalvar = "Próxima";
        }

        /**------------Formulário--------------- */
         /**Criação Conta*/
         if(this.memoriaCalculoFormObj === null){
            this.tiposDatasReferenciaList = [];
            if(this.consultaContaObj){
                if(this.consultaContaObj.configuracaoEventos.length > 0){
                    this.memoriaCalculoForm.controls.configuracaoEventosId.setValue(
                        this.consultaContaObj.configuracaoEventos[0].configuracaoEventosId
                        );
                    }
                this.tiposDatasReferenciaList = this.consultaContaObj.tipoDataReferencias;
                this.itensComposicaoMemoriaDeCalculo = this.consultaContaObj.configContaItensCalculo;
                }
            } else {
                this.tiposDatasReferenciaList = [];
                this.contaAssociadaList = JSON.parse(localStorage.getItem("contaAssociadaList"));
                this.memoriaCalculoModel = JSON.parse(localStorage.getItem("memoriaCalculoObjModel"));

                /**Edição conta */
                /**Popula Formulario */
                this.iniciaForm(this.memoriaCalculoFormObj);

                /**Popula Models */
                let datasReferenciaAux = []
                /**Conversão data para componence aceitar formato */
                this.memoriaCalculoModel.datasReferencia.forEach((element: any) => {
                    datasReferenciaAux.push({
                            tipoDataReferenciaId: element.tipoDataReferenciaId,
                            descricao: element.descricao,
                            dataInformada: this.utils.convertDateToEdit(element.dataInformada),
                            ocorreuEvento: element.ocorreuEvento
                    })
                    /**Recebe Valor Radio button */
                    if(element.ocorreuEvento == true) {
                        this.opcaoSelecionada = element.tipoDataReferenciaId;
                    }
                });
                /**DATA DE REFERÊNCIA EDIT */
                this.tiposDatasReferenciaList = datasReferenciaAux;

                if(this.memoriaCalculoModel.anexo){
                    this.anexos = [this.memoriaCalculoModel.anexo]
                }

                /**NATUREZA(ITEM COMPOISICAO) EDIT */
                this.itensComposicaoMemoriaDeCalculo = this.prepareNaturezaContaEdit(this.memoriaCalculoModel.naturezasCalculo);
                this.valorHonorario = this.memoriaCalculoModel.valorHonorario;
                this.numeroParcela = this.memoriaCalculoModel.numeroParcela;
            }
    }

    prepareNaturezaContaEdit(dataList: any){
        dataList.forEach((element, index) => {
            if(element.itemTipo == 'DATA'){
                element.valorOrigem = this.utils.convertDateToEdit(element.valorOrigem);
            }
        });
        return dataList;
    }

    async getEnunsEDominios() {
        this.enumSimNao = await this.transacaoService
            .getEnumSimNao()
            .toPromise();
        this.documentoList = await this.transacaoService
            .getTipoDocumento()
            .toPromise();
    }

    getTipoCadastro(consultaContaObj){
        this.tipoCadastroList = consultaContaObj.configContaTipoCadastros;
    }

    /**Reseta o valor do radio button setando na poiscao atual*/
    resetDatasReferenciaList(){
        this.tiposDatasReferenciaList.forEach((element, index) => {
            this.tiposDatasReferenciaList[index].ocorreuEvento = false;
        });
    }

    get memoriaFormControl() {
        return this.memoriaCalculoForm?.controls;
    }

    iniciaForm(memoriaCalculoFormObj?: any) {
        this.memoriaCalculoForm = this.formbuilder.group({
            tipoCadastro: [memoriaCalculoFormObj?.tipoCadastro ? memoriaCalculoFormObj?.tipoCadastro : null, Validators.required],
            inscEstadual: [memoriaCalculoFormObj?.inscEstadual ? memoriaCalculoFormObj?.inscEstadual : null, Validators.required],
            tipoDataReferencia: [null],
            dataReferencia: [null],
            booleanOcorreEvento: [null],
            tipoDocumento: [memoriaCalculoFormObj?.tipoDocumento ? memoriaCalculoFormObj?.tipoDocumento : null, Validators.required],
            numeroDocumento: [memoriaCalculoFormObj?.numeroDocumento ? memoriaCalculoFormObj?.numeroDocumento : null, Validators.required],
            numeroParcela: [memoriaCalculoFormObj?.numeroParcela ? memoriaCalculoFormObj?.numeroParcela : null],
            anexoArquivo: [memoriaCalculoFormObj?.anexoArquivo ? memoriaCalculoFormObj?.anexoArquivo : null],
            criarCotaUnica: [memoriaCalculoFormObj?.criarCotaUnica ? memoriaCalculoFormObj?.criarCotaUnica : 'NAO', Validators.required],
            qtdParcelas: [memoriaCalculoFormObj?.qtdParcelas ? memoriaCalculoFormObj?.qtdParcelas : null],
            valorMinimoParcela: [memoriaCalculoFormObj?.valorMinimoParcela ? memoriaCalculoFormObj?.valorMinimoParcela : null],
            valorMinimoParcelamento: [memoriaCalculoFormObj?.valorMinimoParcelamento ? memoriaCalculoFormObj?.valorMinimoParcelamento : null],
            naturezaCalculo: [memoriaCalculoFormObj?.naturezaCalculo ? memoriaCalculoFormObj?.naturezaCalculo : null],
            // valorOrigem: [memoriaCalculoFormObj?.valorOrigem ? memoriaCalculoFormObj?.valorOrigem : null],
            valorvalorDoCampo: [memoriaCalculoFormObj?.valorvalorDoCampo ? memoriaCalculoFormObj?.valorvalorDoCampo : null],
            valorHonorario: [memoriaCalculoFormObj?.valorHonorario ? memoriaCalculoFormObj?.valorHonorario : null],
            datasReferencia: [memoriaCalculoFormObj?.datasReferencia ? memoriaCalculoFormObj?.datasReferencia : null],
            configuracaoEventosId: [memoriaCalculoFormObj?.configuracaoEventosId ? memoriaCalculoFormObj?.configuracaoEventosId : null, Validators.required]
        });
    }

    insereValidacao() {
        if(this.consultaContaObj.permiteParcelar == '1' && (this.memoriaCalculoForm.get("valorMinimoParcela").value ||
         this.memoriaCalculoForm.get("valorMinimoParcelamento").value)){
             this.memoriaCalculoForm.get("qtdParcelas").setValidators(Validators.required);
        } else {
            this.memoriaCalculoForm.get("qtdParcelas").clearValidators();
        }
        this.memoriaCalculoForm.get("qtdParcelas").updateValueAndValidity();
    }

    /**navegação timeline */
    irRConsultaConta() {
        let activeIndex;
        if (this.hiddenItensConfirm == 1) {
            /**Index Configuração Conta */
            activeIndex = 0;
        } else {
            /**Index Contas ASSOCIADAS */
            activeIndex = 2;
        }
        this.outEtapas.emit(activeIndex);
    }

    irConfirmacaoOuSalvar() {
        // apagar
        // this.prepareObjEdit();

        /**Validações Criar/Editar Memória de calculo se etapa index 1 - Memória de Cálculo */
        if(this.hiddenItensConfirm == 1) {
            /**Set valor para objTp Cadastro */
            this.setValorTpCadastro();
            this.validacaoDataReferenciaOcorreuEvento();
            this.validaItensDeComposicaoList();
            this.verificaCamposDatas();

            /**Póroxima Aba */
            if (this.valiodacaoForm() &&
            this.validacaoDataReferencia === false &&
             this.validacaoOcorreuEvento === true &&
             this.validacaoNaturezaCalculo === false) {
                this.prepareObjEdit();
                this.outEtapas.emit(2);
            }
        } else {
            /**Salvar */
            this.salvarMemoriaDeCalculo();
        }
    }

    prepareObjEdit(){
        this.prepareItemsDeComposicaoObjEdit();
        this.prepareDatasReferenciaObjEdit();

        setTimeout(() => {
            this.montaObjSave();
            localStorage.setItem('memoriaCalculoFormObj', JSON.stringify(this.memoriaCalculoForm.value));
            localStorage.setItem('memoriaCalculoObjModel', JSON.stringify(this.memoriaCalculoModel));
        }, 500);
    }

    prepareDatasReferenciaObjEdit(){
            let listAux = [];
            this.tiposDatasReferenciaList.forEach(element => {
                listAux = [
                    ...listAux,
                    {
                        tipoDataReferenciaId: element.tipoDataReferenciaId,
                        dataInformada: this.utils.convertDateToSave(element.dataInformada),
                        descricao: element.descricao,
                        ocorreuEvento: element.ocorreuEvento
                    },
                ];
            });
            /**seta no form para validacao */
            this.memoriaCalculoForm.controls.datasReferencia.setValue(listAux);
    }

    /**Monta objt item composicao */
    prepareItemsDeComposicaoObjEdit(){
        let listAux = [];
            this.itensComposicaoMemoriaDeCalculo.forEach(element => {
                listAux = [
                    ...listAux,{
                        itemComposicaoId: element.itemComposicaoId,
                        valorOrigem: element.itemTipo == 'DATA'
                        ? this.utils.convertDateToSave(element.valorOrigem)
                        : element.valorOrigem,
                        itemCampo: element.itemCampo,
                        itemDescricao: element.itemDescricao,
                        itemTipo: element.itemTipo
                        }
                    ]
                });
        this.memoriaCalculoModel.naturezasCalculo = listAux;
    }

    /**Validacao erro personalizada para as listas */
    /**Devera haver pelomento um valor SIM no campo Ocorreu Evento */
    validacaoDataReferenciaOcorreuEvento() {
        let ocorreuErroSim: boolean = false;
        if(this.tiposDatasReferenciaList.find(
            (aux) =>
                aux.ocorreuEvento == true || aux.ocorreuEvento == 'SIM'
        )){
            this.validacaoOcorreuEvento = true;
        } else {
            this.validacaoOcorreuEvento = false;
        }
    }

    verificaCamposDatas(){
        /**Validacao somente para aba memoria calculo na posicao 1 do timiline */
        if(this.hiddenItensConfirm === 1){
            if(this.tiposDatasReferenciaList.find(
                (aux) =>
                    aux.dataInformada == null || aux.dataInformada == undefined
            )){
                 this.validacaoDataReferencia = true;
            } else {
                this.validacaoDataReferencia = false
            }
            /**Selecionar Radio ocorreu evendo caso houver somente uma data na lista */
            if(this.tiposDatasReferenciaList.length == 1){
                this.verificaEventoSim('MARCAR_RADIO', 0);
                this.memoriaCalculoForm.controls.booleanOcorreEvento.setValue(true);
            }
        }
    }

    validaItensDeComposicaoList(evento?) {
        this.validacaoNaturezaCalculo = false

            if(this.itensComposicaoMemoriaDeCalculo.find(
                (aux) =>
                aux.valorOrigem == null || aux.valorOrigem == undefined)){
                    this.validacaoNaturezaCalculo = true;
                }else {
                    this.validacaoNaturezaCalculo = false;
                }
    }

    salvarMemoriaDeCalculo() {
        this.alertUtil
            .confirm(
                `Confirma a gravação da memória de cálculo e abertura da Conta?`
            )
            .then((result) => {
                if (result.isConfirmed) {
                    this.prepareSave();
                }
            });
    }

    /**Açãoo ao selecionar ocorreu evento */
    verificaEventoSim(event, index?) {
        if(event?.isTrusted || event === 'MARCAR_RADIO'){
            this.resetDatasReferenciaList();
            this.tiposDatasReferenciaList[index].ocorreuEvento = true;
            this.opcaoSelecionada = this.tiposDatasReferenciaList[index].tipoDataReferenciaId;
        }
        this.submittedDatasReferencia = true;
        this.validacaoDataReferenciaOcorreuEvento();
    }

    adicionarAnexo() {
        this.anexos = [
            ...this.anexos,
            {
                arquivo: this.nomeArquivo,
                formato: this.formatoArquivo,
                conteudo: this.arquivoUploadBasse64
            },
        ];
        this.memoriaCalculoModel.anexo = this.anexos[0];
    }

    removeAnexo(i) {
        this.anexos.splice(i, 1);
    }

    valiodacaoForm() {
        this.submitted = true;
        if (this.memoriaCalculoForm.invalid) {
            return false;
        }
        return true;
    }

    upload(event) {
        const file = event.target.files[0];
        this.nomeArquivo = file.name;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.arquivoUploadBasse64 = reader.result;
        };
    }

    prepareSave() {
        // this.prepareDatasReferenciaObjSave();
        // this.prepareItemsDeComposicaoObjSave();

        this.blockedDocument = true;
        /**prepare objeto */
        this.montaObjSave();
        this.converteNaturezaSave();

        setTimeout(() => {
            this.criacaoContaCorrenteService.postMemoriaCaluculo(this.memoriaCalculoModel).subscribe(
                (response: any) => {
                    this.blockedDocument = false;
                    if(response?.lancamentoContasCorrentes?.erros.length > 0){
                        this.tratamentoErroLancamento(response);
                    } else {
                        this.alertUtil
                            .saveSuccess(`Salvo com sucesso`)
                            .then(() => {
                                this.outEtapas.emit(0);
                            });
                    }
                }, (error) => {
                    this.blockedDocument = false;
                    this.alertUtil.handleError(error);
                }
            )
        }, 500);
    }

    converteNaturezaSave() {
        let listAux = [];
        this.itensComposicaoMemoriaDeCalculo.forEach(element => {
            listAux = [
                ...listAux,{
                    naturezaItemCalculoId: element.itemComposicaoId,
                    valorOrigem: element.valorOrigem
                }
            ]
       });

       this.memoriaCalculoModel.naturezasCalculo = listAux;
    }


    montaObjSave(){
        /** Etapa confirmação salvar, this.hiddenItensConfirm === 3 */
        this.memoriaCalculoModel.configContaId = this.consultaContaObj?.configContaId;
        this.memoriaCalculoModel.tipoCadastroId = this.memoriaFormControl.tipoCadastro.value;

        this.memoriaCalculoModel.codigoCadastro =
        this.hiddenItensConfirm === 3 ?
        this.memoriaFormControl.inscEstadual.value.replace(/[^a-zA-Z0-9]/g, "") :
        this.memoriaFormControl.inscEstadual.value;

        this.memoriaCalculoModel.criarCotaUnica = this.memoriaFormControl.criarCotaUnica.value;
        this.memoriaCalculoModel.quantidadeParcelas = this.memoriaFormControl.qtdParcelas.value;
        this.memoriaCalculoModel.valorMinimoParcela = this.memoriaFormControl.valorMinimoParcela.value;
        this.memoriaCalculoModel.valorMinimoParcelamento = this.memoriaFormControl.valorMinimoParcelamento.value;
        this.memoriaCalculoModel.valorHonorario = this.valorHonorario;
        this.memoriaCalculoModel.numeroParcela = this.numeroParcela;
        this.memoriaCalculoModel.situacao = this.consultaContaObj?.situacao;
        this.memoriaCalculoModel.origemInformacaoId = this.consultaContaObj?.origemInformacao.origemInformacaoId;

        this.memoriaCalculoModel.datasReferencia = this.prepareDateReferenciaListSave(this.tiposDatasReferenciaList);

        this.memoriaCalculoModel.naturezasCalculo = this.prepareNaturezaListSave(this.itensComposicaoMemoriaDeCalculo);


        if(this.hiddenItensConfirm === 3) {
            this.memoriaCalculoModel.datasReferencia.forEach((element, index) => {
                this.tiposDatasReferenciaList[index].ocorreuEvento = this.tiposDatasReferenciaList[index].ocorreuEvento == true ?
                'SIM' : 'NAO';
            });
        }

        this.memoriaCalculoModel.documento.tipoDocumentoId = this.memoriaFormControl.tipoDocumento.value;
        this.memoriaCalculoModel.documento.numeroDocumento = this.memoriaFormControl.numeroDocumento.value;
        this.memoriaCalculoModel.anexo = this.anexos[0];
        this.memoriaCalculoModel.configuracaoEventoId = this.memoriaFormControl.configuracaoEventosId.value;

        if(this.hiddenItensConfirm === 3) {
            this.memoriaCalculoModel.lancarConta = 'SIM' ? 1 : 0;
        } else {
            this.memoriaCalculoModel.lancarConta = 'SIM';
        }

        if(this.hiddenItensConfirm === 3) {
            this.contaAssociadaList.forEach(element => {
                this.memoriaCalculoModel.contasCorrentesVinculo.push(element.contaCorrenteId);
            });
        }

        if(this.hiddenItensConfirm === 3) {
            this.removeNameObj();
        }
    }

    prepareDateReferenciaListSave(dataList: any){
        dataList.forEach((element, index) => {
            this.tiposDatasReferenciaList[index].dataInformada = this.utils.convertDateToSave(element.dataInformada);
        });
        return this.tiposDatasReferenciaList;
    }

    prepareNaturezaListSave(dataList: any){
        dataList.forEach((element, index) => {
            if(element.itemTipo == 'DATA'){
                this.itensComposicaoMemoriaDeCalculo[index].valorOrigem = this.utils.convertDateToSave(element.valorOrigem);
            }
        });
        return this.itensComposicaoMemoriaDeCalculo;
    }

    removeNameObj() {
        // Remover o objeto descricao da lista
        this.memoriaCalculoModel.datasReferencia = this.memoriaCalculoModel.datasReferencia.map(parametro => {
            const { descricao, ...resto } = parametro;
            return resto;
        });
    }

    tratamentoErroLancamento(response) {
        let listaErro = [];
        let listErroTemp =
        response?.lancamentoContasCorrentes?.erros.forEach(element => {
            listaErro.push(`- ${element}`)
        });
        this.alertUtil.warning(`
        Não foi possível criar a conta corrente pelos motivos abaixo:
        <span></span>
        <p>${listaErro.join("")}</p>`);
    }

    /**Monta objt data referencia e converte data to save */
    /**apagar */
    // prepareDatasReferenciaObjSave(){
    //     /**Prepare Criar Save data referencia ultima aba */
    //         let listAux = [];
    //         const dataListSave: any = this.memoriaCalculoModel.datasReferencia;
    //         dataListSave.forEach(element => {
    //             listAux = [
    //                 ...listAux,
    //                 {
    //                     tipoDataReferenciaId: element.tipoDataReferenciaId,
    //                     dataInformada: this.utils.convertDateToSave(element.dataInformada),
    //                     descricao: element.descricao,
    //                     ocorreuEvento: element.ocorreuEvento
    //                 },
    //             ];
    //         });
    //         /**seta no form para validacao */
    //         this.memoriaCalculoForm.controls.datasReferencia.setValue(listAux);
    // }


    verificaValorTipoDeCadastro(tipoCodigoCadastro: any){
        if(this.memoriaCalculoForm.get("tipoCadastro").value === 1) {
            this.maskCustom = '999999999';
            this.labelCustom = 'Contribuinte';
            this.labelCustomInput = 'Inscrição Estadual*';
            this.valueMask = false;
            this.placeholderTipoCadastro='Informe a Inscrição Estadual';
            this.maxLengthTipoCadastro=9;
        } else if(this.memoriaCalculoForm.get("tipoCadastro").value === 2) {
            this.maskCustom = '99999999999';
            this.labelCustom = 'Veículo';
            this.labelCustomInput = 'RENAVAM';
            this.valueMask = false;
            this.placeholderTipoCadastro='Informe o RENAVAM';
            this.maxLengthTipoCadastro=11;
        } else if(this.memoriaCalculoForm.get("tipoCadastro").value === 3) {
            this.valueMask = false;
            this.habilitaTipoPessoa = true;
            this.maskCustom = '*pea9';
            this.labelCustom = 'Pessoal Física';
            this.labelCustomInput = 'CPF ou CNPJ*';
            this.placeholderTipoCadastro='Informe CPF ou CNPJ';
            this.maxLengthTipoCadastro=14;
        }
    }

    setValorTpCadastro(){
        this.objTpCadastroParam = {
                tpCadastroId: this.memoriaCalculoForm.get("tipoCadastro").value,
                tpCadastroValue: this.memoriaCalculoForm.get("inscEstadual").value
            }
        localStorage.setItem('objTpCadastroParam', JSON.stringify(this.objTpCadastroParam));

    }

    valorPessoa(evento){
        if(this.habilitaTipoPessoa){
            if(evento === 1) {
                this.labelCustomInput = 'CPF*';
                this.maskCustom = '999.999.999-99';
            } else if(evento === 2) {
                this.labelCustomInput = 'CNPJ*';
                this.maskCustom = '99.999.999/9999-99';
            } else {
                this.alertUtil.warning(`Houve um erro ao tentar informar o tipo de Cadastro.`);
            }
        }
    }

}

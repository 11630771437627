import { Component } from "@angular/core";
import { ConsultaContaCorrenteService } from "src/app/component/criacao-conta-corrente/services/consulta-conta-corrente.service";

@Component({
    selector: "app-modal-parcelamento-integracao",
    templateUrl: "./modal-parcelamento-integracao.component.html",
    styleUrls: ["./modal-parcelamento-integracao.component.scss"],
})
export class ModalParcelamentoIntegracaoComponent {
    visible: boolean;
    dadosParcelamento: any;

    constructor(
        private consultaContaCorrenteService: ConsultaContaCorrenteService
    ) {}

    public showDialog() {
        const conta = JSON.parse(localStorage.getItem("conta"));

        this.consultaContaCorrenteService
            .getDetalhesParcelamentoIntegracao(conta?.numeroDocumento)
            .subscribe((res) => {
                this.dadosParcelamento = res;
                this.visible = true;
            });
    }

    hiddenDialog() {
        this.visible = false;
    }
}

import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Chart, ChartConfiguration, Plugin } from "chart.js";
import { EventoPorPeriodoSearchRequest } from "../../../request/EventoPorPeriodoSearchRequest";
import { UtilsService } from "../../../shared/utils.service";
import { EventoPorPeriodoService } from "./evento-por-periodo.service";

@Component({
    selector: "app-evento-por-periodo",
    templateUrl: "./evento-por-periodo.component.html",
    styleUrls: ["./evento-por-periodo.component.scss"],
})
export class EventoPorPeriodoComponent implements OnInit {
    eventoPorPeriodoSearchRequest: EventoPorPeriodoSearchRequest;
    chartDataResponse: any[];

    @ViewChild("meuCanvas", { static: true }) elemento: ElementRef;

    transacaoList: any[];
    impostoList: any[];
    contaList: any[];
    eventoList: any[];

    campoemBranco: boolean = false;
    DadosemBranco: boolean = false;

    grafico: any;
    maxDateFinal: Date | null = null;
    dataInicialReferenciaValue: Date | null = null;
    dataFinalReferenciaValue: Date | null = null;

    // Lista de cores hexadecimais harmoniosas
    colors = [
        "#1F77B4",
        "#FF7F0E",
        "#2CA02C",
        "#D62728",
        "#9467BD",
        "#8C564B",
        "#E377C2",
        "#7F7F7F",
        "#BCBD22",
        "#17BECF",
        "#AEC7E8",
        "#FFBB78",
        "#98DF8A",
        "#FF9896",
        "#C5B0D5",
        "#C49C94",
        "#F7B6D2",
        "#C7C7C7",
        "#DBDB8D",
        "#9EDAE5",
        "#393B79",
        "#637939",
        "#8C6D31",
        "#843C39",
        "#5254A3",
    ];

    constructor(
        public eventoPorPeridoService: EventoPorPeriodoService,
        private utils: UtilsService
    ) {}

    ngOnInit() {
        this.eventoPorPeriodoSearchRequest =
            new EventoPorPeriodoSearchRequest();
        this.getTransacoesList();
        this.getImpostoList();
        this.getContaList();
        this.getEventoList();
    }

    public legendaTopBarra: Plugin = {
        id: "topline",
        afterDatasetDraw(chart: Chart, args: any, plugins: any) {
            const { ctx, data } = chart;

            data.datasets.forEach((dataset, i) => {
                chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
                    const evento = data.datasets[i]["labelTop"][index]
                        ? data.datasets[i]["labelTop"][index]
                        : "";
                    if (dataset.data[index] !== 0) {
                        ctx.beginPath();
                        ctx.font = "16px sans-serif";
                        ctx.fillStyle = "black";
                        ctx.textAlign = "left";
                        ctx.save();
                        ctx.translate(datapoint.x, datapoint.y - 6);
                        ctx.rotate(-0.5 * Math.PI);
                        ctx.fillText(evento, 0, 0);
                        ctx.restore();
                    }
                });
            });
        },
    };

    public chartOptions: ChartConfiguration["options"];

    createChart(dataResponse, optionsResponse, pluginResponse) {
        this.grafico = new Chart(this.elemento.nativeElement, {
            type: "bar",
            data: dataResponse, // Passando os dados da variável
            options: optionsResponse, // Passando as opções da variável
            plugins: [pluginResponse],
        });
    }

    clear() {
        if (this.grafico) {
            this.grafico.destroy();
        }
        this.eventoPorPeriodoSearchRequest = {
            dataInicialReferenciaValue: null,
            dataFinalReferenciaValue: null,
            eventoId: [],
            transacaoId: [],
            tributoId: [],
            contaId: [],
        };
        this.dataInicialReferenciaValue = null;
        this.dataFinalReferenciaValue = null;
    }

    pesquisar() {
        if (
            !this.dataInicialReferenciaValue ||
            !this.dataFinalReferenciaValue
        ) {
            return (this.campoemBranco = true);
        }
        this.campoemBranco = false;

        this.eventoPorPeriodoSearchRequest.dataInicialReferenciaValue = this
            .dataInicialReferenciaValue
            ? this.utils.convertToSaveAnoMes(this.dataInicialReferenciaValue)
            : null;
        this.eventoPorPeriodoSearchRequest.dataFinalReferenciaValue = this
            .dataFinalReferenciaValue
            ? this.utils.convertToSaveAnoMes(this.dataFinalReferenciaValue)
            : null;

        const data = JSON.parse(
            JSON.stringify(this.eventoPorPeriodoSearchRequest)
        );

        return this.eventoPorPeridoService
            .pesquisaDadosDashboard(data)
            .subscribe((response) => {
                if (this.grafico) {
                    this.grafico.destroy();
                }

                if (Array.isArray(response) && response.length === 0) {
                    this.DadosemBranco = true;
                    return; // Sai da função se o array estiver vazio
                }

                this.DadosemBranco = false; // Reseta o estado se o array não estiver vazio

                this.chartDataResponse = this.adicionaColorsAndRenomeaXAxisID(
                    response,
                    this.colors
                );

                const maxDataValue = this.getMaxValue(
                    this.chartDataResponse[0].datasets
                );
                // Extraia xAxis e xAxis2 do updatedJsonData
                const xAxisConfig =
                    this.chartDataResponse[0]?.scales?.xAxis || {};
                const xAxis2Config =
                    this.chartDataResponse[0]?.scales?.xAxis2 || {};

                this.chartOptions = {
                    scales: {
                        xAxis: xAxisConfig, // Adiciona a configuração de xAxis
                        xAxis2: xAxis2Config, // Adiciona a configuração de xAxis2
                        y: {
                            beginAtZero: true,
                            suggestedMax: maxDataValue,
                            ticks: {
                                callback: function (value) {
                                    return new Intl.NumberFormat("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                    }).format(Number(value));
                                },
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: true,
                            position: "bottom",
                        },
                    },
                };

                this.createChart(
                    this.chartDataResponse[0],
                    this.chartOptions,
                    this.legendaTopBarra
                );

                this.DadosemBranco = false;
            });
    }

    getTransacoesList() {
        return this.eventoPorPeridoService
            .getTrasacoes()
            .subscribe((response) => {
                this.transacaoList = response;
            });
    }

    getEventoList() {
        return this.eventoPorPeridoService.getEvento().subscribe((response) => {
            this.eventoList = response;
        });
    }

    getImpostoList() {
        return this.eventoPorPeridoService
            .getImpostos()
            .subscribe((response) => {
                const impostoTemp = response;

                //informação de impostos abaixo estão presente em outra tabela
                impostoTemp.push(
                    {
                        tributoId: 5,
                        descricaoCompleta: "PARCELAMENTO",
                        descricaoResumida: "PARCELAMENTO",
                        sigla: "PARCELAMENTO",
                        codTributo: 5,
                    },
                    {
                        tributoId: 99,
                        descricaoCompleta: "OUTROS",
                        descricaoResumida: "OUTROS",
                        sigla: "OUTROS",
                        codTributo: 99,
                    }
                );

                this.impostoList = impostoTemp;
            });
    }

    getContaList() {
        return this.eventoPorPeridoService.getConta().subscribe((response) => {
            this.contaList = response;
        });
    }

    //Limita a seleção para periodo de 1 ano
    onDataInicialChange(dataInicial: Date) {
        if (dataInicial) {
            const maxDate = new Date(dataInicial);
            maxDate.setMonth(maxDate.getMonth() + 11);
            this.maxDateFinal = maxDate;
        } else {
            this.maxDateFinal = null;
        }
        if (this.dataFinalReferenciaValue) {
            this.dataFinalReferenciaValue = null;
        }
    }

    getMaxValue(datasets: any[]): number {
        const maxByDataSet: number[] = datasets.map((d) => Math.max(...d.data));
        return Math.max(...maxByDataSet) * 1.1;
    }

    adicionaColorsAndRenomeaXAxisID(jsonData: any, colors: string[]): any {
        const usedColors = new Set<string>(); // Para rastrear as cores já usadas
        let colorIndex = 0;

        jsonData.forEach((chart: any) => {
            chart.datasets.forEach((dataset: any) => {
                // Adiciona a cor ao dataset
                if (!usedColors.has(colors[colorIndex])) {
                    dataset.backgroundColor = colors[colorIndex];
                    dataset.barPercentage = 0.8;
                    dataset.categoryPercentage = 1;
                    usedColors.add(colors[colorIndex]);
                    colorIndex = (colorIndex + 1) % colors.length; // Garante que não exceda o tamanho da lista de cores
                }

                // Corrige xaxisID para xAxisID
                if (dataset.hasOwnProperty("xaxisID")) {
                    dataset.xAxisID = dataset.xaxisID; // Copia o valor para a nova chave
                    delete dataset.xaxisID; // Remove a chave antiga
                }
            });
            // Corrige scales.xaxis para scales.xAxis e scales.xaxis2 para scales.xAxis2
            if (chart.scales) {
                if (chart.scales.hasOwnProperty("xaxis")) {
                    chart.scales.xAxis = chart.scales.xaxis;
                    delete chart.scales.xaxis;
                }

                if (chart.scales.hasOwnProperty("xaxis2")) {
                    chart.scales.xAxis2 = chart.scales.xaxis2;
                    delete chart.scales.xaxis2;
                }
            }
        });

        return jsonData;
    }
}

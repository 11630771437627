import {
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { RecreateDetalharContaService } from "src/app/menu/recreate-detalhar.service";
import { ModalDetalheAutoInfracaoComponent } from "src/app/modal/modal-detalhe-auto-infracao/modal-detalhe-auto-infracao.component";
import { ModalParcelamentoIntegracaoComponent } from "src/app/modal/modal-parcelamento-integracao/modal-parcelamento-integracao.component";
import { Pagination } from "src/app/shared/pagination/pagination";
import { MaskCpfOuCnpj } from "src/app/utils/masklCpfCnpjList/maskCpfCnpj.util";
import swal from "sweetalert2";
import { ConsultaContaCorrenteService } from "../../../services/consulta-conta-corrente.service";
import { ConsultaContribuinteService } from "../../../services/consulta-conta-debitos-contribuinte.service";

enum TipoTributo {
    IPVA = 1,
    ITCD = 2,
}

enum TipoCadastro {
    IE = 1,
    RENAVAM = 2,
    CPFCNPJ = 3,
}

enum TipoDocumento {
    NUM_LANCAMENTO = 11,
    NUM_AUTO_INFRACAO = 12,
    NUM_CONTRATO_PARC = 8,
}
@Component({
    selector: "ccp-detalhamento-conta",
    templateUrl: "./detalhamento-conta.component.html",
    styleUrls: ["./detalhamento-conta.component.scss"],
})
export class DetalhamentoContaComponent implements OnInit {
    @Output() outEtapaSelecaoConta = new EventEmitter();

    @ViewChild(ModalDetalheAutoInfracaoComponent, { static: false })
    modalDetalheAutoInfracaoComponent: ModalDetalheAutoInfracaoComponent;

    @ViewChild(ModalParcelamentoIntegracaoComponent, { static: false })
    modalParcelamentoIntegracaoComponent: ModalParcelamentoIntegracaoComponent;

    title: string = "Conta";
    saldosList: any[] = [];
    detalheConta: any;
    detalheIpvaContribuinte: any;
    saldoNegativo = true;
    detalheContaItcd: any;
    dataAtual: Date = new Date();
    detalheContaPosicaoSaldo: any[] = [];
    detalheContaAnexo: any;
    textoDocDownload: string;
    downloadAnexo: boolean;
    contasAssociadas: any[] = [];
    detalhesIpvaDesvinculado: any;
    detalhesContribuinte: any;

    constructor(
        private maskCpfOuCnpj: MaskCpfOuCnpj,
        private consultaContaCorrenteService: ConsultaContaCorrenteService,
        private reRenderService: RecreateDetalharContaService,
        private contribuinteService: ConsultaContribuinteService
    ) {}

    ngOnInit(): void {
        this.detalheConta = JSON.parse(localStorage.getItem("conta"));
        this.getDetalheContaView();
        this.getDetalheContaDetalheDocumento();
        this.exibeDetalhesContribuinteConta();
    }

    exibeDetalhesContribuinteConta() {
        if (this.showVeiculoInfo) {
            this.consultaContaCorrenteService
                .getDadosIpvaContribuinte({
                    renavam: this.detalheConta?.codigoCadastro,
                })
                .subscribe((dadosIpvaContribuinte) => {
                    this.detalheIpvaContribuinte =
                        dadosIpvaContribuinte?.content[0];
                });
        }

        if (this.showDesvinculado) {
            this.consultaContaCorrenteService
                .getDadosIpvaDesvinculado(this.detalheConta?.numeroDocumento)
                .subscribe((ipvaDesvinculado) => {
                    this.detalhesIpvaDesvinculado = ipvaDesvinculado;
                });
        }

        if (this.showContribuinteInfo) {
            this.contribuinteService
                .getContribuintePorTipoCadastro(
                    this.detalheConta?.tipoCadastroId,
                    this.detalheConta?.codigoCadastro
                )
                .subscribe((res) => {
                    this.detalhesContribuinte = res;
                });
        }

        if (this.isITCD) {
            this.consultaContaCorrenteService
                .getDetalheItcd(
                    {
                        cpfCnpj: this.detalheConta.cpfCnpj2,
                        codTipo: 3,
                        tdiNumDecl: this.detalheConta.numeroDocumento,
                    },
                    new Pagination()
                )
                .subscribe((response: any) => {
                    this.detalheContaItcd = response.content[0];
                });
        }
    }

    getDetalheContaDetalheDocumento() {
        this.consultaContaCorrenteService
            .getDetalheConta(this.detalheConta?.contaCorrenteId)
            .subscribe((response: any) => {
                this.detalheContaAnexo = response;
                this.detalheContaAnexo.contaCorrenteFinanceiros =
                    response.contaCorrenteFinanceiros.sort(
                        (a, b) =>
                            a.naturezaItemCalculo?.ordemExibicao -
                            b.naturezaItemCalculo?.ordemExibicao
                    );
                this.textoDocDownload = `${
                    this.detalheContaAnexo?.documentos?.nomeArquivo
                        ? this.detalheContaAnexo?.documentos?.nomeArquivo
                        : "arquivo.pdf"
                }`;
                if (
                    this.detalheContaAnexo?.documentos?.formato === "PDF" &&
                    this.detalheContaAnexo?.documentos?.documento
                ) {
                    this.downloadAnexo = true;
                }
            });
    }

    irSelecionarConta() {
        this.outEtapaSelecaoConta.emit(1);
    }

    converteValue(ev) {
        let ano = ev?.toString().substring(0, 4);
        let mes = ev?.toString().substring(4, 6);
        return `${mes}/${ano}`;
    }

    downloadFile() {
        if (this.downloadAnexo) {
            /**Gera PDF */
            let nomeArquivo: string =
                this.detalheContaAnexo?.documentos?.nomeArquivo !== null
                    ? `${this.detalheContaAnexo?.documentos?.nomeArquivo}`
                    : `arquivo.pdf`;
            var b64 = `${this.detalheContaAnexo?.documentos?.documento}`;
            const source = `${b64}`;
            const link = document.createElement("a");
            link.href = source;
            link.download = nomeArquivo;
            link.click();
        } else if (this.detalheContaAnexo?.documentos?.formato === "JSON") {
            /**Gera JSON */
            let nomeArquivo = "file";
            let json: any = `${this.detalheContaAnexo?.documentos?.documento}`;
            const fileJson = JSON.stringify(json);
            var a = document.createElement("a");
            a.href = URL.createObjectURL(
                new Blob([fileJson], { type: "application/json" })
            );
            a.download = `${nomeArquivo}.json`;
            a.click();
        }
    }

    maior(ev) {
        if (ev > 0) {
            return true;
        }
        return false;
    }

    insereMascara(cpfOuCnpj) {
        return this.maskCpfOuCnpj.inserirMascara(cpfOuCnpj);
    }

    converteMesAno(data) {
        let ano = data?.toString().substring(0, 4);
        let mes = data?.toString().substring(4, 6);

        return `${mes}/${ano}`;
    }

    getDetalheContaView() {
        this.detalheContaPosicaoSaldo.push(this.detalheConta);
        this.consultaContaCorrenteService
            .getContasAssociadas(this.detalheConta?.contaCorrenteId)
            .subscribe((res) => {
                this.contasAssociadas = res;
            });
    }

    detalharContaAssociada(contaAssociada) {
        this.consultaContaCorrenteService
            .getContaViewConta(
                { contaCorrenteId: contaAssociada.contaCorrenteId },
                { pageable: { pageNumber: 0 }, size: 1 },
                contaAssociada.tipoConta
            )
            .subscribe((res) => {
                if (res.totalElements > 0) {
                    const conta = res.content[0];
                    localStorage.setItem("conta", JSON.stringify(conta));
                    this.reRenderService.reRenderDetalhar();
                } else {
                    swal.fire({
                        title: "Atenção",
                        text: `Conta corrente de Nº ${contaAssociada.contaCorrenteId} não encontrada.`,
                        icon: "warning",
                    });
                }
            });
    }

    exportarExcel() {
        this.consultaContaCorrenteService
            .gerarExcel(this.detalheConta?.contaCorrenteId)
            .subscribe((res) => {
                this.exportaArquivo(
                    res,
                    `ContaCorrente_${this.detalheConta?.contaCorrenteId}.xlsx`
                );
            });
    }

    private exportaArquivo(blob: Blob, nomeArquivo: string) {
        const a = document.createElement("a");
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = nomeArquivo;
        a.click();
        URL.revokeObjectURL(objectUrl);
    }

    abrirModalAutoInfracao() {
        this.modalDetalheAutoInfracaoComponent.showDialog();
    }

    abrirModalParcelamentoIntegracao() {
        this.modalParcelamentoIntegracaoComponent.showDialog();
    }

    get showVeiculoInfo() {
        return this.detalheConta.tipoCadastroId === TipoCadastro.RENAVAM;
    }

    get showContribuinteInfo() {
        return (
            this.detalheConta.tipoCadastroId === TipoCadastro.IE ||
            this.detalheConta.tipoCadastroId === TipoCadastro.CPFCNPJ
        );
    }

    get showDesvinculado() {
        return (
            this.detalheConta.codTributo === TipoTributo.IPVA &&
            this.detalheConta.tipoCadastroId === TipoCadastro.CPFCNPJ
        );
    }

    get isITCD() {
        return this.detalheConta.codTributo === TipoTributo.ITCD;
    }

    get tipoDocumento(): typeof TipoDocumento {
        return TipoDocumento;
    }
}

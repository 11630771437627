import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MensagensNaoLidaDteService {

  constructor(private http: HttpClient) { }

    public getMensagemNaoLidaDTE(): Observable<any> {
        return this.http.get<any>(`${environment.baseUrl}/DteLogin`);
    }
}

import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Chart, ChartConfiguration, ChartOptions, TooltipItem } from "chart.js";
import { Paginator } from "primeng/paginator";
import { ImpostoAtrasadoSearchRequest } from "../../../request/ImpostoAtrasadoSearchRequest";
import { Pagination } from "../../../shared/pagination/pagination";
import { UtilsService } from "../../../shared/utils.service";
import { ChartData } from "../lancamento-por-imposto/lancamento-por-imposto.component";
import { ImpostoAtrasadoService } from "./imposto-atrasado.service";

@Component({
    selector: "app-imposto-atrasado",
    templateUrl: "./imposto-atrasado.component.html",
    styleUrls: ["./imposto-atrasado.component.scss"],
})
export class ImpostoAtrasadoComponent implements OnInit {
    impostoAtrasadoSearchRequest: ImpostoAtrasadoSearchRequest;
    chartDataResponse: any[];
    pagination: Pagination<any>;

    @ViewChild("paginator", { static: true }) paginator: Paginator;
    @ViewChild("meuCanvas", { static: true }) elemento: ElementRef;

    colorEm_Aberto_grupo1: string = "rgba(66, 165, 245, 0.7)"; // Cor azul meio transparente
    colorEm_Aberto_grupo2: string = "rgba(255, 167, 38, 0.7)"; // Cor castanho meio transparente
    alternateColorFlag: boolean = true;

    tipoGraficoList: any[] = [
        { tipo: "bar", descricao: "Imposto em Atraso" },
        { tipo: "doughnut", descricao: "Contas em Atraso" },
    ];
    impostoList: any[];
    contaList: any[];
    tipoGrafico: any;
    grafico: any;

    campoemBranco: boolean = false;
    DadosemBranco: boolean = false;
    maxDateFinal: Date | null = null;
    dataInicialReferenciaValue: Date | null = null;
    dataFinalReferenciaValue: Date | null = null;

    colors = [
        "#1F77B4",
        "#FF7F0E",
        "#2CA02C",
        "#D62728",
        "#9467BD",
        "#8C564B",
        "#E377C2",
        "#7F7F7F",
        "#BCBD22",
        "#17BECF",
        "#AEC7E8",
        "#FFBB78",
        "#98DF8A",
        "#FF9896",
        "#C5B0D5",
        "#C49C94",
        "#F7B6D2",
        "#C7C7C7",
        "#DBDB8D",
        "#9EDAE5",
        "#393B79",
        "#637939",
        "#8C6D31",
        "#843C39",
        "#5254A3",
    ];

    constructor(
        public impostoAtrasadoService: ImpostoAtrasadoService,
        private utils: UtilsService
    ) {}

    ngOnInit() {
        this.impostoAtrasadoSearchRequest = new ImpostoAtrasadoSearchRequest();
        this.getContaList();
        this.getImpostoList();
    }

    public chartOptions: ChartConfiguration["options"] = {
        responsive: true,
        scales: {
            x: {
                stacked: false,
                grid: {},
            },
            y: {
                beginAtZero: true,
                ticks: {
                    callback: function (value) {
                        return new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                        }).format(Number(value));
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: true,
                position: "bottom",
            },
        },
    };

    public chartOptionsDoughnut: ChartOptions<"doughnut"> = {
        plugins: {
            legend: {
                display: true,
                position: "right",
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem: TooltipItem<"doughnut">) {
                        const data = tooltipItem.chart.data;

                        // Verifique se 'data' e 'data.labels' estão definidos
                        if (data && data.labels && data.datasets) {
                            // Obtém o rótulo do item
                            let label =
                                data.labels[tooltipItem.dataIndex] || "";

                            // Adiciona um espaço entre o rótulo e o valor
                            if (label) {
                                label += ": ";
                            }

                            // Obtém o valor atual
                            const value =
                                data.datasets[tooltipItem.datasetIndex].data[
                                    tooltipItem.dataIndex
                                ];

                            // Formata o valor como número com separador de milhares
                            const formattedValue = new Intl.NumberFormat(
                                "pt-BR",
                                { style: "currency", currency: "BRL" }
                            ).format(Number(value));

                            return "Conta: " + label + formattedValue;
                        } else {
                            // Se data.labels ou data.datasets estiverem indefinidos, retorne um valor padrão
                            return "N/A";
                        }
                    },
                },
            },
        },
    };

    createChart(chartData, chartOptions, typeChart) {
        if (typeChart === "doughnut") {
            this.elemento.nativeElement.style.height = "80vh";
            this.elemento.nativeElement.style.width = "80vh";
            this.elemento.nativeElement.style.margin = "auto";
        }

        this.grafico = new Chart(this.elemento.nativeElement, {
            type: typeChart,
            data: chartData, // Passando os dados da variável
            options: chartOptions, // Passando as opções da variável
        });
    }

    clear() {
        if (this.grafico) {
            this.grafico.destroy();
        }
        this.tipoGrafico = null;
        this.impostoAtrasadoSearchRequest = {
            contaId: [],
            impostoId: [],
            dataInicialReferenciaValue: null,
            dataFinalReferenciaValue: null,
            atraso: 30,
        };
        this.dataInicialReferenciaValue = null;
        this.dataFinalReferenciaValue = null;
    }

    pesquisar() {
        if (
            (!this.dataInicialReferenciaValue ||
                !this.dataFinalReferenciaValue) ||
            this.tipoGrafico == undefined
        ) {
            return (this.campoemBranco = true);
        }
        console.log(this.tipoGrafico);
        this.campoemBranco = false;

        this.impostoAtrasadoSearchRequest.dataInicialReferenciaValue = this
            .dataInicialReferenciaValue
            ? this.utils.convertToSaveAnoMes(this.dataInicialReferenciaValue)
            : null;
        this.impostoAtrasadoSearchRequest.dataFinalReferenciaValue = this
            .dataFinalReferenciaValue
            ? this.utils.convertToSaveAnoMes(this.dataFinalReferenciaValue)
            : null;

        const data = JSON.parse(
            JSON.stringify(this.impostoAtrasadoSearchRequest)
        );
        return this.impostoAtrasadoService
            .pesquisaDadosDashboard(data, this.tipoGrafico)
            .subscribe((response) => {
                if (this.grafico) {
                    this.grafico.destroy();
                }

                if (Array.isArray(response) && response.length === 0) {
                    this.DadosemBranco = true;
                    return; // Sai da função se o array estiver vazio
                }
                this.DadosemBranco = false; // Reseta o estado se o array não estiver vazio

                this.chartDataResponse = this.processChartData(response);

                this.createChart(
                    this.chartDataResponse[0],
                    this.tipoGrafico === "bar"
                        ? this.chartOptions
                        : this.chartOptionsDoughnut,
                    this.tipoGrafico
                );
            });
    }

    getContaList() {
        return this.impostoAtrasadoService.getConta().subscribe((response) => {
            this.contaList = response;
        });
    }

    getImpostoList() {
        return this.impostoAtrasadoService
            .getImpostos()
            .subscribe((response) => {
                const impostoTemp = response;

                //informação de impostos abaixo estão presente em outra tabela
                impostoTemp.push(
                    {
                        tributoId: 5,
                        descricaoCompleta: "PARCELAMENTO",
                        descricaoResumida: "PARCELAMENTO",
                        sigla: "PARCELAMENTO",
                        codTributo: 5,
                    },
                    {
                        tributoId: 99,
                        descricaoCompleta: "OUTROS",
                        descricaoResumida: "OUTROS",
                        sigla: "OUTROS",
                        codTributo: 99,
                    }
                );

                this.impostoList = impostoTemp;
            });
    }

    processChartData(response: ChartData[]): ChartData[] {
        return response.map((chartData) => ({
            ...chartData,
            datasets: chartData.datasets.map((dataset, index) => ({
                ...dataset,
                backgroundColor:
                    this.tipoGrafico == "bar"
                        ? this.getColorByIndex(index)
                        : this.getListColor(dataset.data.length), // Função que define a cor de fundo com base no label
                barPercentage: 0.8,
                categoryPercentage: 0.9, // category Percentage padrão para todos
            })),
        }));
    }

    // Função para definir a cor de fundo com base no label
    getListColor(dataLength: number): string[] {
        const backgroundColors: string[] = [];

        for (let i = 0; i < dataLength; i++) {
            backgroundColors.push(this.colors[i % this.colors.length]); // Cicla pelas cores na lista
        }

        return backgroundColors;
    }

    getColorByIndex(dataLength: number): string {
        return this.colors.length < dataLength
            ? this.getRandomColor()
            : this.colors[dataLength];
    }

    getRandomColor() {
        return "#" + Math.floor(Math.random() * 16777215).toString(16);
    }
    //Limita a seleção para periodo de 1 ano
    onDataInicialChange(dataInicial: Date) {
        if (dataInicial) {
            const maxDate = new Date(dataInicial);
            maxDate.setMonth(maxDate.getMonth() + 11);
            this.maxDateFinal = maxDate;
        } else {
            this.maxDateFinal = null;
        }
        if (this.dataFinalReferenciaValue) {
            this.dataFinalReferenciaValue = null;
        }
    }
}

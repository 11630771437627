import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import {PerfilService} from "../api/perfil/service/perfil.service";
import {AppTopbarService} from "../app.topbar.service";
import {AcaoHelper} from "../shared/acoes-helper/acao-helper";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {
    constructor(
        protected readonly router: Router,
        protected readonly keycloak: KeycloakService,
        protected readonly perfilService: PerfilService,
        private readonly appTopbarService: AppTopbarService
    ) {
        super(router, keycloak);
    }

    public async isAccessAllowed (
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        // Force the user to log in if currently unauthenticated.
        if (!this.authenticated) {
            await this.keycloak.login({
                redirectUri: window.location.origin + state.url
            });
        }

        // Allow the user to proceed if all the required roles are present.
        return this.perfilService.getAcoesByUsuarioSSO(this.keycloak.getKeycloakInstance().tokenParsed.sub).then((acoes) => {
            return acoes;
        }).then((permissions) => {
            AcaoHelper.roles = permissions;

            return true;
        });
    }
}

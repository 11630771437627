import { EventEmitter, Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})

/**Reseta index componentes que utilizam time line e em pages dentro de um único componente */
export class RecreateDetalharContaService {
    private value = new EventEmitter<number>();

    constructor() {}

    reRenderDetalhar() {
        this.value.emit(Math.random() * 100);
    }

    getUpdateObservable() {
        return this.value.asObservable();
    }
}

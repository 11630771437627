<div class="p-grid">
    <!-- <div class="p-col-12" *ngIf="implantado">
        <p-messages severity="info">
            <ng-template pTemplate>
                <div class="ml-2">
                    Não é permitido editar um evento implantado.
                </div>
            </ng-template>
        </p-messages>
    </div> -->
    <div class="p-col-12 p-fluid">
        <label>Conta *</label>
        <p-autoComplete
            [disabled]="indexEdicao != null"
            [(ngModel)]="contaSelecionada.conta"
            [forceSelection]="true"
            [suggestions]="contaAutoCompleteOptions"
            field="nomeCompleto"
            (completeMethod)="buscarContaPorNome($event)"
        >
        </p-autoComplete>
    </div>
    <div class="p-col-12 p-fluid">
        <div class="p-grid">
            <div
                class="field-checkbox p-col-2 p-d-flex p-jc-flex-start p-ai-center"
                style="gap: 5px"
            >
                <p-checkbox
                    [(ngModel)]="contaSelecionada.criaConta"
                    [binary]="true"
                    inputId="criaConta"
                ></p-checkbox>
                <label for="criaConta"> Cria conta?</label>
            </div>
            <div class="p-col-10 p-fluid" *ngIf="contaSelecionada.criaConta">
                <label>Selecionar conta a ser criada pós evento*</label>
                <p-autoComplete
                    [(ngModel)]="contaSelecionada.contaPosEvento"
                    [forceSelection]="true"
                    [suggestions]="contaAutoCompleteOptions"
                    field="nomeCompleto"
                    (completeMethod)="buscarContaPorNome($event)"
                >
                </p-autoComplete>
            </div>
        </div>
    </div>
    <div class="p-col-5 p-fluid">
        <label>Critério de Busca Conta Corrente</label>
        <p-dropdown
            [options]="criterioBuscaOptions"
            [(ngModel)]="contaSelecionada.criterioBuscaContaCorrente"
            optionValue="chave"
            optionLabel="valor"
            placeholder="Selecione uma opção"
        ></p-dropdown>
    </div>
    <div class="p-col-2 p-fluid">
        <label>Receita pós evento</label>
        <p-dropdown
            [options]="contaSelecionada?.conta?.receitas || []"
            [(ngModel)]="contaSelecionada.codReceitaPosEvento"
            optionValue="codReceita"
            optionLabel="nomeReceita"
            placeholder="Selecione uma opção"
        ></p-dropdown>
    </div>
    <div class="p-col-2 p-fluid">
        <label>Início da Vigência *</label>
        <p-calendar
            [(ngModel)]="contaSelecionada.inicioVigencia"
            dateFormat="dd/mm/yy"
            dataType="string"
            [showIcon]="true"
        ></p-calendar>
    </div>
    <div class="p-col-2 p-fluid">
        <label>Fim da Vigência </label>
        <p-calendar
            [(ngModel)]="contaSelecionada.fimVigencia"
            dateFormat="dd/mm/yy"
            dataType="string"
            [showIcon]="true"
        ></p-calendar>
    </div>
    <div class="p-col-1 p-fluid">
        <label><br /></label>
        <button
            [disabled]="desabilitaAdicionarCC"
            type="button"
            pButton
            title="Incluir"
            icon="pi pi-plus"
            class="p-button-rounded"
            (click)="addCC()"
        ></button>
    </div>
</div>
<div class="p-grid">
    <div class="p-col-12">
        <h5>Lista de Contas</h5>
        <p-table [value]="contasForm.value.contasSelecionadas">
            <ng-template pTemplate="header">
                <tr>
                    <th>Código</th>
                    <th>Conta</th>
                    <th>Descrição</th>
                    <th>Tributo</th>
                    <th>Receita Pós Evento</th>
                    <th>Conta Pós Evento</th>
                    <th>Início Vigência</th>
                    <th>Fim Vigência</th>
                    <th>Implantado</th>
                    <th>Ações</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-cc>
                <tr>
                    <td>{{ cc.conta?.configContaId }}</td>
                    <td>{{ cc.conta?.nomeResumido }}</td>
                    <td>{{ cc.conta?.nomeCompleto }}</td>
                    <td>{{ cc.conta?.tributo?.descricaoResumida }}</td>
                    <td>
                        <p-tag
                            *ngIf="cc.codReceitaPosEvento"
                            styleClass="p-mr-1"
                            [value]="
                                descricaoReceitaSelecionada(
                                    cc.codReceitaPosEvento,
                                    cc.conta?.receitas
                                )
                            "
                        ></p-tag>
                    </td>
                    <td>
                        {{ cc.contaPosEvento?.nomeResumido }}
                    </td>
                    <td>{{ cc.inicioVigencia }}</td>
                    <td>{{ cc.fimVigencia }}</td>
                    <td>{{ cc.implantado ? "Sim" : "Não" }}</td>
                    <td>
                        <button
                            *ngIf="!cc.implantado"
                            [disabled]="indexEdicao != null"
                            pButton
                            pRipple
                            title="Editar"
                            type="button"
                            icon="pi pi-pencil"
                            class="p-button-rounded"
                            (click)="editarCC(cc)"
                        ></button>
                        <button
                            *ngIf="!cc.implantado"
                            [disabled]="indexEdicao != null"
                            pButton
                            pRipple
                            title="Remover"
                            type="button"
                            icon="pi pi-trash"
                            class="p-button-rounded"
                            (click)="removerCC(cc)"
                        ></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td class="p-text-center" colspan="10">
                        Informe pelo menos uma conta
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <p
            class="erro-validacao"
            *ngIf="
                (contasForm.controls.contasSelecionadas.touched ||
                    validarCampos) &&
                contasForm.controls.contasSelecionadas.errors?.required
            "
        >
            Campo obrigatório
        </p>
    </div>
</div>

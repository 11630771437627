<p-dialog header="Dados de Lançamento" [(visible)]="visible">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
            <label>Origem da Informação:</label>
            {{ dadosLancamento?.origemInformacao || "-" }}
        </div>
        <br />
        <div class="p-field p-col-12">
            <p-fieldset legend="Itens de Composição da Conta">
                <div class="p-fluid p-formgrid p-grid font-style">
                    <div class="p-field p-col-12 p-md-12">
                        <p-table
                            [value]="dadosLancamento?.itensComposicaoConta"
                        >
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Código</th>
                                    <th>Campo</th>
                                    <th>Descrição</th>
                                    <th>Valor</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-item>
                                <tr>
                                    <td>{{ item.itemComposicaoId }}</td>
                                    <td>{{ item.itemCampo }}</td>
                                    <td>{{ item.itemDescricao }}</td>
                                    <td>
                                        {{
                                            formatItemValor(
                                                item.itemTipo,
                                                item.valorOrigem
                                            )
                                        }}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </p-fieldset>
        </div>
        <br />
        <div class="p-field p-col-12">
            <p-fieldset legend="Datas de Referência">
                <div class="p-fluid p-formgrid p-grid font-style">
                    <div class="p-field p-col-12 p-md-12">
                        <p-table [value]="dadosLancamento?.datasReferencia">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Tipo de Data</th>
                                    <th>Data</th>
                                    <th class="p-text-center">
                                        Ocorreu evento
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template
                                pTemplate="body"
                                let-data
                                let-rowIndex="rowIndex"
                            >
                                <tr>
                                    <td>
                                        {{ data.tipoDataReferenciaId }} -
                                        {{ data.descricaoTipoDataReferencia }}
                                    </td>
                                    <td>
                                        {{
                                            data.dataInformada
                                                | date : "dd/MM/yyyy"
                                        }}
                                    </td>
                                    <td class="p-text-center">
                                        {{ data.ocorreuEvento }}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </p-fieldset>
        </div>
    </div>
</p-dialog>

import { Component } from "@angular/core";

@Component({
    selector: "modal-detalhe-parcelamento",
    templateUrl: "./modal-detalhe-parcelamento.component.html",
    styleUrls: ["./modal-detalhe-parcelamento.component.scss"],
})
export class ModalDetalheParcelamentoComponent {
    visible: boolean;

    constructor() {}

    public showDialog() {
        this.visible = true;
    }

    hiddenDialog() {
        this.visible = false;
    }
}

import {
    Directive,
    EmbeddedViewRef,
    Input,
    OnChanges,
    SimpleChanges,
    TemplateRef,
    ViewContainerRef,
} from "@angular/core";

@Directive({
    selector: "[recreateViewOnValueChange]",
})
export class RecreateViewDirective implements OnChanges {
    @Input() recreateViewOnValueChange: any;

    viewRef: EmbeddedViewRef<any>;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["recreateViewOnValueChange"]) {
            if (this.viewRef) {
                this.destroyView();
            }

            this.createView();
        }
    }

    private createView() {
        this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
    }

    private destroyView() {
        this.viewRef.destroy();
        this.viewRef = null;
    }
}

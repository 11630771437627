<p-dialog
    header="Dados da Transação"
    [(visible)]="visible"
    [style]="{ width: '50vw' }"
    [maximizable]="true"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
    (onHide)="hiddenDialog()"
>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
            <strong>Código: </strong>
            <span>{{ transacao?.codigo }}</span>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <strong>Nome completo: </strong>
            <span>{{ transacao?.nomeCompleto }}</span>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <strong>Nome Resumido: </strong>
            <span>{{ transacao?.nomeResumido }}</span>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <strong>Tipo de Processamento: </strong>
            <span> {{ transacao?.tipoProcessamento }} </span>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <strong>Operação: </strong>
            <span> {{ transacao?.operacao }} </span>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <strong>Situação: </strong>
            <span> {{ transacao?.situacao }} </span>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <strong>Data de Criação: </strong>
            <span> {{ converteDataHora(transacao?.dataCriacao) }} </span>
        </div>
        <div class="p-field p-col-12" *ngIf="transacao?.indice">
            <strong>Índice: </strong>
            <span> {{ transacao?.indice }} </span>
        </div>
        <div class="p-field p-col-12">
            <strong>Observação: </strong>
            <span> {{ transacao?.observacao }} </span>
        </div>
    </div>
    <ng-container
        *ngIf="
            transacao?.baseIncidenciaRegraCalculo &&
            transacao?.baseIncidenciaRegraCalculo.length > 0
        "
    >
        <h5>Regra de Cálculo</h5>
        <hr />
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-6">
                <strong>Base de Incidência: </strong><br />
                <ul>
                    <li
                        *ngFor="let bi of transacao?.baseIncidenciaRegraCalculo"
                    >
                        {{ bi }}
                    </li>
                </ul>
            </div>
        </div>

        <h5>Momento da Incidência</h5>
        <hr />
        <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="transacao?.dataReferenciaMomentoIncidencia"
        >
            <div class="p-field p-col-12 p-md-6">
                <strong>Data Referência: </strong>
                <span> {{ transacao?.dataReferenciaMomentoIncidencia }} </span>
            </div>
            <div
                class="p-field p-col-12 p-md-6"
                *ngIf="transacao?.regraMomentoIncidencia"
            >
                <strong>Regra: </strong>
                <span> {{ transacao?.regraMomentoIncidencia }} </span>
            </div>
            <div
                class="p-field p-col-12 p-md-6"
                *ngIf="transacao?.naturezaMomentoIncidencia"
            >
                <strong>Natureza: </strong>
                <span> {{ transacao?.naturezaMomentoIncidencia }} </span>
            </div>
            <div
                class="p-field p-col-12 p-md-6"
                *ngIf="transacao?.diaMomentoIncidencia != null"
            >
                <strong>Dia: </strong>
                <span> {{ transacao?.diaMomentoIncidencia }} </span>
            </div>
            <div
                class="p-field p-col-12 p-md-6"
                *ngIf="transacao?.mesMomentoIncidencia != null"
            >
                <strong>Mês: </strong>
                <span> {{ transacao?.mesMomentoIncidencia }} </span>
            </div>
            <div
                class="p-field p-col-12 p-md-6"
                *ngIf="transacao?.quantidadeDiasMomentoIncidencia != null"
            >
                <strong>Quantidade de Dias: </strong>
                <span> {{ transacao?.quantidadeDiasMomentoIncidencia }} </span>
            </div>
            <div
                class="p-field p-col-12 p-md-6"
                *ngIf="transacao?.quantidadeMesMomentoIncidencia != null"
            >
                <strong>Quantidade de Meses: </strong>
                <span> {{ transacao?.quantidadeMesMomentoIncidencia }} </span>
            </div>
        </div>

        <h5>Forma de Cálculo</h5>
        <hr />
        <div class="p-fluid p-formgrid p-grid">
            <div
                class="p-field p-col-12 p-md-6"
                *ngIf="transacao?.tipoAliquotaFormaCalculo != null"
            >
                <strong>Tipo Alíquota: </strong>
                <span> {{ transacao?.tipoAliquotaFormaCalculo }} </span>
            </div>
            <div
                class="p-field p-col-12 p-md-6"
                *ngIf="transacao?.tipoCapitalizacaoFormaCalculo != null"
            >
                <strong>Tipo de Capitalização: </strong>
                <span> {{ transacao?.tipoCapitalizacaoFormaCalculo }} </span>
            </div>
            <div
                class="p-field p-col-12 p-md-6"
                *ngIf="transacao?.aliquotaFormaCalculo != null"
            >
                <strong>Alíquota: </strong>
                <span> {{ transacao?.aliquotaFormaCalculo }} </span>
            </div>
            <div
                class="p-field p-col-12 p-md-6"
                *ngIf="transacao?.indiceFormaCalculo != null"
            >
                <strong>Índice: </strong>
                <span> {{ transacao?.indiceFormaCalculo }} </span>
            </div>
            <div
                class="p-field p-col-12 p-md-6"
                *ngIf="transacao?.temAliquotaMaximaFormaCalculo != null"
            >
                <strong>Tem Alíquota Máxima: </strong>
                <span> {{ transacao?.temAliquotaMaximaFormaCalculo }} </span>
            </div>
            <div
                class="p-field p-col-12 p-md-6"
                *ngIf="transacao?.aliquotaMaximaFormaCalculo != null"
            >
                <strong>Alíquota Máxima: </strong>
                <span> {{ transacao?.aliquotaMaximaFormaCalculo }} </span>
            </div>
            <div
                class="p-field p-col-12 p-md-6"
                *ngIf="transacao?.periodicidadeIncidenciaFormaCalculo != null"
            >
                <strong>Periodicidade de Incidência: </strong>
                <span>
                    {{ transacao?.periodicidadeIncidenciaFormaCalculo }}
                </span>
            </div>
        </div>
    </ng-container>
    <ng-template pTemplate="footer">
        <p-button
            (click)="hiddenDialog()"
            icon="pi pi-angle-left"
            label="Voltar"
        ></p-button>
    </ng-template>
</p-dialog>

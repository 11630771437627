import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class EventosFinanceirosService {
    constructor(private http: HttpClient) {}

    getTipoEventos(): Observable<Array<any>> {
        return this.http.get<Array<any>>(`${environment.baseUrl}/tipo-evento`);
    }

    getEventoById(eventoId): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/eventos/${eventoId}`
        );
    }

    getTipoEventosById(tipoEventoId): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/eventos/get-by-tipo-evento/${tipoEventoId}`
        );
    }

    getAllEventos(isTransacao: boolean): Observable<Array<any>> {
        console.log(`${environment.baseUrl}/eventos?isTransacao=${isTransacao}`)
        return this.http.get<Array<any>>(`${environment.baseUrl}/eventos?isTransacao=${isTransacao}`);
    }

    operacaoEvento(): Observable<Array<any>> {
        return this.http.get<Array<any>>(
            `${environment.baseUrl}/enums/evento/operacao`
        );
    }
}

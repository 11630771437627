<div class="card">
    <p-steps
        [model]="items"
        [(activeIndex)]="activeIndex"
        [readonly]="nextPage"
        class="time-line"
    ></p-steps>
</div>

<ccp-pesquisa-contribuinte
    *ngIf="activeIndex == 0"
    (outEtapaVeiculo)="outEtapas($event)"
></ccp-pesquisa-contribuinte>

<ccp-selecao-conta
    *ngIf="activeIndex == 1"
    (outEtapaSelecaoConta)="outEtapas($event)"
>
</ccp-selecao-conta>

<div *ngIf="activeIndex == 2">
    <ccp-detalhamento-conta
        (outEtapaSelecaoConta)="outEtapas($event)"
        *recreateViewOnValueChange="value"
    >
    </ccp-detalhamento-conta>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RecreateViewDirective } from './RecreateViewDirective.directive';


@NgModule({
  declarations: [
    RecreateViewDirective
  ],
  exports: [
    RecreateViewDirective
  ],
  imports: [
    CommonModule
  ]
})
export class directiveModule { }
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ArrecadaCaoDareService } from "src/app/component/criacao-conta-corrente/services/arrecadacao-dare.service";
import { ConsultaContaCorrenteService } from "src/app/component/criacao-conta-corrente/services/consulta-conta-corrente.service";
import { UtilsService } from "src/app/shared/utils.service";
import { AlertsUtil } from "src/app/utils/alerts/alerts.util";

@Component({
    selector: "modal-alerta-honorario",
    templateUrl: "./modal-alerta-honorario.component.html",
    styleUrls: ["./modal-alerta-honorario.component.scss"],
})
export class ModalAlertaHonorarioComponent {
    @Input() set contaCorrenteId(id) {
        if (id) {
            this.consultarContasAssociadas(id);
        }
    }
    @Output() contaCorrenteIdChange = new EventEmitter<number>();

    visible: boolean;
    contaList: any[] = [];

    constructor(
        private consultaContaCorrenteService: ConsultaContaCorrenteService,
        private utilService: UtilsService,
        private arrecadaCaoDareService: ArrecadaCaoDareService,
        private alertsUtil: AlertsUtil
    ) {}

    consultarContasAssociadas(idConta) {
        this.consultaContaCorrenteService
            .getContaVinculadaDare(idConta)
            .subscribe(
                (res: any[]) => {
                    this.contaList = res.filter(c => c.idTipoAssociacao == null || c.idTipoAssociacao == 1); // apenas honorarios e o principal (null)
                    this.visible = true;
                    this.contaCorrenteIdChange.emit(null);
                },
                () => this.contaCorrenteIdChange.emit(null)
            );
    }

    hiddenDialog() {
        this.visible = false;
    }

    preparePayloadContas() {
        const contas = this.contaList.map((conta) => ({
            contaCorrenteId: conta.contaCorrenteId,
            codigoReceita: conta.codReceita,
        }));

        return {
            contas,
            dataVencimento: this.contaList[0].dataVencimento,
            informacoesComplementares: null,
        };
    }

    emitirDare() {
        this.arrecadaCaoDareService
            .postArrecadacaoDare(this.preparePayloadContas())
            .subscribe((data) => {
                this.alertsUtil.saveSuccess(`Dare emitido com sucesso.`);
                this.visible = false;
                this.emitePdfDare(data.base64);
            });
    }

    emitePdfDare(dataResponse) {
        this.utilService.geraPdfStringBase64(dataResponse);
    }
}

import { Component, OnInit } from '@angular/core';
import {MensagensNaoLidaDteService} from "./service/mensagens-nao-lida-dte.service";
import {environment} from "../../../environments/environment";
import {KeycloakService} from "keycloak-angular";
import {AppTopbarService} from "../../app.topbar.service";

@Component({
  selector: 'app-mensagens-nao-lida-dte',
  templateUrl: './mensagens-nao-lida-dte.component.html',
  styleUrls: ['./mensagens-nao-lida-dte.component.scss']
})
export class MensagensNaoLidaDteComponent implements OnInit {
    displayedColumns: string[] = [ 'destinatario', 'qtdMensagensNaoLidas'];
    isVisible: boolean = false;
    resposta: any[] = [];

    constructor(private mensagensNaoLidaDteService: MensagensNaoLidaDteService,
                private keycloakService: KeycloakService,
                private appTopbarService: AppTopbarService) { }

    ngOnInit(): void {
        this.mensagensNaoLidaDteService.getMensagemNaoLidaDTE().subscribe(
            (data) => {
                this.resposta = data;
                if(this.resposta.length == 0){
                    this.isVisible = false;
                }else{
                    this.isVisible = true;
                }
            },
            (error) => {
                console.error('Erro na requisição:', error);
            }
        );
    }

    openModal(): void {
        this.isVisible = true;
    }

    closeModal(): void {
        this.logOff()
    }

    logOff(): void {
        this.keycloakService.logout(environment.redirectUrl);
        this.appTopbarService.clearProfile();
        localStorage.clear();
        sessionStorage.clear();
    }

}

<p-dialog
    header="Emitir DARE"
    [(visible)]="visible"
    [modal]="true"
    [style]="{ width: '90vw' }"
    [maximizable]="true"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
>
    <p-table [value]="contaList" [scrollable]="true">
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="tipo">
                    Tipo <p-sortIcon field="tipo"></p-sortIcon>
                </th>
                <th pSortableColumn="conta">
                    Conta <p-sortIcon field="conta"></p-sortIcon>
                </th>
                <th pSortableColumn="numeroDocumento">
                    Número do Documento
                    <p-sortIcon field="numeroDocumento"></p-sortIcon>
                </th>
                <th pSortableColumn="contribuinte">
                    Contribuinte
                    <p-sortIcon field="contribuinte"></p-sortIcon>
                </th>
                <th pSortableColumn="periodoReferencia">
                    Perído de Referência
                    <p-sortIcon field="periodoReferencia"></p-sortIcon>
                </th>
                <th pSortableColumn="dataVencimento">
                    Dt. Vencimento
                    <p-sortIcon field="dataVencimento"></p-sortIcon>
                </th>
                <th pSortableColumn="estadoConta">
                    Estado da Conta
                    <p-sortIcon field="estadoConta"></p-sortIcon>
                </th>
                <th pSortableColumn="saldoConta">
                    Saldo da Conta
                    <p-sortIcon field="saldoConta"></p-sortIcon>
                </th>
                <th pSortableColumn="situacaoCredito">
                    Sit. Crédito
                    <p-sortIcon field="situacaoCredito"></p-sortIcon>
                </th>
                <th pSortableColumn="situacaoCobranca">
                    Sit. Cobrança
                    <p-sortIcon field="situacaoCobranca"></p-sortIcon>
                </th>
                <th>Cód. Receita</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-conta>
            <tr class="selecao">
                <td>{{ conta?.tipo || 'Principal' }}</td>
                <td>{{ conta?.conta }}</td>
                <td>{{ conta?.numeroDocumento }}</td>
                <td>{{ conta?.contribuinte }}</td>
                <td>{{ conta?.periodoReferencia }}</td>
                <td>{{ conta?.dataVencimento | date : "dd/MM/yyyy" }}</td>
                <td>{{ conta?.estadoConta }}</td>
                <td>{{ conta?.saldoConta | currency : "BRL" : "" }}</td>
                <td>{{ conta?.situacaoCredito }}</td>
                <td>{{ conta?.situacaoCobranca }}</td>
                <td *ngIf="conta?.codigosReceita?.length === 1">
                    {{ conta?.codigosReceita[0] }}
                </td>
                <td *ngIf="conta?.codigosReceita?.length > 1">
                    <p-dropdown
                        [options]="conta?.codigosReceita"
                        [(ngModel)]="conta.codReceita"
                        appendTo="body"
                    ></p-dropdown>
                </td>
            </tr>
        </ng-template>
    </p-table>

    <div class="p-mt-3" style="text-align: right">
        <button
            pButton
            label="Cancelar"
            icon="pi pi-close-circle"
            class="p-button-outlined p-mr-2"
            (click)="hiddenDialog()"
        ></button>
        <button
            pButton
            class="float-btn-next"
            label="Emitir DARE"
            icon="pi pi-plus-circle"
            (click)="emitirDare()"
        ></button>
    </div>
</p-dialog>

<p-dialog [(visible)]="visible" [style]="{ width: '80vw' }">
    <br />
    <div class="p-fluid p-grid">
        <div class="p-field p-col-12">
            <p-fieldset legend="Dados do Parcelamento">
                <div class="p-fluid p-formgrid p-grid font-style">
                    <div class="p-field p-col-6">
                        <strong>Usuário:</strong>
                        {{ dadosParcelamento?.usuarioNome || "-" }}
                    </div>
                    <div class="p-field p-col-6">
                        <strong>Tipo de Parcelamento:</strong>
                        {{
                            dadosParcelamento?.descricaoTipoParcelamento || "-"
                        }}
                    </div>
                    <div class="p-field p-col-6">
                        <strong>Data:</strong>
                        {{ dadosParcelamento?.dataCriacao || "-" }}
                    </div>
                    <div class="p-field p-col-6">
                        <strong>Nº do Parcelamento:</strong>
                        {{ dadosParcelamento?.numeroParcelamento || "-" }}
                    </div>
                    <div class="p-field p-col-12">
                        <strong>Descrição:</strong>
                        {{ dadosParcelamento?.descricaoParcelamento || "-" }}
                    </div>
                </div>
            </p-fieldset>
        </div>
        <br />
        <div class="p-field p-col-12">
            <p-fieldset legend="Caracterização da Dívida">
                <div class="p-fluid p-formgrid p-grid font-style">
                    <div class="p-field p-col-12 p-md-12">
                        <p-table
                            [value]="
                                dadosParcelamento?.contasOrigemParcelamento
                            "
                        >
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Id</th>
                                    <th>Descrição</th>
                                    <th>Nº Documento</th>
                                    <th>Vencimento</th>
                                    <th>Principal</th>
                                    <th>Multa</th>
                                    <th>Juros</th>
                                    <th>Honorário</th>
                                    <th>Total</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-item>
                                <tr>
                                    <td>{{ item.contaId }}</td>
                                    <td>{{ item.descricaoConta }}</td>
                                    <td>{{ item.numeroDocumento }}</td>
                                    <td>{{ item.dataVencimento }}</td>
                                    <td>
                                        {{
                                            item.valorPrincipal
                                                | currency : "BRL"
                                        }}
                                    </td>
                                    <td>
                                        {{ item.valorMulta | currency : "BRL" }}
                                    </td>
                                    <td>
                                        {{ item.valorJuros | currency : "BRL" }}
                                    </td>
                                    <td>
                                        {{
                                            item.valorHonorario
                                                | currency : "BRL"
                                        }}
                                    </td>
                                    <td>
                                        {{ item.valorTotal | currency : "BRL" }}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </p-fieldset>
        </div>
        <br />
        <p-fieldset legend="Demonstrativo Cálculo">
            <div class="p-fluid p-formgrid p-grid font-style">
                <div class="p-field p-col-12 demonstrativo">
                    <strong>Principal Original</strong>
                    {{
                        dadosParcelamento?.valorPrincipalOrigem
                            | currency : "BRL"
                    }}
                </div>
                <div class="p-field p-col-12 demonstrativo">
                    <strong>Multa Original</strong>
                    {{ dadosParcelamento?.valorMultaOrigem | currency : "BRL" }}
                </div>
                <div class="p-field p-col-12 demonstrativo">
                    <strong>Juros Original</strong>
                    {{ dadosParcelamento?.valorJurosOrigem | currency : "BRL" }}
                </div>
                <div class="p-field p-col-12 demonstrativo">
                    <strong>Total Original</strong>
                    {{ dadosParcelamento?.valorTotalOrigem | currency : "BRL" }}
                </div>
                <br />
                <div class="p-field p-col-12 demonstrativo">
                    <strong>Valores Parcelados</strong>
                    {{
                        dadosParcelamento?.valorParcelamento | currency : "BRL"
                    }}
                </div>
                <div class="p-field p-col-12 demonstrativo">
                    <strong>Principal Parcelado</strong>
                    {{
                        dadosParcelamento?.valorPrincipalParcelamento
                            | currency : "BRL"
                    }}
                </div>
                <div class="p-field p-col-12 demonstrativo">
                    <strong>Multa Parcelado (Mora e Infração)</strong>
                    {{
                        dadosParcelamento?.valorMultaParcelamento
                            | currency : "BRL"
                    }}
                </div>
                <div class="p-field p-col-12 demonstrativo">
                    <strong>Juros Parcelado</strong>
                    {{
                        dadosParcelamento?.valorJurosParcelamento
                            | currency : "BRL"
                    }}
                </div>
                <div class="p-field p-col-12 demonstrativo">
                    <strong>Total Parcelado</strong>
                    {{
                        dadosParcelamento?.valorTotalParcelamento
                            | currency : "BRL"
                    }}
                </div>
                <div class="p-field p-col-12 demonstrativo">
                    <strong>Honorário</strong>
                    {{ dadosParcelamento?.valorHonorario | currency : "BRL" }}
                </div>
                <div class="p-field p-col-12 demonstrativo">
                    <strong>Número de parcelas</strong>
                    {{ dadosParcelamento?.numeroParcelas }}
                </div>
                <div class="p-field p-col-12 demonstrativo">
                    <strong>Valor da Primeira Parcela</strong>
                    {{
                        dadosParcelamento?.valorPrimeiraParcela
                            | currency : "BRL"
                    }}
                </div>
                <div class="p-field p-col-12 demonstrativo">
                    <strong>Honorário da Primeira Parcela</strong>
                    {{
                        dadosParcelamento?.valorHonorarioPrimeiraParcela
                            | currency : "BRL"
                    }}
                </div>
                <div class="p-field p-col-12 demonstrativo">
                    <strong>Valor das Demais Parcelas</strong>
                    {{
                        dadosParcelamento?.valorDemaisParcelas
                            | currency : "BRL"
                    }}
                </div>
                <div class="p-field p-col-12 demonstrativo">
                    <strong>Honorário das Demais Parcelas</strong>
                    {{
                        dadosParcelamento?.valorHonorarioDemaisParcelas
                            | currency : "BRL"
                    }}
                </div>
            </div>
        </p-fieldset>
    </div>
</p-dialog>

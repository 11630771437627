<p-dialog
    [visible]="isVisible"
    [responsive]="true"
    [style]="{ maxWidth: '90vw' }"
    [closable]="false"
    [resizable]="false"
    [modal]="true">

    <div class="modal-backdrop" (click)="closeModal()"></div>

    <div class="modal">
        <div class="modal-content">
            <h2>Há mensagens não lidas(DTE) para as Inscrições Estaduais abaixo:</h2>
        </div>
    </div>

    <p-table [value]="resposta" [columns]="displayedColumns" rows="10"
             [paginator]="true" [rowHover]="true" responsiveLayout="scroll"
             [lazyLoadOnInit]="false" styleClass="p-datatable-gridlines" paginatorPosition="bottom"
             [resetPageOnSort]="true" [autoLayout]="true" [responsive]="true"
             [lazy]="true" [totalRecords]="resposta?.length" [first]="0"
             [showCurrentPageReport]="true" currentPageReportTemplate="Exibindo de {first} a {last} no total de {totalRecords} registro(s)"
             [resizableColumns]="true" class="p-mt-4">
        <ng-template pTemplate="header">
            <tr>
                <th class="center-text" id="ie">Destinatário</th>
                <th class="center-text" id="qtdMensagensNaoLidas">Quantidade</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-data>
            <tr>
                <td class="center-text">{{data?.destinatario}}</td>
                <td class="center-text">{{data?.qtdMensagensNaoLidas}}</td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td [attr.colspan]="columns.length">
                    Nenhum registro encontrado.
                </td>
            </tr>
        </ng-template>
    </p-table>

    <ng-template pTemplate="footer">
        <p-button
            (click)="closeModal()"
            label="SAIR"
            styleClass="p-button-secondary"
            icon="pi pi-power-off" class="p-button-secondary mr-2 w-6rem "
        ></p-button>
    </ng-template>

</p-dialog>

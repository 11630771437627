import {
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { forkJoin } from "rxjs";
import { CpfCnpjPipe } from "src/app/compartilhado/pipes/cpf.pipe";
import { ConfigurarContaCorrenteService } from "src/app/component/configurar-conta-corrente/configurar-conta-corrente.service";
import { ModalAlertaHonorarioComponent } from "src/app/modal/modal-alerta-honorario/modal-alerta-honorario.component";
import { ModalExtratoContaCorrenteComponent } from "src/app/modal/modal-extrato-conta-corrente/modal-extrato-conta-corrente.component";
import { ModalSimularHistoricoComponent } from "src/app/modal/modal-simular-historico/modal-simular-historico.component";
import { Pagination } from "src/app/shared/pagination/pagination";
import { UtilsService } from "src/app/shared/utils.service";
import { AlertsUtil } from "src/app/utils/alerts/alerts.util";
import { MaskCpfOuCnpj } from "src/app/utils/masklCpfCnpjList/maskCpfCnpj.util";
import { RemoveParamsUtil } from "src/app/utils/paramsRemove/paramsRemove.util";
import swal from "sweetalert2";
import { RelatorioConta } from "../../../../../models/relatorio-conta";
import { ConsultaContaCorrenteService } from "../../../services/consulta-conta-corrente.service";

enum TipoRelatorio {
    XLS = "Excel",
    PDF = "PDF",
}

@Component({
    selector: "ccp-selecao-conta",
    templateUrl: "./selecao-conta.component.html",
    styleUrls: ["./selecao-conta.component.scss"],
})
export class SelecaoContaComponent implements OnInit {
    @Output() outEtapaSelecaoConta = new EventEmitter();
    @ViewChild(ModalExtratoContaCorrenteComponent, { static: false })
    modalExtratoConta: ModalExtratoContaCorrenteComponent;

    @ViewChild(ModalSimularHistoricoComponent, { static: false })
    modalSimularHistorico: ModalSimularHistoricoComponent;

    @ViewChild(ModalAlertaHonorarioComponent, { static: false })
    modalAlertaPossuiHonorario: ModalAlertaHonorarioComponent;

    title: string = "Selecionar Conta";
    valueContribuinte: any;
    listContasIpvaItcdIcms: any[] = [];

    contribuinteIeList: number[] = [];
    relacaoProfissionalIeList: any[] = [];
    renavamList: any[] = [];
    renavamValue: any;
    inscricaoValue: any;
    pagination: Pagination<any>;
    paginationRenavam: Pagination<any>;
    abaSelecionada: string;
    objParamsFiltro: any;
    estadoContaList: any[];
    situacaoCreditoList: any[];
    saldoList: any[];
    situaCobrancaList: any[];
    tipoDocumentoList: any[];
    contaList: any[];
    contaDareVinculadaList: any[];

    /**Atributos filtro */
    contaValue: any;
    estadoValue: any;
    situacaoCreditoValue: any;
    saldoValue: any;
    situacaoCobrancaValue: any;
    dataInicialReferenciaValue: any;
    dataFinalReferenciaValue: any;
    tipoDocumentoValue: any;
    numeroDocumentoValue: any;
    tributoId: any;
    tipoNumeroDocumentoValue: any;
    msgNenhumRegistroEncontrado = `Não foi encontrado nenhum registro para `;
    rowIndexConta: any;
    contribuinteValue: any;
    beneficiarioItcdValue: any;
    tipoTributo: any;
    /**Remover OS 09 - blockeBtnFiltro */
    blockeBtnFiltro: boolean = true;
    selectedProducts: any[] = [];
    objParamsConta: {
        idsContas: any[];
        dataVencimento: any;
        informacoesComplementares: any;
    };
    oxen: any;

    tributoModels: any[];
    tipoConta = 0;

    pesquisaRealizada = false;
    imprimirDetalhes: boolean = false;
    exportandoDocumentos: boolean = false;
    deveLimparFiltros = true; //false qnd carrega filtrosUsadosAnteriormente

    isRenavamDisabled: boolean = false;
    isContribuinteDisabled: boolean = false;
    contaCorrenteIdDare: number = null;
    contribuinteRelacaoProfissionalList: any[] = [];
    listaBeneficiariosItcd: any[] = [];

    cpfCnpjPipe: CpfCnpjPipe = new CpfCnpjPipe();

    constructor(
        private consultaContaCorrenteService: ConsultaContaCorrenteService,
        private alertsUtil: AlertsUtil,
        private utilService: UtilsService,
        private removeParamsUtil: RemoveParamsUtil,
        private utils: UtilsService,
        private maskCpfOuCnpj: MaskCpfOuCnpj,
        private configContaService: ConfigurarContaCorrenteService
    ) {}

    ngOnInit(): void {
        const filtrosUsadosAnteriormente = JSON.parse(
            localStorage.getItem("filtrosCCAnterior")
        );

        if (filtrosUsadosAnteriormente) {
            this.deveLimparFiltros = false;
            this.popularFiltros(filtrosUsadosAnteriormente.objParams);
            this.abaSelecionada = filtrosUsadosAnteriormente.abaSelecionada;
            this.tipoConta = filtrosUsadosAnteriormente.indexTabAtiva;
            this.handleChange({ index: this.tipoConta }); //forcando rodar a logica pq so setar o indexTavAtiva nao foi
            this.tributoId = filtrosUsadosAnteriormente.tributoId;
            this.pagination = filtrosUsadosAnteriormente.pagination;
            this.pagination.size = filtrosUsadosAnteriormente.paginationSize;
            this.paginationRenavam =
                filtrosUsadosAnteriormente.paginationRenavam;

            this.filtroConta();
        } else {
            this.abaSelecionada = "IPVA";
            this.tipoConta = 0;
            this.tributoId = "1";
            this.pagination = new Pagination<any>();
            this.pagination.size = 20;
            this.paginationRenavam = new Pagination<any>();
        }
        this.popularValueTabPanel();
        setTimeout(() => {
            this.initStorage();
            this.getDropdownFiltro();
        }, 500);

        this.configContaService.getTributos().subscribe((response) => {
            this.tributoModels = response;
        });
    }

    initStorage() {
        this.valueContribuinte = JSON.parse(
            localStorage.getItem("contribuinte")
        );
        if (this.valueContribuinte) {
            setTimeout(() => {
                this.getRenavamPageable(
                    this.valueContribuinte,
                    JSON.parse(localStorage.getItem("valueTabPanel"))
                );
            }, 500);
        }
    }

    private popularFiltros(values) {
        this.valueContribuinte = values.valueContribuinte;
        this.contaValue = values.contaValue;
        this.estadoValue = values.estadoValue;
        this.situacaoCreditoValue = values.situacaoCreditoValue;
        this.saldoValue = values.saldoValue;
        this.situacaoCobrancaValue = values.situacaoCobrancaValue;
        this.dataInicialReferenciaValue = new Date(
            values.dataInicialReferenciaValue
        );
        this.dataFinalReferenciaValue = new Date(
            values.dataFinalReferenciaValue
        );
        this.contribuinteValue = values.contribuinteValue;
        this.tipoNumeroDocumentoValue = values.tipoNumeroDocumentoValue;
        this.numeroDocumentoValue = values.numeroDocumentoValue;
        this.renavamValue = values.renavamValue;
    }

    /**acao pesquisar contas */
    filtroConta() {
        this.gerarFiltros();
        /**faz consulta */
        this.getContaViewConta(this.objParamsFiltro);
    }

    private gerarFiltros() {
        /**Reset check box */
        if (!this.exportandoDocumentos) {
            this.selectedProducts = [];
        }

        const razaoFiltroPrincipal = localStorage
            .getItem("razaoFiltroPrincipal")
            .replace('"', "")
            .replace('"', "");
        const valueFiltroPrincipal = localStorage.getItem(
            "valueFiltroPrincipal"
        );
        const labelFiltroPrincipal = localStorage.getItem(
            "labelFiltroPrincipal"
        );

        this.objParamsFiltro = {
            cpfCnpjResponsavel: this.getResponsavel(),
            codigoCadastro: this.getCodigoCadastro(),
            configContaId: this.contaValue,
            estadoContaId: this.estadoValue,
            situacaoCreditoId: this.situacaoCreditoValue,
            saldoContaId: this.saldoValue,
            situacaoCobrancaId: this.situacaoCobrancaValue,
            periodoReferenciaInicial: this.dataInicialReferenciaValue
                ? this.utils.convertToSaveAnoMes(
                      this.dataInicialReferenciaValue
                  )
                : null,
            periodoReferenciaFinal: this.dataFinalReferenciaValue
                ? this.utils.convertToSaveAnoMes(this.dataFinalReferenciaValue)
                : null,
            tipoNumeroDocumentoId: this.tipoNumeroDocumentoValue,
            numeroDocumento: this.numeroDocumentoValue,
            tributoId: null,
            codTributo: this.tipoTributo ? this.tipoTributo.codTributo : null,
            valueFiltroPrincipal: valueFiltroPrincipal
                ? valueFiltroPrincipal
                : null,
            labelFiltroPrincipal: labelFiltroPrincipal
                ? labelFiltroPrincipal
                : null,
            imprimirDetalhes: this.imprimirDetalhes,
            listVwContaCorrenteRequest:
                this.selectedProducts && this.selectedProducts.length > 0
                    ? this.selectedProducts
                    : null,
        };

        if (this.contribuinteValue) {
            var dadosIeRelacaoProf = this.relacaoProfissionalIeList.find(
                (c: any) => c.ieContribuinte === this.contribuinteValue[0]
            );
            var tipoCadastroId = dadosIeRelacaoProf
                ? dadosIeRelacaoProf?.tipoCadastroId
                : 1; // 1 = inscricao estadual
            this.objParamsFiltro.tipoCadastroId = tipoCadastroId;
        }
        this.objParamsFiltro =
            this.removeParamsUtil.removeParametroNaoUtilizado(
                this.objParamsFiltro
            );

        const backup = {
            abaSelecionada: this.abaSelecionada,
            indexTabAtiva: this.tipoConta,
            tributoId: this.tributoId,
            pagination: this.pagination,
            paginationSize: this.pagination.size,
            paginationRenavam: this.paginationRenavam,
            objParams: {
                contribuinteValue: this.contribuinteValue,
                valueContribuinte: this.valueContribuinte,
                renavamValue: this.renavamValue,
                contaValue: this.contaValue,
                estadoValue: this.estadoValue,
                situacaoCreditoValue: this.situacaoCreditoValue,
                saldoValue: this.saldoValue,
                situacaoCobrancaValue: this.situacaoCobrancaValue,
                dataInicialReferenciaValue: this.dataInicialReferenciaValue,
                dataFinalReferenciaValue: this.dataFinalReferenciaValue,
                tipoNumeroDocumentoValue: this.tipoNumeroDocumentoValue,
                numeroDocumentoValue: this.numeroDocumentoValue,
                tipoTributo: this.tipoTributo,
            },
        };
        //salvando parametros usados na consulta para qnd navegar no detalhe e voltar novamente

        localStorage.setItem("filtrosCCAnterior", JSON.stringify(backup));
    }

    getCodigoCadastro() {
        switch (this.abaSelecionada) {
            case "IPVA":
                return [this.renavamValue ?? this.valueContribuinte.baseCnpj];

            case "ICMS":
                return [
                    this.contribuinteValue ?? this.valueContribuinte.baseCnpj,
                ];
            case "ITCD":
                return [
                    this.beneficiarioItcdValue ??
                        this.valueContribuinte.baseCnpj,
                ];
            case "PARCELAMENTO":
            case "HONORARIOS":
            case "OUTROS":
                let strings = [];
                if (this.renavamValue && this.renavamValue !== "") {
                    strings.push(this.renavamValue);
                } else if (
                    this.contribuinteValue &&
                    this.contribuinteValue !== ""
                ) {
                    strings.push(this.contribuinteValue);
                } else {
                    strings.push(this.valueContribuinte.baseCnpj);
                }
                return strings;
            default:
                return [];
        }
    }

    getResponsavel() {
        switch (this.abaSelecionada) {
            case "ITCD":
                return [
                    this.beneficiarioItcdValue ??
                        this.valueContribuinte.baseCnpj,
                ];
            default:
                return [this.valueContribuinte.baseCnpj];
        }
    }

    getRenavamPageable(contribuinte: any, valuePanel?: any) {
        if (valuePanel !== "ITCD" && valuePanel !== "ICMS") {
            /**Lista renavam */
            const objParamsIpva = {
                cpfCnpjRenavam: contribuinte.baseCnpj,
            };
            /**verificar necessidade do tamanho */
            this.paginationRenavam.size = 10000;
            this.consultaContaCorrenteService
                .getRenavamPageable(objParamsIpva, this.paginationRenavam)
                .subscribe((response: any) => {
                    this.paginationRenavam = response;
                    this.paginationRenavam.content.forEach((element) => {
                        this.renavamList.push({
                            label:
                                element.renavam +
                                " - " +
                                element.chassi +
                                " - " +
                                element.placa,
                            idRenavam: element.idRenavam,
                        });
                    });
                });
        }
    }

    validaQtdRenavam(evento: any) {
        if (this.renavamValue.length > 10) {
            this.alertsUtil
                .warning(`O limite de 10 itens foi atingido. Refaça a seleção.`)
                .then((result) => {
                    if (result.isConfirmed) {
                        this.renavamValue = undefined;
                    }
                });
        }
    }

    limiteCaractere(evento) {
        if (evento?.filter.length > 50) {
            this.alertsUtil.warning(
                `Foi informado um grande número de caracteres no filtro de pesquisa.`
            );
        }
    }

    getContribuinteInscricaoEstadual() {
        /**Lista inscrição estadual */
        const objParamsIcms = {
            cpfCnpj: this.valueContribuinte?.baseCnpj,
        };

        forkJoin([
            this.consultaContaCorrenteService.getRelacaoProfissional(
                this.valueContribuinte?.baseCnpj
            ),
            this.consultaContaCorrenteService.getContribuinte(objParamsIcms),
        ]).subscribe(([relacaoProfissionalList, contribuintes]) => {
            this.relacaoProfissionalIeList = relacaoProfissionalList;
            this.contribuinteIeList = contribuintes?.content?.map(
                (c) => c.ieDv
            );

            const contribuintesMap = contribuintes?.content
                .filter(
                    (c) =>
                        !relacaoProfissionalList.find(
                            (rp) => rp.ieContribuinte === c.ieDv
                        )
                )
                .filter(c => !(c.ieDv as string).startsWith("9"))
                .map((c) => ({
                    value: c.ieDv,
                    label: c.ieDv
                        .concat(" - ")
                        .concat(c.nome)
                        .concat(" - ")
                        .concat(c.descTipo || ""),
                }));

            const relacaoProfissionalMap = relacaoProfissionalList.map(
                (rp) => ({
                    value: rp.ieContribuinte,
                    label: rp.ieContribuinte
                        .concat(" - ")
                        .concat(rp.nomeContribuinte)
                        .concat(" - ")
                        .concat(rp.tipoRelacionamentoDescricao || ""),
                })
            );

            this.contribuinteRelacaoProfissionalList = [
                ...contribuintesMap,
                ...relacaoProfissionalMap,
            ];
        });
    }

    onPageChangeIpva(e) {
        this.pagination.size = e.rows;
        this.pagination.pageable.pageNumber = e.page;
        this.getContaViewConta(this.objParamsFiltro);
    }

    onPageChange(e) {
        this.pagination.size = e.rows;
        this.pagination.pageable.pageNumber = e.page;
        this.getContaViewConta(this.objParamsFiltro);
    }

    checkIeClear(event) {
        if (!event.value || event.value.length == 0) {
            this.contribuinteValue = null;
        }
    }

    getBeneficiariosInventario() {
        const objparams = {
            cpfCnpj: this.valueContribuinte?.baseCnpj,
            codTipo: 1,
        };
        const page = new Pagination<any>();
        page.size = 10000;

        this.consultaContaCorrenteService
            .getDetalheItcd(objparams, page)
            .subscribe((response: any) => {
                this.listaBeneficiariosItcd = response.content.map((b) => ({
                    value: b.cpfCnpjBeneficiario,
                    label: this.cpfCnpjPipe
                        .transform(b.cpfCnpjBeneficiario)
                        .concat(" - ")
                        .concat(b.nomeBeneficiario)
                        .concat(" - ")
                        .concat(b.tdiNumDecl),
                }));
            });
    }

    getContaViewConta(objParams) {
        const tabValue = this.abaSelecionada.toLowerCase();

        this.consultaContaCorrenteService
            .getContaViewConta(objParams, this.pagination, tabValue)
            .subscribe((response: any) => {
                this.pagination = response;
                this.listContasIpvaItcdIcms = response.content;

                this.pesquisaRealizada = this.listContasIpvaItcdIcms.length > 0;

                if (!this.pesquisaRealizada) {
                    let complemento = "o CPF/CNPJ consultado.";

                    if (
                        this.exibirFiltroRenavan() &&
                        this.renavamValue &&
                        this.renavamValue.length !== ""
                    ) {
                        complemento = "o Renavam consultado.";
                    }

                    if (
                        this.exibirFiltroIe() &&
                        this.contribuinteValue &&
                        this.contribuinteValue !== ""
                    ) {
                        complemento = "a Inscrição Estadual consultada.";
                    }

                    this.alertsUtil.warning(
                        this.msgNenhumRegistroEncontrado + complemento
                    );
                }
            });
    }

    irSelecaoContribuinte() {
        this.outEtapaSelecaoConta.emit(0);
    }

    irDetalhamentoConta(conta) {
        localStorage.setItem("conta", JSON.stringify(conta));
        this.outEtapaSelecaoConta.emit(2);
    }

    converteValue(ev) {
        let ano = ev.toString().substring(0, 4);
        let mes = ev.toString().substring(4, 6);
        return `${mes}/${ano}`;
    }

    handleChange(ev) {
        this.limparFiltros();
        /**reset objParams */
        this.objParamsFiltro = null;
        this.popularValueTabPanel();
        this.tributoId = this.verificaTributoId();
        // this.getContribuinteByRenavam();
    }

    private popularValueTabPanel() {
        switch (this.tipoConta) {
            case 0:
                localStorage.setItem("valueTabPanel", JSON.stringify("IPVA"));
                this.listFiltroContaIpvaItcdIcms("IPVA");

                break;
            case 1:
                localStorage.setItem("valueTabPanel", JSON.stringify("ITCD"));
                // this.listFiltroContaItcdOrIcms('ITCD');
                this.getBeneficiariosInventario();
                this.listFiltroContaIpvaItcdIcms("ITCD");
                break;
            case 2:
                localStorage.setItem("valueTabPanel", JSON.stringify("ICMS"));
                // this.listFiltroContaItcdOrIcms('ICMS');
                this.listFiltroContaIpvaItcdIcms("ICMS");
                this.getContribuinteInscricaoEstadual();
                break;
            case 3:
                localStorage.setItem(
                    "valueTabPanel",
                    JSON.stringify("PARCELAMENTO")
                );
                this.listFiltroContaIpvaItcdIcms("PARCELAMENTO");
                this.getContribuinteInscricaoEstadual();
                break;
            case 4:
                localStorage.setItem(
                    "valueTabPanel",
                    JSON.stringify("HONORARIOS")
                );
                this.listFiltroContaIpvaItcdIcms("HONORARIOS");
                this.getContribuinteInscricaoEstadual();
                break;
            case 5:
                localStorage.setItem("valueTabPanel", JSON.stringify("OUTROS"));
                this.listFiltroContaIpvaItcdIcms("OUTROS");
                this.getContribuinteInscricaoEstadual();
                break;
            default:
                break;
        }
        this.abaSelecionada = JSON.parse(localStorage.getItem("valueTabPanel"));
        localStorage.setItem(
            "tabTipoContaDetalhar",
            localStorage.getItem("valueTabPanel")
        );
    }

    verificaTributoId() {
        let tributoId: any;
        if (this.abaSelecionada == "IPVA") {
            tributoId = "4";
        } else if (this.abaSelecionada == "ICMS") {
            tributoId = "2";
        } else if (this.abaSelecionada == "ITCD") {
            tributoId = "1";
        } else {
            return null;
        }
        return tributoId;
    }

    limparFiltros() {
        this.pesquisaRealizada = false;
        if (!this.deveLimparFiltros) {
            // nao deve limpar filtros quando volta do detalhe e o filtrosCCAnterior for verificado
            this.deveLimparFiltros = true;
            return;
        }
        this.pagination = new Pagination<any>();
        this.pagination.size = 20;
        (this.renavamValue = undefined),
            (this.contaValue = null),
            (this.estadoValue = null),
            (this.situacaoCreditoValue = null),
            (this.saldoValue = null),
            (this.situacaoCobrancaValue = null),
            (this.dataInicialReferenciaValue = null),
            (this.dataFinalReferenciaValue = null),
            (this.numeroDocumentoValue = null),
            (this.tributoId = null),
            (this.listContasIpvaItcdIcms = []),
            (this.tipoNumeroDocumentoValue = null),
            (this.contribuinteValue = null),
            (this.selectedProducts = []);

        this.ativarInativarCampos();
    }

    /**Valores lista Dropdown Filtros */
    async getDropdownFiltro() {
        const paramsEstadoConta = {
            situacao: "ATIVA",
        };
        const paramsContaCorrente = {
            siglaTributo: this.abaSelecionada,
        };
        this.consultaContaCorrenteService
            .getEstadoConta(paramsEstadoConta)
            .subscribe((res) => (this.estadoContaList = res));
        this.consultaContaCorrenteService
            .getSituacaoCredito()
            .subscribe((res) => (this.situacaoCreditoList = res));
        this.consultaContaCorrenteService
            .getSaldoConta()
            .subscribe((res) => (this.saldoList = res));
        this.consultaContaCorrenteService
            .getSituacaoCobranca()
            .subscribe((res) => (this.situaCobrancaList = res));
        this.consultaContaCorrenteService
            .getTipoNumeroDocumento()
            .subscribe((res) => (this.tipoDocumentoList = res));
        /**filtro conta */
        this.listFiltroContaIpvaItcdIcms("IPVA");

        return new Promise((resolve) => {
            resolve("");
        });
    }

    openModal(dadosContribuinte, contaCorrenteContribuinte) {
        this.modalExtratoConta.showDialog(
            dadosContribuinte,
            contaCorrenteContribuinte
        );
    }

    simularHistoricoLancamento() {
        this.modalSimularHistorico.showDialog(this.valueContribuinte);
    }

    insereMascara(cpfOuCnpj) {
        return this.maskCpfOuCnpj.inserirMascara(cpfOuCnpj);
    }

    converteMesAno(data) {
        let ano = data?.toString().substring(0, 4);
        let mes = data?.toString().substring(4, 6);
        return `${mes}/${ano}`;
    }

    /** Inicio Filtros Conta IPVA, ITCD e ICMS */
    listFiltroContaIpvaItcdIcms(abaSelecionada) {
        let siglaTributo = abaSelecionada;
        this.consultaContaCorrenteService
            .listFiltroContaIpvaItcdIcms(siglaTributo)
            .subscribe((response: any) => {
                this.contaList = response;
            });
    }

    listFiltroContaItcdOrIcms(valuePanel) {
        let objParams = {};
        let tipoConta = valuePanel === "ITCD" ? "itcd" : "icms";

        objParams = {
            // siglaTributo: valuePanel,
            cpfCnpj: this.valueContribuinte.cpfcnpj,
        };
        this.consultaContaCorrenteService
            .listFiltroContaItcdOrIcms(objParams, tipoConta, this.pagination)
            .subscribe((response) => {
                this.contaList = response.content;
            });
    }

    tipoDocumentoChange() {
        if (this.tipoNumeroDocumentoValue == null) {
            /**reset campo Nº documento */
            this.numeroDocumentoValue = null;
        }
    }

    expandeEnvento(evento) {
        /**Index para o componente tabela juros */
        this.rowIndexConta = evento;
    }

    exportarExcel() {
        this.exportarRelatorio(TipoRelatorio.XLS);
    }

    exportarPdf() {
        this.exportarRelatorio(TipoRelatorio.PDF);
    }

    exportarRelatorio(tipoRelatorio: TipoRelatorio) {
        this.alertsUtil
            .confirmClose("Você deseja gerar o relatório detalhado?")
            .then((result) => {
                if (result.isConfirmed) {
                    const isConfirmed = true;
                    this.executarExportacao(tipoRelatorio, isConfirmed);
                } else if (
                    result.dismiss === swal.DismissReason.cancel ||
                    result.dismiss === swal.DismissReason.close
                ) {
                    const isConfirmed = false;
                    this.executarExportacao(tipoRelatorio, isConfirmed);
                }
            });
    }

    executarExportacao(tipoRelatorio: TipoRelatorio, isConfirmed: boolean) {
        this.imprimirDetalhes = isConfirmed;
        this.exportandoDocumentos = true;

        this.gerarFiltros();

        const tabValue = this.abaSelecionada.toLowerCase();
        const paramsFiltro = {...this.objParamsFiltro};

        if(paramsFiltro.cpfCnpjResponsavel && paramsFiltro.cpfCnpjResponsavel?.length > 0)
            paramsFiltro.cpfCnpjResponsavel =  paramsFiltro.cpfCnpjResponsavel[0];

        let successMessage = tipoRelatorio + " emitido com sucesso!";
        let serviceMethod;
        let baixarArquivoMethod;

        if (tipoRelatorio === TipoRelatorio.XLS) {
            serviceMethod = this.consultaContaCorrenteService.getXls;
            baixarArquivoMethod = this.utilService.baixarXlsFromBase64;
        } else if (tipoRelatorio === TipoRelatorio.PDF) {
            serviceMethod = this.consultaContaCorrenteService.getPdf;
            baixarArquivoMethod = this.utilService.baixarPdfFromBase64;
        }

        serviceMethod
            .call(this.consultaContaCorrenteService, paramsFiltro, tabValue)
            .subscribe((response: RelatorioConta) => {
                this.alertsUtil.saveSuccess(successMessage);

                if (response.arquivo) {
                    setTimeout(() => {
                        baixarArquivoMethod.call(
                            this.utilService,
                            response.arquivo,
                            tabValue
                        );
                    }, 600);
                } else {
                    this.alertsUtil.warning(response.erro);
                }
            });

        this.exportandoDocumentos = false;
    }

    lancarDare(conta) {
        this.contaCorrenteIdDare = conta.contaCorrenteId;
    }

    desabilitarExports(): boolean {
        return !this.pesquisaRealizada;
    }

    emitePdfDare(dataResponse) {
        this.utilService.geraPdfStringBase64(dataResponse);
    }

    isAbaSelecionadaIpva() {
        return this.abaSelecionada === "IPVA";
    }

    isAbaSelecionadaIcms() {
        return this.abaSelecionada === "ICMS";
    }

    isAbaSelecionadaParcelamento() {
        return this.abaSelecionada === "PARCELAMENTO";
    }

    isAbaSelecionadaHonorarios() {
        return this.abaSelecionada === "HONORARIOS";
    }

    isAbaSelecionadaOutros() {
        return this.abaSelecionada === "OUTROS";
    }

    isAbaSelecionadaOutrosParcelamentoHonorario() {
        return (
            this.isAbaSelecionadaHonorarios() ||
            this.isAbaSelecionadaOutros() ||
            this.isAbaSelecionadaParcelamento()
        );
    }

    exibirFiltroRenavan(): boolean {
        return (
            this.isAbaSelecionadaIpva() ||
            (this.isAbaSelecionadaOutrosParcelamentoHonorario() &&
                this.renavamList.length > 0)
        );
    }

    exibirFiltroIe(): boolean {
        return (
            this.isAbaSelecionadaIcms() ||
            (this.isAbaSelecionadaOutrosParcelamentoHonorario() &&
                this.contribuinteRelacaoProfissionalList.length > 0)
        );
    }

    ativarInativarCampos() {
        if (!this.exibirFiltroRenavan() || !this.exibirFiltroIe()) return;

        if (this.renavamValue) {
            this.contribuinteValue = null;
            this.isContribuinteDisabled = true;
        } else if (this.contribuinteValue) {
            this.renavamValue = null;
            this.isRenavamDisabled = true;
        } else {
            this.isContribuinteDisabled = false;
            this.isRenavamDisabled = false;
        }
    }
}

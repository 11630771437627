export class EventoPorPeriodoSearchRequest {
    dataInicialReferenciaValue: any | null;
    dataFinalReferenciaValue: any | null;
    transacaoId: number[];
    tributoId: number[];
    contaId: number[];
    eventoId: number[];

    constructor() {
        this.dataInicialReferenciaValue = null;
        this.dataFinalReferenciaValue = null;
        this.tributoId = [];
        this.transacaoId = [];
        this.contaId = [];
        this.eventoId = [];
    }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ccp-detalhe-calculo-juros',
  templateUrl: './detalhe-calculo-juros.component.html',
  styleUrls: ['./detalhe-calculo-juros.component.scss']
})
export class DetalheCalculoJurosComponent implements OnInit {

  detalheContribuinte = [];
  @Input() conta;
  @Input() tituloValorLancado = 'Valor Lançado';
  @Input() tituloValorAtualizado = 'Valor Atualizado';
  @Input() tituloValorSimulado = 'Valor Simulado';
  @Input() isSimular;

  contaList: any[] = [];
  contasSimuladas: any[] = [];
  valorTotalSimulado: void;

  constructor() { }

  ngOnInit(): void {
    this.contaList.push(this.conta);
    this.contasSimuladas = this.conta.financeiros;
    this.valorTotalSimulado = this.conta.valorTotalSimulado;
  }

  maior(ev){
    if(ev > 0){
      return true;
    }
    return false;
  }

}

import { Component, OnInit } from '@angular/core';
import swal from "sweetalert2";
import { RelatorioConta } from "../../../models/relatorio-conta";
import { AcoesContaCorrenteHelper } from "../../../shared/acoes-helper/acoes-conta-corrente-helper";
import { UtilsService } from "../../../shared/utils.service";
import { AlertsUtil } from "../../../utils/alerts/alerts.util";
import { RelatorioContaCorrenteModel } from "../model/conta-model";
import { FiltroConta } from '../model/filtro-conta';
import { RodapedModel } from '../model/rodape-model';
import { RelatorioContaCorrenteService } from "../service/relatorio-conta-corrente.service";

enum TipoRelatorio {
    XLS = 'Excel',
    PDF = 'PDF'
}

@Component({
    selector: 'app-relatorio-por-periodo',
    templateUrl: './relatorio-por-periodo.component.html',
    styleUrls: ['./relatorio-por-periodo.component.scss']
})
export class RelatorioPorPeriodoComponent implements OnInit {

    private ALERTA_FILTROS_PERIODO: string = "Você deve informar o período inicial e final!";
    private ALERTA_DOIS_FILTROS_OBRIGATORIOS: string = 'Você deve informar pelo menos mais dois filtros!';
    private ALERTA_UM_FILTRO_OBRIGATORIO: string = 'Você deve informar pelo menos mais um filtro!';

    protected readonly event = event;
    maskCustom: string = "";

    contas: RelatorioContaCorrenteModel[] = [];
    contasSelecionadas: any[] = [];

    filtro: FiltroConta = new FiltroConta();
    filtroAtual: FiltroConta = new FiltroConta();

    contaList: string[] = [];
    estadoContaList: string[] = [];
    situacaoCreditoList: string[] = [];
    saldoContaList: string[] = [];
    eventoList: string[] = [];
    situacaoContaList: string[] = [];

    contribuinteList: string[] = [
        'CPF',
        'CNPJ',
        'Renavam',
        'Inscrição Estadual'
    ];

    rodapeModel: RodapedModel = new RodapedModel();
    totalValor: number = 0;
    totalMulta: number = 0;
    totalJuros: number = 0;
    totalTotal: number = 0;
    maximoCaracteres: number = 0;

    pesquisaRealizada: boolean = false;

    constructor (
        private service: RelatorioContaCorrenteService,
        private alertsUtil: AlertsUtil,
        private utilService: UtilsService
    ) {}

    ngOnInit(): void {
        this.carregarValoresFiltros();
    }

    private carregarValoresFiltros() {
        this.service.getOpcoesFiltros().subscribe(res => {
            this.contaList = res.configConta;
            this.estadoContaList = res.estadoConta;
            this.situacaoCreditoList = res.situacaoCreditoConta;
            this.saldoContaList = res.saldoConta;
            this.eventoList = res.configEvento;
            this.situacaoContaList = res.situacaoCobrancaConta;
        });
    }

    private formatDate(date: Date): string {
        return date.toISOString().slice(0, 19);
    }

    public carregarDados() {
        const params = this.getParametrosBusca();
        const page =  this.rodapeModel.paginaAtual - 1;
        const size = this.rodapeModel.itensPorPagina;

        this.service.listar(params, page, size).subscribe(res => {
            this.contas = res.content;

            this.contas.forEach(conta => {
                conta.valorPrincipal = this.getValor(conta.valorPrincipal);
                conta.valorMultaMora = this.getValor(conta.valorMultaMora);
                conta.valorJurosMora = this.getValor(conta.valorJurosMora);
                conta.valorMultaInfracao = this.getValor(conta.valorMultaInfracao);
                conta.valorTotal = this.getValor(conta.valorTotal);
            });

            this.rodapeModel.totalRecords = res.totalElements;
            this.rodapeModel.totalPages = res.totalPages;

            this.calcularTotais();
            this.atualizarPaginacao();

            this.pesquisaRealizada = this.contas.length > 0;
        });
    }

    private getParametrosBusca() {
        const params: any = {
            dataReferenciaInicial: this.formatDate(new Date(this.filtro.dataReferenciaInicial)),
            dataReferenciaFinal: this.formatDate(new Date(this.filtro.dataReferenciaFinal)),
        };

        if (this.filtro.tipoDocumentoContribuinte) {
            params.tipoDocumentoContribuinte = this.filtro.tipoDocumentoContribuinte;
        }

        if (this.filtro.numeroDocumentoContribuinte) {
            params.numeroDocumentoContribuinte = this.filtro.numeroDocumentoContribuinte;
        }

        if (this.filtro.configConta) {
            params.configContaId = this.filtro.configConta.id;
            params.configContaDescricao = this.filtro.configConta.descricao;
        }

        if (this.filtro.estadoConta) {
            params.estadoContaId = this.filtro.estadoConta.id;
            params.estadoContaDescricao = this.filtro.estadoConta.descricao;
        }

        if (this.filtro.situacaoCreditoConta) {
            params.situacaoCreditoContaId = this.filtro.situacaoCreditoConta.id;
            params.situacaoCreditoContaDescricao = this.filtro.situacaoCreditoConta.descricao;
        }

        if (this.filtro.saldoConta) {
            params.saldoContaId = this.filtro.saldoConta.id;
            params.saldoContaDescricao = this.filtro.saldoConta.descricao;
        }

        if (this.filtro.configEvento) {
            params.configEventoId = this.filtro.configEvento.id;
            params.configEventoDescricao = this.filtro.configEvento.descricao;
        }

        if (this.filtro.situacaoCobranca) {
            params.situacaoCobrancaContaId = this.filtro.situacaoCobranca.id;
            params.situacaoCobrancaContaDescricao = this.filtro.situacaoCobranca.descricao;
        }

        for (const key in params) {
            if (params[key] == null) {
                delete params[key];
            }
        }

        return params;
    }

    private getParametrosRelatorio() {
        let params = this.getParametrosBusca();

        if (this.contasSelecionadas) {
            params.listContaCorrenteDto = this.contasSelecionadas;
        }

        return params;
    }

    private getValor(valor: number) {
        if (!valor) {
            return 0;
        }

        return valor;
    }

    calcularTotais() {
        this.totalValor = this.contas.reduce((sum, item) => sum + item.valorPrincipal, 0);
        this.totalMulta = this.contas.reduce((sum, item) => sum + item.valorMultaMora, 0);
        this.totalJuros = this.contas.reduce((sum, item) => sum + item.valorJurosMora, 0);
        this.totalTotal = this.contas.reduce((sum, item) => sum + item.valorTotal, 0);
    }

    limparFiltros() {
        this.filtro = new FiltroConta();
        this.contasSelecionadas = [];
    }

    atualizarPaginacao() {
        const paginaAtual = this.rodapeModel.paginaAtual;
        const itensPorPagina = this.rodapeModel.itensPorPagina;
        const roundedUp = Math.ceil(this.rodapeModel.totalRecords / this.rodapeModel.itensPorPagina);

        this.rodapeModel.opcoesPagina = [];

        for (let i = 1; i <= roundedUp; i++) {
            let item = {label: i.toString(), value: i};

            this.rodapeModel.opcoesPagina.push(item);
        }

        this.rodapeModel.inicioItem = (paginaAtual - 1) * itensPorPagina + 1;
        this.rodapeModel.fimItem = Math.min((paginaAtual) * itensPorPagina, this.rodapeModel.totalRecords);
    }

    notHasPrevPage(): boolean {
        return !this.hasPrevPage();
    }

    hasPrevPage(): boolean {
        return this.rodapeModel.paginaAtual > 1;
    }

    prevPage() {
        if (this.hasPrevPage()) {
            this.rodapeModel.paginaAtual--;

            this.carregarDados();
        }
    }

    notHasNextPage(): boolean {
        return this.rodapeModel.paginaAtual == this.rodapeModel.totalPages;
    }

    nextPage() {
        if (this.rodapeModel.paginaAtual < this.rodapeModel.totalPages) {
            this.rodapeModel.paginaAtual++;

            this.carregarDados();
        }
    }

    alterarItensPorPagina() {
        this.carregarDados();
    }

    contribuinteChange() {
        this.filtro.numeroDocumentoContribuinte = null;
    }

    pesquisar() {
        if (this.validarFiltros()) {
            this.filtroAtual.tipoDocumentoContribuinte = this.filtro.tipoDocumentoContribuinte;
            this.filtroAtual.numeroDocumentoContribuinte = this.filtro.numeroDocumentoContribuinte;
            this.filtroAtual.configConta = this.filtro.configConta;
            this.filtroAtual.estadoConta = this.filtro.estadoConta;
            this.filtroAtual.situacaoCreditoConta = this.filtro.situacaoCreditoConta;
            this.filtroAtual.saldoConta = this.filtro.saldoConta;
            this.filtroAtual.configEvento = this.filtro.configEvento;
            this.filtroAtual.situacaoCobranca = this.filtro.situacaoCobranca;
            this.filtroAtual.dataReferenciaInicial = this.filtro.dataReferenciaInicial;
            this.filtroAtual.dataReferenciaFinal = this.filtro.dataReferenciaFinal;
            this.filtroAtual.page = this.filtro.page;
            this.filtroAtual.size = this.filtro.size;

            this.contasSelecionadas = [];

            this.carregarDados();
        }
    }

    paramContribuinte() {
        switch (this.filtro.tipoDocumentoContribuinte) {
            case "CPF":
                this.maskCustom = "999.999.999-99";
                this.maximoCaracteres = 14;
                break;
            case "CNPJ":
                this.maskCustom = "99.999.999/9999-99";
                this.maximoCaracteres = 18;
                break;
            case "Renavam":
                this.maximoCaracteres = 11;
                this.maskCustom = '';
                break;
            case "Inscrição Estadual":
                this.maximoCaracteres = 12;
                this.maskCustom = '';
                break;
            default:
                this.maximoCaracteres = 12;
                this.maskCustom = '';
                break;
        }
    }

    private validarFiltros(): boolean {
        let numeroFiltrosUtilizados: number = 0;

        if (this.filtro.numeroDocumentoContribuinte) {
            numeroFiltrosUtilizados++;
        }

        if (this.filtro.configConta && this.filtro.configConta.id) {
            numeroFiltrosUtilizados++;
        }

        if (this.filtro.estadoConta && this.filtro.estadoConta.id) {
            numeroFiltrosUtilizados++;
        }

        if (this.filtro.situacaoCreditoConta && this.filtro.situacaoCreditoConta.id) {
            numeroFiltrosUtilizados++;
        }

        if (this.filtro.saldoConta && this.filtro.saldoConta.id) {
            numeroFiltrosUtilizados++;
        }

        if (this.filtro.configEvento && this.filtro.configEvento.id) {
            numeroFiltrosUtilizados++;
        }

        if (this.filtro.situacaoCobranca && this.filtro.situacaoCobranca.id) {
            numeroFiltrosUtilizados++;
        }

        if (!this.filtro.dataReferenciaInicial || !this.filtro.dataReferenciaFinal) {
            swal.fire(
                'Atenção',
                this.ALERTA_FILTROS_PERIODO,
                'warning'
            );

            return false;
        }

        if (numeroFiltrosUtilizados < 1) {
            swal.fire(
                'Atenção',
                this.ALERTA_DOIS_FILTROS_OBRIGATORIOS,
                'warning'
            );

            return false;
        }

        if (numeroFiltrosUtilizados < 2) {
            swal.fire(
                'Atenção',
                this.ALERTA_UM_FILTRO_OBRIGATORIO,
                'warning'
            );

            return false;
        }

        return true;
    }

    exportarExcel() {
        const params = this.getParametrosRelatorio();

        let successMessage = TipoRelatorio.XLS + ' emitido com sucesso!';

        this.service.getXls(params).subscribe(
            (response: RelatorioConta) => {
                this.alertsUtil.saveSuccess(successMessage);

                if (response.arquivo) {
                    setTimeout(() => {
                        this.utilService.baixarXlsFromBase64(response.arquivo, TipoRelatorio.XLS.toLowerCase());
                    }, 600);
                } else {
                    this.alertsUtil.warning(response.erro);
                }
            },
            (error) => {
                this.alertsUtil.handleError(error);
            }
        );
    }

    exportarPdf() {
        const params = this.getParametrosRelatorio();

        let successMessage = TipoRelatorio.PDF + ' emitido com sucesso!';

        this.service.getPdf(params).subscribe(
            (response: RelatorioConta) => {
                this.alertsUtil.saveSuccess(successMessage);

                if (response.arquivo) {
                    setTimeout(() => {
                        this.utilService.baixarPdfFromBase64(response.arquivo, TipoRelatorio.PDF.toLowerCase());
                    }, 600);
                } else {
                    this.alertsUtil.warning(response.erro);
                }
            },
            (error) => {
                this.alertsUtil.handleError(error);
            }
        );
    }

    pesquisaNaoRealizada() {
        return !this.pesquisaRealizada;
    }

    isRenavamOuInscricao() {
        return this.filtro.tipoDocumentoContribuinte === 'Renavam' || this.filtro.tipoDocumentoContribuinte === 'Inscrição Estadual';
    }

    exibirColunaConta(): boolean {
        return this.filtroAtual?.configConta?.id === undefined;
    }

    exibirColunaEstado(): boolean {
        return this.filtroAtual?.estadoConta?.id === undefined;
    }

    exibirColunaSituacaoCredito(): boolean {
        return this.filtroAtual?.situacaoCreditoConta?.id === undefined;
    }

    exibirColunaEvento(): boolean {
        return this.filtroAtual?.configEvento?.id === undefined;
    }

    getNumColunas(): number {
        let numeroColunas: number = 8;

        if (!this.exibirColunaConta()) {
            numeroColunas--;
        }

        if (!this.exibirColunaEstado()) {
            numeroColunas--;
        }

        if (!this.exibirColunaSituacaoCredito()) {
            numeroColunas--;
        }

        if (!this.exibirColunaEvento()) {
            numeroColunas--;
        }

        return numeroColunas;
    }

    podeExportarRelatorioContaCorrente(): boolean {
        return AcoesContaCorrenteHelper.podeExportarRelatorioContaCorrente;
    }

    onDataReferenciaFinalChange() {
        const ano = this.filtro.dataReferenciaFinal.getFullYear();
        const mes = this.filtro.dataReferenciaFinal.getMonth();
        this.filtro.dataReferenciaFinal = new Date(ano, mes + 1, 0);;
    }
}

<p-card>
    <div class="p-grid" [style]="{ 'margin-top': '20px' }">
        <!--Detalhamento do contribuinte-->
        <div class="p-md-12" *ngIf="showContribuinteInfo">
            <p-fieldset legend="Detalhamento do Contribuinte">
                <div class="p-fluid p-formgrid p-grid">
                    <div
                        class="p-field p-md-3 p-sm-6"
                        *ngIf="
                            !detalhesContribuinte?.inscricaoEstadual?.startsWith(
                                '9'
                            )
                        "
                    >
                        <strong>Inscrição Estadual</strong>
                        <p>
                            {{ detalhesContribuinte?.inscricaoEstadual }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>CPF/CNPJ</strong>
                        <p>
                            {{ insereMascara(detalhesContribuinte?.cpfCnpj) }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>{{
                            detalhesContribuinte?.tipoPessoa === "JURIDICA"
                                ? "Razão Social"
                                : "Nome"
                        }}</strong>
                        <p>
                            {{ detalhesContribuinte?.nome }}
                        </p>
                    </div>
                </div>
            </p-fieldset>
        </div>
        <!--Fim do Detalhamento do contribuinte-->
        <!--Detalhamento do veículo-->
        <div class="p-md-12" *ngIf="showVeiculoInfo">
            <p-fieldset legend="Detalhamento do Contribuinte">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Proprietário</strong>
                        <p>
                            {{
                                insereMascara(
                                    detalheIpvaContribuinte?.cpfCnpjRenavam2
                                )
                            }}
                            -
                            {{ detalheIpvaContribuinte?.nome }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Nome do Veículo</strong>
                        <p>
                            {{
                                detalheIpvaContribuinte?.marcaModelo
                                    ? detalheIpvaContribuinte?.marcaModelo
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Renavam</strong>
                        <p>
                            {{ detalheIpvaContribuinte?.renavam }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Chassi</strong>
                        <p>
                            {{ detalheIpvaContribuinte?.chassi }}
                        </p>
                    </div>
                </div>
            </p-fieldset>
        </div>
        <div class="p-md-12" *ngIf="showVeiculoInfo">
            <p-fieldset legend="Informações Complementares">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Placa</strong>
                        <p>
                            {{
                                detalheIpvaContribuinte?.placa
                                    ? detalheIpvaContribuinte?.placa
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Cor</strong>
                        <p>
                            {{
                                detalheIpvaContribuinte?.cor
                                    ? detalheIpvaContribuinte?.cor
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Ano Modelo</strong>
                        <p>
                            {{
                                detalheIpvaContribuinte?.anoModelo
                                    ? detalheIpvaContribuinte?.anoModelo
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Ano Fabricação</strong>
                        <p>
                            {{
                                detalheIpvaContribuinte?.anoFabricacao
                                    ? detalheIpvaContribuinte?.anoFabricacao
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Combustível</strong>
                        <p>
                            {{
                                detalheIpvaContribuinte?.codCombustivel
                                    ? detalheIpvaContribuinte?.codCombustivel
                                    : "-"
                            }}
                            -
                            {{
                                detalheIpvaContribuinte?.combustivel
                                    ? detalheIpvaContribuinte?.combustivel
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Categoria</strong>
                        <p>
                            {{
                                detalheIpvaContribuinte?.categoriaVeiculo
                                    ? detalheIpvaContribuinte?.categoriaVeiculo
                                    : "-"
                            }}
                            -
                            {{
                                detalheIpvaContribuinte?.categoriaVeiculoDesc
                                    ? detalheIpvaContribuinte?.categoriaVeiculoDesc
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Cilindrada</strong>
                        <p>
                            {{
                                detalheIpvaContribuinte?.cilindrada
                                    ? detalheIpvaContribuinte?.cilindrada
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Potência</strong>
                        <p>
                            {{
                                detalheIpvaContribuinte?.potencia
                                    ? detalheIpvaContribuinte?.potencia
                                    : "-"
                            }}
                        </p>
                    </div>
                </div>
            </p-fieldset>
        </div>
        <div
            class="p-md-12"
            *ngIf="showDesvinculado && detalhesIpvaDesvinculado"
        >
            <p-fieldset legend="IPVA Desvinculado">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Número do Processo</strong>
                        <p>
                            {{
                                detalhesIpvaDesvinculado?.numeroProcesso || "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-4 p-sm-6">
                        <strong>Motivo da Desvinculação</strong>
                        <p>
                            {{ detalhesIpvaDesvinculado?.motivo || "-" }}
                        </p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Data da Desvinculação</strong>
                        <p>
                            {{
                                (detalhesIpvaDesvinculado?.dataDesvinculacao
                                    | date : "dd/MM/yyyy") || "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Número do Termo</strong>
                        <p>
                            {{ detalhesIpvaDesvinculado?.numeroTermo || "-" }}
                        </p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Nome do Veículo</strong>
                        <p>
                            {{ detalhesIpvaDesvinculado?.marcaModelo || "-" }}
                        </p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Renavam</strong>
                        <p>
                            {{ detalhesIpvaDesvinculado?.renavam || "-" }}
                        </p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Chassi</strong>
                        <p>
                            {{ detalhesIpvaDesvinculado?.chassi || "-" }}
                        </p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Placa</strong>
                        <p>
                            {{ detalhesIpvaDesvinculado?.placa || "-" }}
                        </p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Ano Modelo</strong>
                        <p>
                            {{ detalhesIpvaDesvinculado?.anoModelo || "-" }}
                        </p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Ano Fabricação</strong>
                        <p>
                            {{ detalhesIpvaDesvinculado?.anoFabricacao || "-" }}
                        </p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Combustível</strong>
                        <p>
                            {{ detalhesIpvaDesvinculado?.combustivel || "-" }}
                        </p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Cilindrada</strong>
                        <p>
                            {{ detalhesIpvaDesvinculado?.cilindrada || "-" }}
                        </p>
                    </div>
                </div></p-fieldset
            >
        </div>
        <!-- Fim do detalhamento de veículo -->
        <!--Conta Corrente-->
        <div class="p-md-12">
            <p-fieldset legend="Conta Corrente">
                <!-- CONTA -->
                <div class="p-fluid p-formgrid p-grid conta-corrente">
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Nº Conta Corrente</strong>
                        <p>{{ detalheConta?.contaCorrenteId }}</p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Conta Corrente</strong>
                        <p>
                            {{
                                detalheConta?.configContaId +
                                    " - " +
                                    detalheConta?.configContaNomeCompleto
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Período de Referência</strong>
                        <p>
                            {{
                                detalheConta?.periodoReferencia
                                    ? converteMesAno(
                                          detalheConta?.periodoReferencia
                                      )
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Data de Vencimento</strong>
                        <p>
                            {{
                                detalheConta?.dataVencimento
                                    | date : "dd/MM/yyyy"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Data de Criação</strong>
                        <p>
                            {{
                                detalheConta?.dataCriacao | date : "dd/MM/yyyy"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Tipo de Documento</strong>
                        <p>
                            {{ detalheConta?.tipoNumeroDocumentoDesc || "-" }}
                        </p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Número do Documento</strong>
                        <div>
                            {{ detalheConta?.numeroDocumento || "-" }}
                            <div
                                *ngIf="
                                    detalheConta?.tipoNumeroDocumentoId ===
                                    tipoDocumento.NUM_AUTO_INFRACAO
                                "
                                (click)="abrirModalAutoInfracao()"
                                pTooltip="Detalhar Auto de Infração"
                                tooltipPosition="top"
                                class="fa fa-search"
                                style="
                                    font-size: small;
                                    margin-right: 8px;
                                    cursor: pointer;
                                "
                            ></div>
                            <div
                                *ngIf="
                                    detalheConta?.tipoNumeroDocumentoId ===
                                    tipoDocumento.NUM_CONTRATO_PARC
                                "
                                (click)="abrirModalParcelamentoIntegracao()"
                                pTooltip="Detalhar Parcelamento"
                                tooltipPosition="top"
                                class="fa fa-search"
                                style="
                                    font-size: small;
                                    margin-right: 8px;
                                    cursor: pointer;
                                "
                            ></div>
                        </div>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Data de Atualização</strong>
                        <p>
                            {{
                                detalheConta?.dataAtualizacao
                                    | date : "dd/MM/yyyy"
                            }}
                        </p>
                    </div>
                    <div
                        class="p-field p-md-2 p-sm-6"
                        *ngIf="detalheConta?.cotaUnica === 'SIM'"
                    >
                        <strong>Cota Única</strong>
                        <p>{{ detalheConta?.cotaUnica || "-" }}</p>
                    </div>
                    <div
                        class="p-field p-md-2 p-sm-6"
                        *ngIf="detalheConta?.parcela"
                    >
                        <strong>Número da Cota/Parcela</strong>
                        <p>{{ detalheConta?.parcela || "-" }}</p>
                    </div>
                    <div
                        class="p-field p-md-2 p-sm-6"
                        *ngIf="detalheConta?.contaCorrenteCotaUnicaId"
                    >
                        <strong>Nº da Conta (Cota Única)</strong>
                        <p>{{ detalheConta?.contaCorrenteCotaUnicaId }}</p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Saldo da Conta</strong>
                        <p [style]="{ 'background-color': '#ffcbdb' }">
                            {{ detalheConta?.saldoContaDesc || "-" }}
                        </p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Situação do Crédito</strong>
                        <p>{{ detalheConta?.situacaoCreditoDesc || "-" }}</p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Situação da Cobrança</strong>
                        <p>{{ detalheConta?.situacaoCobrancaDesc || "-" }}</p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Estado da Conta</strong>
                        <p>{{ detalheConta?.estadoContaDesc }}</p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>DARE Bloqueado</strong>
                        <p>
                            {{ detalheContaAnexo?.bloqueiaEmissaoDare || "-" }}
                        </p>
                    </div>
                    <div
                        class="p-field p-md-2 p-sm-6"
                        *ngIf="detalheContaAnexo?.bloqueiaEmissaoDare != 'Sim'"
                    >
                        <strong>Código de Receita</strong>
                        <p>
                            {{ detalheContaAnexo?.codReceita || "-" }} -
                            {{ detalheContaAnexo?.nomeReceita }}
                        </p>
                    </div>
                    <div class="p-field p-md-2 p-sm-6">
                        <strong>Anexo</strong>
                        <p
                            (click)="downloadFile()"
                            [ngClass]="{ 'cursor-pointer': downloadAnexo }"
                        >
                            {{ downloadAnexo ? textoDocDownload : " - " }}
                            <i
                                *ngIf="downloadAnexo"
                                class="fa-solid fa-download"
                            ></i>
                        </p>
                    </div>
                </div>
            </p-fieldset>
        </div>
        <!--Fim Conta Corrente-->
        <!--Posição de Saldos-->
        <div class="p-md-12 p-sm-6">
            <p-fieldset legend="Posição de Saldos">
                <p-table
                    [value]="detalheContaPosicaoSaldo"
                    styleClass="p-datatable-gridlines"
                >
                    <ng-template pTemplate="header">
                        <tr>
                            <th>VALOR INICIAL</th>
                            <th>
                                {{
                                    detalheConta?.dataBaseLancamento
                                        | date : "dd/MM/yyyy"
                                }}
                            </th>
                            <th>SALDO ATUAL</th>
                            <th>
                                {{
                                    detalheConta?.dataAtualizacao
                                        | date : "dd/MM/yyyy"
                                }}
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-c>
                        <!-- Linha 1: Saldo da Conta -->
                        <tr>
                            <td>Saldo da Conta</td>
                            <td
                                [ngClass]="{
                                    'saldo-negativo': maior(
                                        c?.valorTotalLancado
                                    ),
                                    'text-right': true
                                }"
                            >
                                {{
                                    c?.valorTotalLancado | currency : "BRL" : ""
                                }}
                            </td>
                            <td>Saldo da Conta</td>
                            <td
                                [ngClass]="{
                                    'saldo-negativo': maior(
                                        c?.saldoTotalAtualizado
                                    ),
                                    'text-right': true
                                }"
                            >
                                {{
                                    c?.saldoTotalAtualizado
                                        | currency : "BRL" : ""
                                }}
                            </td>
                        </tr>
                        <!-- Linhas adicionais do detalheContaAnexo -->
                        <tr
                            *ngFor="
                                let c of detalheContaAnexo?.contaCorrenteFinanceiros
                            "
                        >
                            <td>{{ c?.naturezaItemCalculo?.descricao }}</td>
                            <td
                                [ngClass]="{
                                    'saldo-negativo': maior(c?.valorLancado),
                                    'text-right': true
                                }"
                            >
                                {{ c?.valorLancado | currency : "BRL" : "" }}
                            </td>
                            <td>{{ c?.naturezaItemCalculo?.descricao }}</td>
                            <td
                                [ngClass]="{
                                    'saldo-negativo': maior(c?.saldoAtualizado),
                                    'text-right': true
                                }"
                            >
                                {{ c?.saldoAtualizado | currency : "BRL" : "" }}
                            </td>

                            <!-- <td>{{ detalheConta?.dataAtualizacao | date: 'dd/MM/yyyy' }}</td> -->
                        </tr>
                    </ng-template>
                </p-table>
            </p-fieldset>
        </div>
        <!--Fim Posição de Saldos-->
    </div>
</p-card>
<!--CONTAS ASSOCIADAS-->
<p-card *ngIf="contasAssociadas?.length > 0" [style]="{ 'margin-top': '20px' }">
    <div class="p-grid">
        <div class="p-md-12">
            <p-fieldset
                legend="Contas Associadas"
                [style]="{ 'margin-top': '20px' }"
            >
                <p-table #dt1 [value]="contasAssociadas">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Nº Conta Corrente</th>
                            <th>Conta Corrente</th>
                            <th>Número do Documento</th>
                            <th>Código Cadastro</th>
                            <th>Categoria da Conta</th>
                            <th>Tipo de Associação</th>
                            <th>Ações</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-conta>
                        <tr>
                            <td>{{ conta.contaCorrenteId }}</td>
                            <td>
                                {{
                                    conta?.configContaId +
                                        " - " +
                                        conta?.configContaNomeCompleto
                                }}
                            </td>
                            <td>{{ conta.numeroDocumento }}</td>
                            <td>{{ conta.codigoCadastro }}</td>
                            <td>{{ conta.tipoContaDescricao }}</td>
                            <td>{{ conta.tipoAssociacaoContaDescricao }}</td>
                            <td>
                                <i
                                    (click)="detalharContaAssociada(conta)"
                                    pTooltip="Detalhe Conta"
                                    tooltipPosition="top"
                                    class="fa fa-list"
                                    aria-hidden="true"
                                    style="font-size: large; margin-right: 8px; cursor: pointer;"
                                ></i>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-fieldset>
        </div>
    </div>
</p-card>
<!-- DETALHAMENTO -->
<p-card [style]="{ 'margin-top': '20px' }">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
            <p-fieldset legend="Detalhamento">
                <!-- DETALHE ITCD -->
                <div *ngIf="isITCD" class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Responsável</strong>
                        <p>
                            {{
                                detalheContaItcd?.cpfCnpj
                                    ? detalheContaItcd?.cpfCnpj
                                    : "-"
                            }}
                            -
                            {{
                                detalheContaItcd?.nomeResponsavel
                                    ? detalheContaItcd?.nomeResponsavel
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Número da Declaração</strong>
                        <p>
                            {{
                                detalheContaItcd?.tdiNumDecl
                                    ? detalheContaItcd?.tdiNumDecl
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Tipo de Declaração</strong>
                        <p>
                            {{
                                detalheContaItcd?.codTipoDesc
                                    ? detalheContaItcd?.codTipoDesc
                                    : "-"
                            }}
                        </p>
                    </div>
                    <div class="p-field p-md-3 p-sm-6">
                        <strong>Tipo de Transmissão</strong>
                        <p>
                            {{
                                detalheContaItcd?.tipoTransmissaoDesc
                                    ? detalheContaItcd?.tipoTransmissaoDesc
                                    : "-"
                            }}
                        </p>
                    </div>
                </div>
                <!-- DETALHE COMPONENT EVENTO E TRANSAÇÕES & DETALHE ICMS-->
                <ccp-detalhe-eventos-transacoes (detalharConta)="detalharContaAssociada($event)"></ccp-detalhe-eventos-transacoes>
            </p-fieldset>
        </div>
        <div class="p-col-12">
            <div class="botoes-footer">
                <button
                    pButton
                    label="Voltar"
                    icon="pi pi-angle-left"
                    class="p-button-outlined p-mr-2"
                    (click)="irSelecionarConta()"
                ></button>
                <button
                    pButton
                    type="button"
                    icon="pi pi-file-excel"
                    label="Excel"
                    class="p-button-success p-mr-2"
                    (click)="exportarExcel()"
                ></button>
            </div>
        </div>
    </div>
</p-card>

<app-modal-detalhe-auto-infracao></app-modal-detalhe-auto-infracao>
<app-modal-parcelamento-integracao></app-modal-parcelamento-integracao>

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, FormArray, FormControl, Validators} from '@angular/forms';
import {TransacaoService} from '../../services/transacao.service';

@Component({
    selector: 'app-reducao-multa-punitiva',
    templateUrl: './reducao-multa-punitiva.component.html',
    styleUrls: ['./reducao-multa-punitiva.component.scss']
})
export class ReducaoMultaPunitivaComponent implements OnInit {

    formGroupReducaoPunitiva: FormGroup;
    tipoValorReducaoEnum: any[];
    rotuloReducao: string;
    rotuloDecrescimo: string;
    indicaQualAbaExibir: number;
    tipoDataRefereMomenInicidLista: any[];
    tiposDatasReferenciaAcontarLista: any[];
    perdaBeneficioEnum: any[];
    tiposContagemLista: any[];
    regraReducaoProgressivaVariavel: any[];
    erroLimiteReducao: boolean;
    erroLimiteDecrescimo: boolean;
    erroQtdDiaTabela: boolean = false;
    erroValorReducaoTabela: boolean;
    erroTabelaObrigatoria: boolean;
    erroCamposObrigatoriosTabela: boolean;
    msgErroReducao: string;
    msgErroDecrescimo: string;
    formPreenchidoCorretamente: boolean;
    mode: any;
    currency: string;
    locale: string;

    @Output() changeForm = new EventEmitter();
    @Output() validaPreenchimentoForm = new EventEmitter();

    @Input() set transacaoRegraReducaoRequest(obj) {
        if (obj != null) {
            this.perdaBeneficioEnum = [
                {chave: 'NAO', id: '1', valor: 'Não'},
                {chave: 'SIM', id: '2', valor: 'Sim - Total'},
                {chave: 'DECRESCIMO_FIXO', id: '3', valor: 'Sim - Progressivo com decréscimo fixo'},
                {chave: 'DECRESCIMO_VARIAVEL', id: '4', valor: 'Sim - Progressivo com decréscimo variável'},
            ];
            this.iniciaForm(obj);
            this.onChangePerdaBeneficio(this.formGroupReducaoPunitiva.value.haPerdaBeneficio);
        }
    }

    @Input() submitted;

    constructor(
        public transacaoService: TransacaoService,
        private formBuilder: FormBuilder,
    ) {
        this.iniciaForm();
    }

    onCheckboxChange(event, value) {
        const checkArray: FormArray = this.idNaturezaItemcalculo;
        if (event.checked.length > 0) {
            checkArray.push(new FormControl(event.checked[0]));
        } else {
            checkArray.controls.forEach((item: any, i) => {
                if (item.value === value) {
                    checkArray.removeAt(i);
                    return;
                }
            });
        }

        this.changeForm.emit(this.formGroupReducaoPunitiva.value);
    }

    get idNaturezaItemcalculo() {
        return this.formGroupReducaoPunitiva.controls.idNaturezaItemcalculo as FormArray;
    }

    ngOnInit(): void {
        this.getNaturezaItemCalculo();
        this.getTiposDatasReferencia();
        this.getTiposContagens();
        this.erroLimiteReducao = false;
        this.erroLimiteDecrescimo = false;
        this.formPreenchidoCorretamente = false;
        this.erroCamposObrigatoriosTabela = false;
        this.msgErroReducao = '';
        this.msgErroDecrescimo = '';

        if (this.formGroupReducaoPunitiva.value.transacaoRegraReducaoId !== null &&
            this.formGroupReducaoPunitiva.value.transacaoRegraReducaoId !== undefined) {
            this.transacaoService.getRegraReducaoProgressivaByTransacaoId(this.formGroupReducaoPunitiva.value.transacaoRegraReducaoId).subscribe(list => {
                this.regraReducaoProgressivaVariavel = list;
                this.popularTabelaReducao();
            });
        }

        this.tipoValorReducaoEnum = [
            {chave: 'FIXO', id: '1', valor: 'Fixo'},
            {chave: 'PERCENTUAL', id: '1', valor: 'Percentual'}
        ];

        this.perdaBeneficioEnum = [
            {chave: 'NAO', id: '1', valor: 'Não'},
            {chave: 'SIM', id: '2', valor: 'Sim - Total'},
            {chave: 'DECRESCIMO_FIXO', id: '3', valor: 'Sim - Progressivo com decréscimo fixo'},
            {chave: 'DECRESCIMO_VARIAVEL', id: '4', valor: 'Sim - Progressivo com decréscimo variável'},
        ];
    }

    get idNaturezaItemcalculoFormControl() {
        return this.formGroupReducaoPunitiva.get('idNaturezaItemcalculo');
    }

    get reducaoPunitivaFormControl() {
        return this.formGroupReducaoPunitiva.controls;
    }

    iniciaForm(obj?) {
        if (obj === undefined) {
            this.indicaQualAbaExibir = 1;
        }
        this.formGroupReducaoPunitiva = this.formBuilder.group({
            transacaoRegraReducaoId: [obj ? obj.transacaoRegraReducaoId : null],
            tipoDataRefereMomenInicid: [obj ? obj.tipoDataRefereMomenIncid : null, Validators.required], // lista
            tipoDataReferenciaAcontar: [obj ? obj.tipoDataReferenciaAcontar : {
                descricao: 'Data do Fato Gerador',
                tipoDataReferenciaId: 1
            }, Validators.required],
            tipoContagem: [obj ? obj.tipoContagem : {
                descricao: 'Dias Corridos',
                tipoContagemId: 1
            }, Validators.required],
            haPerdaBeneficio: [obj ? this.perdaBeneficioEnum.find(t => t.chave === obj.haPerdaBeneficio) : {
                chave: 'NAO',
                id: '1',
                valor: 'Não'
            }, Validators.required],
            tipoValorReducao: [obj ? obj.tipoValorReducao : 'FIXO', Validators.required],
            valorReducao: [obj ? obj.valorReducao : undefined, Validators.required],
            qtdDias: [obj ? obj.qtdDias : undefined, Validators.required],
            valorDecrescimo: [obj ? obj.valorDecrescimo : undefined, Validators.required],
            qtdDiasSubSequente: [obj ? obj.qtdDiasSubSequente : undefined, Validators.required],
            regraReducaoProgressivaVariavelLista: this.formBuilder.array([]),
            regraReducaoProgressivaFixoLista: [obj ? obj.regraReducaoProgressivaFixoLista : undefined],
        });

        if (this.formGroupReducaoPunitiva.value.tipoValorReducao === 'FIXO') {
            this.rotuloReducao = 'Valor Redução (R$)';
            this.rotuloDecrescimo = 'Valor Decréscimo (R$)';
            this.changeValorReducao(1);
        } else {
            this.rotuloReducao = '%Redução';
            this.rotuloDecrescimo = '%Decréscimo';
            this.changeValorReducao(2);
        }
    }

    regraReducaoProgressivaLista(): FormArray {
        return this.formGroupReducaoPunitiva.get('regraReducaoProgressivaVariavelLista') as FormArray;
    }

    novaReducao(): FormGroup {
        return this.formBuilder.group({
            valorReducao: '',
            qtdDiasInicial: '',
            qtdDiasFinal: '',
            transacaoRegraReducaoId: '',
            idRegraReducaoProgressiva: '',
        });
    }

    adicionarReducaoBotao() {
        this.regraReducaoProgressivaLista().push(this.novaReducao());
        this.validaOrdemDaQuantidadeDiasNosCamposTabela();
        this.validaLimiteDeValoresNosCamposReducaoTabela();
    }

    adicionarReducao() {
        this.regraReducaoProgressivaLista().push(this.novaReducao());
    }

    removeReducao(i: number) {
        this.regraReducaoProgressivaLista().removeAt(i);
        this.validaOrdemDaQuantidadeDiasNosCamposTabela();
        this.validaLimiteDeValoresNosCamposReducaoTabela();

        this.changeForm.emit(this.formGroupReducaoPunitiva.value);
        this.validaPreenchimentoForm.emit(this.validaPreenchimentoCompletoForm());
    }

    popularTabelaReducao() {
        this.regraReducaoProgressivaVariavel?.forEach((data, index) => {
            this.adicionarReducao();
            this.regraReducaoProgressivaLista().controls[index].setValue(data);
        });

        if (this.formGroupReducaoPunitiva.value.haPerdaBeneficio.id === '4') {
            this.validaOrdemDaQuantidadeDiasNosCamposTabela();
            this.validaLimiteDeValoresNosCamposReducaoTabela();
        }
    }

    getNaturezaItemCalculo() {
        this.transacaoService.getNaturezaItemCalculo().subscribe((response) => {
            this.tipoDataRefereMomenInicidLista = response;
        });
    }

    getTiposDatasReferencia() {
        this.transacaoService.getTiposDatasReferencia().subscribe((response) => {
            this.tiposDatasReferenciaAcontarLista = response;
        });
    }

    getTiposContagens() {
        this.transacaoService.getTiposContagens().subscribe((response) => {
            this.tiposContagemLista = response;
        });
    }

    validaLimiteDeValoresBaseadoPercentualeFixo() {
        if (this.formGroupReducaoPunitiva.value.tipoValorReducao === 'PERCENTUAL') {
            this.validaCampoReducaoPercentual();
            this.validaCampoDecrescimo();
        } else {
            // this.erroLimiteReducao = false;
            this.validaCampoReducaoFixo();
            this.validaCampoDecrescimo();
        }
    }

    validaCampoReducaoPercentual() {
        if (this.formGroupReducaoPunitiva.value.valorReducao >= 0 &&
            this.formGroupReducaoPunitiva.value.valorReducao <= 100) {
            this.erroLimiteReducao = false;

            const casasDecimais = this.formGroupReducaoPunitiva.value.valorReducao?.toString()?.split('.')[1]?.length;
            if (casasDecimais > 5) {
                this.erroLimiteReducao = true;
                this.msgErroReducao = 'São permitidas apenas 5 casas decimais';
            }
        } else {
            this.erroLimiteReducao = true;
            this.msgErroReducao = 'O valor máximo permitido para a redução é 100';
        }
    }

    validaCampoReducaoFixo() {
        if (this.formGroupReducaoPunitiva.value.valorReducao >= 0 &&
            this.formGroupReducaoPunitiva.value.valorReducao <= 999999999.99) {
            this.erroLimiteReducao = false;

            const casasDecimais = this.formGroupReducaoPunitiva.value.valorReducao?.toString()?.split('.')[1]?.length;
            if (casasDecimais > 2) {
                this.erroLimiteReducao = true;
                this.msgErroReducao = 'São permitidas apenas 2 casas decimais';
            }

        } else {
            this.erroLimiteReducao = true;
            this.msgErroReducao = 'O valor máximo permitido para a redução é 999999999,99';
        }
    }

    validaCampoDecrescimo() {
        return;
        if (this.indicaQualAbaExibir == 3) {
            console.log({...this.formGroupReducaoPunitiva.value})
            const casasDecimais = this.formGroupReducaoPunitiva.value.valorDecrescimo?.toString()?.split('.')[1]?.length;

            if (this.formGroupReducaoPunitiva.value.valorDecrescimo >= this.formGroupReducaoPunitiva.value.valorReducao) {
                this.erroLimiteDecrescimo = true;
                this.msgErroDecrescimo = 'O decréscimo deve ser inferior a redução';
            } else if (this.formGroupReducaoPunitiva.value.tipoValorReducao === 'PERCENTUAL') {
                if (casasDecimais > 5) {
                    this.erroLimiteDecrescimo = true;
                    this.msgErroDecrescimo = 'O decréscimo deve ser inferior a redução, com no máximo cinco casas decimais';
                } else {
                    this.erroLimiteDecrescimo = false;
                }
            } else {
                if (casasDecimais > 2) {
                    this.erroLimiteDecrescimo = true;
                    this.msgErroDecrescimo = 'O decréscimo deve ser inferior a redução, com no máximo duas casas decimais';

                } else {
                    this.erroLimiteDecrescimo = false;
                }
            }
        } else {
            this.erroLimiteDecrescimo = false;
        }
    }

      validaLimiteDeValoresNosCamposReducaoTabela() {
         const lista = this.formGroupReducaoPunitiva.value.regraReducaoProgressivaVariavelLista;
        let contErrosLimiteReducao = 0;


        for (let i = 0; i < lista?.length; i++) {
            if (this.formGroupReducaoPunitiva.value.tipoValorReducao === 'PERCENTUAL') {
                const campo = lista[i].valorReducao;
                const casasDecimaisR = campo?.toString()?.split('.')[1]?.length;

                if (casasDecimaisR > 5) {
                    this.erroLimiteReducao = true;
                    contErrosLimiteReducao++;
                    this.msgErroReducao = 'São permitidas apenas 5 casas decimais';
                }
                if (lista[i].valorReducao < 0 ||
                    lista[i].valorReducao > 100) {
                    this.erroLimiteReducao = true;
                    contErrosLimiteReducao++;
                    this.msgErroReducao = 'O valor máximo permitido para a redução é 100';
                }
            } else if (this.formGroupReducaoPunitiva.value.tipoValorReducao === 'FIXO') {
                const campo = lista[i].valorReducao;
                const casasDecimais = campo?.toString()?.split('.')[1]?.length;

                if (casasDecimais > 2) {
                    this.erroLimiteReducao = true;
                    contErrosLimiteReducao++;
                    this.msgErroReducao = 'São permitidas apenas 2 casas decimais';
                }

                if (lista[i].valorReducao < 0 ||
                    lista[i].valorReducao > 999999999.99) {
                    this.erroLimiteReducao = true;
                    contErrosLimiteReducao++;
                    this.msgErroReducao = 'O valor máximo permitido para a redução é 999999999,99';
                }
            }
        }
        if (contErrosLimiteReducao == 0) {
            this.erroLimiteReducao = false;
        }
    }

    validaOrdemDaQuantidadeDiasNosCamposTabela() {

        const lista = this.formGroupReducaoPunitiva.value.regraReducaoProgressivaVariavelLista;

        let contErrosDias = 0;
        let contErrosReducao = 0;
        let contErrosCamposObrigatorios = 0;

        for (let i = 0; i < lista?.length; i++) {
            if (i > 0) {
                if (lista[i].valorReducao > lista[i - 1].valorReducao) {
                    this.erroValorReducaoTabela = true;
                    contErrosReducao++;
                }

                if (lista[i].qtdDiasFinal < lista[i - 1].qtdDiasFinal) {
                    this.erroQtdDiaTabela = true;
                    contErrosDias++;
                }

                if (contErrosDias > 0) {
                    this.erroQtdDiaTabela = true;
                }
                if (contErrosReducao > 0) {
                    this.erroValorReducaoTabela = true;
                }
            }

            if (!lista[i].valorReducao || !lista[i].qtdDiasFinal) {
                this.erroCamposObrigatoriosTabela = true;
                contErrosCamposObrigatorios++;
            }
        }

        if (contErrosDias == 0) {
            this.erroQtdDiaTabela = false;
        }
        if (contErrosReducao == 0) {
            this.erroValorReducaoTabela = false;
        }
        if (contErrosCamposObrigatorios == 0) {
            this.erroCamposObrigatoriosTabela = false;
        }

    }

    onChange() {
        this.validaLimiteDeValoresBaseadoPercentualeFixo();

        if (this.formGroupReducaoPunitiva.value.haPerdaBeneficio.id === '4') {
            this.validaOrdemDaQuantidadeDiasNosCamposTabela();
            this.validaLimiteDeValoresNosCamposReducaoTabela();
        }

        this.changeForm.emit(this.formGroupReducaoPunitiva.value);
        this.validaPreenchimentoForm.emit(this.validaPreenchimentoCompletoForm());

    }

    onChangeMomentoIncidencia(event) {

        this.changeForm.emit(this.formGroupReducaoPunitiva.value);
        this.validaPreenchimentoForm.emit(this.validaPreenchimentoCompletoForm());
    }

    onChangeValorReducao() {
        this.changeForm.emit(this.formGroupReducaoPunitiva.value);
        this.validaPreenchimentoForm.emit(this.validaPreenchimentoCompletoForm());
    }

    onChangeContagemEm(event) {

        this.changeForm.emit(this.formGroupReducaoPunitiva.value);
        this.validaPreenchimentoForm.emit(this.validaPreenchimentoCompletoForm());
    }

    onChangeAcontarDa(event) {

        this.changeForm.emit(this.formGroupReducaoPunitiva.value);
        this.validaPreenchimentoForm.emit(this.validaPreenchimentoCompletoForm());
    }

    onClickTipoValorReducao(event) {
        this.formGroupReducaoPunitiva.controls.valorReducao.setValue(null);
        if (this.formGroupReducaoPunitiva.value.tipoValorReducao === 'FIXO') {
            this.rotuloReducao = 'Valor Redução (R$)';
            this.rotuloDecrescimo = 'Valor Decréscimo (R$)';
        } else {
            this.rotuloReducao = '%Redução';
            this.rotuloDecrescimo = '%Decréscimo';
        }

        this.validaLimiteDeValoresBaseadoPercentualeFixo();
        if (this.formGroupReducaoPunitiva.value.haPerdaBeneficio.id === '4') {
            this.validaLimiteDeValoresNosCamposReducaoTabela();
        }


        this.changeForm.emit(this.formGroupReducaoPunitiva.value);
        this.validaPreenchimentoForm.emit(this.validaPreenchimentoCompletoForm());
        this.changeValorReducao(event);
    }

    changeValorReducao(ev) {
        if (ev == 1) {
            this.mode = "currency";
            this.currency = "BRL";
            this.locale = "pt-BR";
        } else {
            this.mode = null;
        }
    }

    onChangePerdaBeneficio(event) {
        if (event.id === '2') {
            this.indicaQualAbaExibir = 2;
        } else if (event.id === '3') {
            this.indicaQualAbaExibir = 3;
        } else if (event.id === '4') {
            this.indicaQualAbaExibir = 4;
        } else {
            this.indicaQualAbaExibir = 1;
        }
        this.changeForm.emit(this.formGroupReducaoPunitiva.value);
        this.validaPreenchimentoForm.emit(this.validaPreenchimentoCompletoForm());
    }

    constroiObjFinalRegraReducaoProgressivaDescrecimoVariavel() {
        const lista = this.formGroupReducaoPunitiva.value.regraReducaoProgressivaVariavelLista;
        if (lista?.length === 0) {
            this.erroTabelaObrigatoria = true;
            return;
        }

        for (let i = 0; i < lista.length; i++) {
            if (i == 0) {
                lista[i].qtdDiasInicial = 0;
            } else {
                lista[i].qtdDiasInicial = lista[i - 1].qtdDiasFinal + 1;
            }
        }

        this.formGroupReducaoPunitiva.value.regraReducaoProgressivaVariavelLista = lista;

    }

    constroiObjFinalRegraReducaoProgressivaDescrecimoFixo() {
        const qtdDiasFinal = this.formGroupReducaoPunitiva.value.qtdDiasSubSequente;
        let valorReducao = this.formGroupReducaoPunitiva.value.valorReducao;
        const valorDecrescimo = this.formGroupReducaoPunitiva.value.valorDecrescimo;

        const lista = [];
        let i = 0;

        if (valorReducao >= 0 && valorDecrescimo >= 0) {
            while (valorReducao > valorDecrescimo) {
                let obj;
                if (i === 0) {
                    obj = {
                        valorReducao,
                        qtdDiasInicial: 0,
                        qtdDiasFinal,
                        transacaoRegraReducaoId: null, // id pegar no back pq na criaçãoo o front nao tem
                    };

                } else {
                    valorReducao = valorReducao - valorDecrescimo;

                    obj = {
                        valorReducao,
                        qtdDiasInicial: qtdDiasFinal * i + 1,
                        qtdDiasFinal: qtdDiasFinal * (i + 1),
                        transacaoRegraReducaoId: null
                    };
                }
                lista.push(obj);
                i++;
            }
        }

        this.formGroupReducaoPunitiva.value.regraReducaoProgressivaFixoLista = lista;
    }

    criacaoRegraReducaoProgressiva() {
        if (this.formGroupReducaoPunitiva.value.haPerdaBeneficio.id === '3') {
            this.constroiObjFinalRegraReducaoProgressivaDescrecimoFixo();
            this.changeForm.emit(this.formGroupReducaoPunitiva.value);
            this.validaPreenchimentoForm.emit(this.validaPreenchimentoCompletoForm());
        }

        if (this.formGroupReducaoPunitiva.value.haPerdaBeneficio.id === '4') {
            this.constroiObjFinalRegraReducaoProgressivaDescrecimoVariavel();
            this.changeForm.emit(this.formGroupReducaoPunitiva.value);
            this.validaPreenchimentoForm.emit(this.validaPreenchimentoCompletoForm());
        }
    }

    validaPreenchimentoCompletoForm() {
        switch (this.indicaQualAbaExibir) {
            case 1:
                if (this.reducaoPunitivaFormControl.valorReducao.status === 'VALID') {
                    this.formPreenchidoCorretamente = true;
                } else {
                    this.formPreenchidoCorretamente = false;
                }
                break;
            case 2:
                if (this.reducaoPunitivaFormControl.valorReducao.status === 'VALID'
                    && this.reducaoPunitivaFormControl.qtdDias.status === 'VALID') {
                    this.formPreenchidoCorretamente = true;
                } else {
                    this.formPreenchidoCorretamente = false;
                }
                break;
            case 3:
                if (this.reducaoPunitivaFormControl.valorReducao.status === 'VALID'
                    && this.reducaoPunitivaFormControl.qtdDias.status === 'VALID'
                    && this.reducaoPunitivaFormControl.valorDecrescimo.status === 'VALID'
                    && this.reducaoPunitivaFormControl.qtdDiasSubSequente.status === 'VALID') {
                    this.formPreenchidoCorretamente = true;
                } else {
                    this.formPreenchidoCorretamente = false;
                }
                break;
            case 4:
                if (!this.erroQtdDiaTabela &&
                    !this.erroValorReducaoTabela &&
                    !this.erroCamposObrigatoriosTabela &&
                    this.formGroupReducaoPunitiva.value.regraReducaoProgressivaVariavelLista.length > 0) {
                    this.formPreenchidoCorretamente = true;
                } else {
                    this.formPreenchidoCorretamente = false;
                }
                break;
        }

        if (this.erroLimiteReducao || this.erroLimiteDecrescimo) {
            this.formPreenchidoCorretamente = false;
        }

        return this.formPreenchidoCorretamente;
    }
}

import { CurrencyPipe } from "@angular/common";
import { Component } from "@angular/core";
import { ConsultaContaCorrenteService } from "src/app/component/criacao-conta-corrente/services/consulta-conta-corrente.service";

enum TipoItem {
    ALFANUMERICO = "ALFANUMERICO",
    NUMERICO = "NUMERICO",
    DATA = "DATA",
    DECIMAL = "DECIMAL",
}
@Component({
    selector: "app-modal-detalhe-lancamento-conta",
    templateUrl: "./modal-detalhe-lancamento-conta.component.html",
    styleUrls: ["./modal-detalhe-lancamento-conta.component.scss"],
})
export class ModalDetalheLancamentoContaComponent {
    visible: boolean;
    dadosLancamento: any;
    currencyPipe: CurrencyPipe = new CurrencyPipe("pt", "BRL");

    constructor(
        private consultaContaCorrenteService: ConsultaContaCorrenteService
    ) {}

    public showDialog() {
        const conta = JSON.parse(localStorage.getItem("conta"));

        this.consultaContaCorrenteService
            .getDetalhesLancamentoConta(conta?.contaCorrenteId)
            .subscribe((res) => {
                this.dadosLancamento = res;
                this.visible = true;
            });
    }

    formatItemValor(tipo, valor) {
        switch (tipo) {
            case TipoItem.DECIMAL:
                const valorSanitizer = valor
                    ?.toString()
                    .replace(",", ".")
                    .replace(" ", "");
                return this.currencyPipe.transform(valorSanitizer, null, "");
            default:
                return valor;
        }
    }

    hiddenDialog() {
        this.visible = false;
    }
}

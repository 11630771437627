import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { UtilsService } from "src/app/shared/utils.service";
import { environment } from "./../../../environments/environment";

@Component({
    selector: "app-modal-transacao-conta-corrente",
    templateUrl: "./modal-transacao-conta-corrente.component.html",
    styleUrls: ["./modal-transacao-conta-corrente.component.scss"],
})
export class ModalTransacaoContaCorrenteComponent implements OnInit {
    transacao: any = null;
    visible = false;

    constructor(private http: HttpClient, private utilsService: UtilsService) {}

    ngOnInit(): void {}

    hiddenDialog() {
        this.visible = false;
        this.transacao = null;
    }

    openModal(idTransacao, idEvento, dataTransacao: any) {
        this.http
            .get(`${environment.baseUrl}/conta-corrente/transacoes/info`, {
                params: {
                    idTransacao,
                    idEvento,
                    dataTransacao: dataTransacao.replaceAll("/", "-"),
                },
            })
            .subscribe((resp) => {
                this.visible = true;
                this.transacao = resp;
            });
    }

    converteDataHora(dataCriacao) {
        if(!dataCriacao)

            return null;

        return `${this.utilsService.convertToDateBr(
            dataCriacao.substring(0, 10)
        )} às ${dataCriacao.substring(11, 20)}`;
    }
}

<div>
    <strong style="margin-bottom: 25px">Eventos</strong>
</div>

<p-table
    #dt1
    [value]="eventosList?.content"
    [globalFilterFields]="[
        'dataEvento',
        'configuracaoEventos.eventos.descricao',
        'saldoConta.descricao',
        'situacaoCredito.descricao',
        'estadoConta.descricao',
        'valorTotalTransacoes'
    ]"
>
    <ng-template pTemplate="caption">
        <div class="p-d-flex" style="margin-left: auto !important; width: 16%">
            <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input
                    pInputText
                    type="text"
                    (input)="dt1.filterGlobal($event.target.value, 'contains')"
                    placeholder="Pesquisar na lista"
                />
            </span>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th>Data do Evento</th>
            <th>Data do Registro</th>
            <th>Nome</th>
            <th>Saldo da Conta</th>
            <th>Situação do Crédito</th>
            <th>Estado da Conta</th>
            <th class="text-right">Valor</th>
            <th class="text-right">Ações</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-evento>
        <tr
            class="selecao"
            [ngClass]="{
                'evento-selecionado': vinculoEvento(
                    evento.contaCorrenteEventoId
                )
            }"
        >
            <td
                (click)="
                    buscaTransacoesVinculoEvento(evento.contaCorrenteEventoId)
                "
            >
                {{ evento?.dataEvento }}
            </td>
            <td
            (click)="
                buscaTransacoesVinculoEvento(evento.contaCorrenteEventoId)
            "
        >
            {{ evento?.dataCriacao | date: 'dd/MM/yyyy' }}
        </td>
            <td
                (click)="
                    buscaTransacoesVinculoEvento(evento.contaCorrenteEventoId)
                "
            >
                {{ evento?.configuracaoEventos?.eventos?.descricao }}
            </td>
            <td
                (click)="
                    buscaTransacoesVinculoEvento(evento.contaCorrenteEventoId)
                "
            >
                {{ evento?.saldoConta?.descricao }}
            </td>
            <td
                (click)="
                    buscaTransacoesVinculoEvento(evento.contaCorrenteEventoId)
                "
            >
                {{ evento?.situacaoCredito?.descricao }}
            </td>
            <td
                (click)="
                    buscaTransacoesVinculoEvento(evento.contaCorrenteEventoId)
                "
            >
                {{ evento?.estadoConta?.descricao }}
            </td>
            <td
                (click)="
                    buscaTransacoesVinculoEvento(evento.contaCorrenteEventoId)
                "
                class="text-right"
            >
                {{ evento?.valorTotalTransacoes | currency : "BRL" : "" }}
            </td>
            <td class="text-right">
                <i
                    class="fa fa-search"
                    style="cursor: pointer; font-size: large; margin-left: 5px"
                    tooltipPosition="top"
                    aria-hidden="true"
                    pTooltip="Ver Detalhes do Evento"
                    (click)="openModalEvento(evento)"
                ></i>
                <i
                    *ngIf="evento.configuracaoEventos?.eventos?.eventosId === 1"
                    class="fa fa-list-ul"
                    style="cursor: pointer; font-size: large; margin-left: 5px"
                    tooltipPosition="top"
                    aria-hidden="true"
                    pTooltip="Dados do Evento"
                    (click)="openModalLancamento($event)"
                ></i>
                <i
                    *ngIf="evento.eventosAssociados?.length > 0"
                    class="fa fa-eye"
                    style="cursor: pointer; font-size: large"
                    pTooltip="Ver Eventos Associadas"
                    tooltipPosition="top"
                    aria-hidden="true"
                    (click)="openModalEventoAssociado(evento)"
                ></i>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td class="p-text-center" colspan="9">
                Nenhum registro encontrado
            </td>
        </tr>
    </ng-template>
</p-table>
<p-paginator
    [first]="0"
    [rows]="15"
    [rowsPerPageOptions]="[15, 25, 50, 100]"
    [totalRecords]="eventosList?.totalElements"
    (onPageChange)="paginar($event)"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{first} - {last} de {totalRecords}"
></p-paginator>
<div>
    <strong style="margin-bottom: 25px">Transações</strong>
</div>

<p-table
    #dt2
    [value]="transacaoList"
    [rowHover]="true"
    [globalFilterFields]="[
        'dataTransacao',
        'nomeResumido',
        'valorPrincipal',
        'valorMultaMora',
        'valorJurosMora',
        'valorMultaInfracao',
        'valorTotal'
    ]"
>
    <ng-template pTemplate="caption">
        <div class="p-d-flex" style="margin-left: auto !important; width: 16%">
            <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input
                    pInputText
                    type="text"
                    (input)="dt2.filterGlobal($event.target.value, 'contains')"
                    placeholder="Pesquisar na lista"
                />
            </span>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th>Data</th>
            <th>Data do Registro</th>
            <th>Nome</th>
            <th class="text-right">Principal</th>
            <th class="text-right">Juros</th>
            <th class="text-right">Multa (Mora)</th>
            <th class="text-right">Multa (Infração)</th>
            <th class="text-right">Saldo</th>
            <th class="text-right">Ações</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
        <tr
            class="selecao"
            (click)="buscaTransacoesVinculoEvento(item.contaCorrenteEventoId)"
            [ngClass]="{
                'evento-selecionado': vinculoEvento(item.contaCorrenteEventoId)
            }"
        >
            <td>{{ item.dataTransacao }}</td>
            <td>{{ item.dataCriacaoEvento }}</td>
            <td>{{ item.nomeResumido }}</td>
            <td class="text-right">
                {{ item.valorPrincipal | currency : "BRL" : "" }}
            </td>
            <td class="text-right">
                {{ item.valorMultaMora | currency : "BRL" : "" }}
            </td>
            <td class="text-right">
                {{ item.valorJurosMora | currency : "BRL" : "" }}
            </td>
            <td class="text-right">
                {{ item.valorMultaInfracao | currency : "BRL" : "" }}
            </td>
            <td class="text-right">
                {{ item.valorTotal | currency : "BRL" : "" }}
            </td>
            <td class="text-right">
                <i
                    class="fa fa-search"
                    style="cursor: pointer; font-size: large; margin-left: 5px"
                    tooltipPosition="top"
                    aria-hidden="true"
                    pTooltip="Ver Dados da Transação"
                    (click)="
                        modalTransacao.openModal(
                            item.transacaoId,
                            item.contaCorrenteEventoId,
                            item.dataTransacao
                        )
                    "
                ></i>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td class="p-text-center" colspan="9">
                Nenhum registro encontrado
            </td>
        </tr>
    </ng-template>
</p-table>

<modal-evento-conta-corrente></modal-evento-conta-corrente>

<app-modal-detalhe-lancamento-conta></app-modal-detalhe-lancamento-conta>
<app-modal-transacao-conta-corrente
    #modalTransacao
></app-modal-transacao-conta-corrente>
<p-dialog
    header="Eventos Associados"
    [(visible)]="visibleAssociados"
    [baseZIndex]="10000"
    [resizable]="false"
>
    <p-table #dt1 [value]="eventosAssociados">
        <ng-template pTemplate="header">
            <tr>
                <th>Nº Conta Corrente</th>
                <th>Conta Corrente</th>
                <th>Data do Evento</th>
                <th>Nome</th>
                <th>Saldo da Conta</th>
                <th>Período de Referência/Parcela</th>
                <th>Situação do Crédito</th>
                <th>Estado da Conta</th>
                <th class="text-right">Valor</th>
                <th>Ações</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-evento>
            <tr>
                <td>
                    {{ evento?.contaCorrenteId }}
                </td>
                <td>
                    {{
                        evento?.configContaId +
                            " - " +
                            evento?.configContaNomeCompleto
                    }}
                </td>
                <td>
                    {{ evento?.dataEvento }}
                </td>
                <td>
                    {{ evento?.nomeEvento }}
                </td>
                <td>
                    {{ evento?.saldoConta }}
                </td>
                <td>
                    {{
                        converteMesAno(evento?.periodoReferencia) +
                            " - " +
                            evento?.parcela
                    }}
                </td>
                <td>
                    {{ evento?.situacaoCredito }}
                </td>
                <td>
                    {{ evento?.estadoConta }}
                </td>
                <td class="text-right">
                    {{ evento?.valorTotalTransacoes | currency : "BRL" : "" }}
                </td>
                <td>
                    <i
                        (click)="detalharEventoConta(evento)"
                        pTooltip="Detalhe Conta"
                        tooltipPosition="top"
                        class="fa fa-list"
                        style="cursor: pointer;"
                    ></i>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-dialog>

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TipoCadastro } from "src/app/models/tipo-cadastro";
import { Pageable } from "src/app/shared/pagination/pageable";
import { Pagination } from "src/app/shared/pagination/pagination";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class ConsultaContribuinteService {
    constructor(private http: HttpClient) {}

    getContribuinte(cpfCnpj): Observable<Pageable<any>> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/conta-corrente/contas-contribuinte/${cpfCnpj}`
        );
    }

    getContribuinteView(
        objParams,
        pagination: Pagination<any>
    ): Observable<Pageable<any>> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/conta-corrente/view/search`,
            {
                params: {
                    ...objParams,
                    page: pagination.pageable.pageNumber,
                    size: pagination.size,
                },
            }
        );
    }

    getContribuinteFinanceiro(contaCorrenteId): Observable<Pageable<any>> {
        return this.http.get<Pageable<any>>(
            `${environment.baseUrl}/conta-corrente/${contaCorrenteId}/financeiro`
        );
    }

    getContribuintePorTipoCadastro(
        tipoCadastro: TipoCadastro,
        cadastro: number
    ) {
        return this.http.get<any>(
            `${environment.baseUrl}/contribuinte/tipoCadastro/${tipoCadastro}/cadastro/${cadastro}`
        );
    }
}

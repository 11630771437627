import { AcaoHelper } from './acao-helper';

export enum AcoesContaCorrente {
    DASH_ARRECADACAO_ESPERADA_ATUALIZADA = 'DASH_ARRECADACAO_ESPERADA_ATUALIZADA',
    DASH_IMPOSTO_CONTAS_ATRASO = 'DASH_IMPOSTO_CONTAS_ATRASO',
    DASH_IMPOSTO_CONTA_PAGAS = 'DASH_IMPOSTO_CONTA_PAGAS',
    DASH_EVENTO_PERIODO = 'DASH_EVENTO_PERIODO',
    DASH_RELATORIO_CONTA_CORRENTE_LISTAR = 'DASH_RELATORIO_CONTA_CORRENTE_LISTAR',
    DASH_RELATORIO_CONTA_CORRENTE_EXPORTAR = 'DASH_RELATORIO_CONTA_CORRENTE_EXPORTAR',
    CONFIG_CONTA_CORRENTE_GERENCIAR_MODELOS_LISTAR = 'CONFIG_CONTA_CORRENTE_GERENCIAR_MODELOS_LISTAR',
    CONFIG_CONTA_CORRENTE_GERENCIAR_MODELOS_CADASTRAR = 'CONFIG_CONTA_CORRENTE_GERENCIAR_MODELOS_CADASTRAR',
    CONFIG_CONTA_CORRENTE_GERENCIAR_MODELOS_EDITAR = 'CONFIG_CONTA_CORRENTE_GERENCIAR_MODELOS_EDITAR',
    CONFIG_CONTA_CORRENTE_GERENCIAR_MODELOS_EXCLUIR = 'CONFIG_CONTA_CORRENTE_GERENCIAR_MODELOS_EXCLUIR',
    CONFIG_CONTA_CORRENTE_GERENCIAR_CONTAS_LISTAR = 'CONFIG_CONTA_CORRENTE_GERENCIAR_CONTAS_LISTAR',
    CONFIG_CONTA_CORRENTE_GERENCIAR_CONTAS_CADASTRAR = 'CONFIG_CONTA_CORRENTE_GERENCIAR_CONTAS_CADASTRAR',
    CONFIG_CONTA_CORRENTE_GERENCIAR_CONTAS_EDITAR = 'CONFIG_CONTA_CORRENTE_GERENCIAR_CONTAS_EDITAR',
    CONFIG_CONTA_CORRENTE_GERENCIAR_CONTAS_EXCLUIR = 'CONFIG_CONTA_CORRENTE_GERENCIAR_CONTAS_EXCLUIR',
    CONFIG_TRANSACAO_LISTAR = 'CONFIG_TRANSACAO_LISTAR',
    CONFIG_TRANSACAO_CADASTRAR = 'CONFIG_TRANSACAO_CADASTRAR',
    CONFIG_TRANSACAO_EDITAR = 'CONFIG_TRANSACAO_EDITAR',
    CONFIG_TRANSACAO_EXCLUIR = 'CONFIG_TRANSACAO_EXCLUIR',
    CONFIG_ESTADO_CONTA_LISTAR = 'CONFIG_ESTADO_CONTA_LISTAR',
    CONFIG_ESTADO_CONTA_CADASTRAR = 'CONFIG_ESTADO_CONTA_CADASTRAR',
    CONFIG_ESTADO_CONTA_EDITAR = 'CONFIG_ESTADO_CONTA_EDITAR',
    CONFIG_ESTADO_CONTA_EXCLUIR = 'CONFIG_ESTADO_CONTA_EXCLUIR',
    CONFIG_EVENTOS_LISTAR = 'CONFIG_EVENTOS_LISTAR',
    CONFIG_EVENTOS_CADASTRAR = 'CONFIG_EVENTOS_CADASTRAR',
    CONFIG_EVENTOS_EDITAR = 'CONFIG_EVENTOS_EDITAR',
    CONFIG_EVENTOS_EXCLUIR = 'CONFIG_EVENTOS_EXCLUIR',
    CONFIG_GERAIS_REGRA_IMPUTACAO_LISTAR = 'CONFIG_GERAIS_REGRA_IMPUTACAO_LISTAR',
    CONFIG_GERAIS_REGRA_IMPUTACAO_CADASTRAR = 'CONFIG_GERAIS_REGRA_IMPUTACAO_CADASTRAR',
    CONFIG_GERAIS_REGRA_IMPUTACAO_EDITAR = 'CONFIG_GERAIS_REGRA_IMPUTACAO_EDITAR',
    CONFIG_GERAIS_REGRA_IMPUTACAO_ATIVAR_INATIVAR = 'CONFIG_GERAIS_REGRA_IMPUTACAO_ATIVAR_INATIVAR',
    CONFIG_INTEGRACAO_OPERADORES_LISTAR = 'CONFIG_INTEGRACAO_OPERADORES_LISTAR',
    CONFIG_INTEGRACAO_OPERADORES_CADASTRAR = 'CONFIG_INTEGRACAO_OPERADORES_CADASTRAR',
    CONFIG_INTEGRACAO_OPERADORES_EDITAR = 'CONFIG_INTEGRACAO_OPERADORES_EDITAR',
    CONFIG_INTEGRACAO_OPERADORES_EXCLUIR = 'CONFIG_INTEGRACAO_OPERADORES_EXCLUIR',
    CONFIG_INTEGRACAO_FLUXOS_LISTAR = 'CONFIG_INTEGRACAO_FLUXOS_LISTAR',
    CONFIG_INTEGRACAO_FLUXOS_EDITAR = 'CONFIG_INTEGRACAO_FLUXOS_EDITAR',
    CONFIG_INTEGRACAO_FLUXOS_CADASTRAR = 'CONFIG_INTEGRACAO_FLUXOS_CADASTRAR',
    CONFIG_INTEGRACAO_FLUXOS_EXCLUIR = 'CONFIG_INTEGRACAO_FLUXOS_EXCLUIR',
    CONTA_CORRENTE_ABERTURA = 'CONTA_CORRENTE_ABERTURA',
}

export class AcoesContaCorrenteHelper {

    static get podeVisualizarDashboardArrecadacaoEsperadaAtualizada(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.DASH_ARRECADACAO_ESPERADA_ATUALIZADA);
    }

    static get podeVisualizarDashboardImpostoContasEmAtraso(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.DASH_IMPOSTO_CONTAS_ATRASO);
    }

    static get podeVisualizarDashboardImpostoContasPagas(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.DASH_IMPOSTO_CONTA_PAGAS);
    }

    static get podeVisualizarDashboardEventosPorPeriodo(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.DASH_EVENTO_PERIODO);
    }

    static get podeVisualizarDashboardRelatorioContaCorrente(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.DASH_RELATORIO_CONTA_CORRENTE_LISTAR);
    }

    static get podeExportarRelatorioContaCorrente(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.DASH_RELATORIO_CONTA_CORRENTE_EXPORTAR);
    }

    static get podeListarModelosContaCorrente(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_CONTA_CORRENTE_GERENCIAR_MODELOS_LISTAR);
    }

    static get podeCadastrarModelosContaCorrente(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_CONTA_CORRENTE_GERENCIAR_MODELOS_CADASTRAR);
    }

    static get podeEditarModelosContaCorrente(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_CONTA_CORRENTE_GERENCIAR_MODELOS_EDITAR);
    }

    static get podeExcluirModelosContaCorrente(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_CONTA_CORRENTE_GERENCIAR_MODELOS_EXCLUIR);
    }

    static get podeListarContasCorrente(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_CONTA_CORRENTE_GERENCIAR_CONTAS_LISTAR);
    }

    static get podeCadastrarContasCorrente(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_CONTA_CORRENTE_GERENCIAR_CONTAS_CADASTRAR);
    }

    static get podeEditarContasCorrente(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_CONTA_CORRENTE_GERENCIAR_CONTAS_EDITAR);
    }

    static get podeExcluirContasCorrente(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_CONTA_CORRENTE_GERENCIAR_CONTAS_EXCLUIR);
    }

    static get podeListarTransacao(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_TRANSACAO_LISTAR);
    }

    static get podeCadastrarTransacao(): boolean {
            return AcaoHelper.pode(AcoesContaCorrente.CONFIG_TRANSACAO_CADASTRAR);
    }

    static get podeEditarTransacao(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_TRANSACAO_EDITAR);
    }

    static get podeExcluirTransacao(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_TRANSACAO_EXCLUIR);
    }

    static get podeListarEstadoConta(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_ESTADO_CONTA_LISTAR);
    }

    static get podeCadastrarEstadoConta(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_ESTADO_CONTA_CADASTRAR);
    }

    static get podeEditarEstadoConta(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_ESTADO_CONTA_EDITAR);
    }

    static get podeExcluirEstadoConta(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_ESTADO_CONTA_EXCLUIR);
    }

    static get podeListarEventos(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_EVENTOS_LISTAR);
    }

    static get podeCadastrarEventos(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_EVENTOS_CADASTRAR);
    }

    static get podeEditarEventos(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_EVENTOS_EDITAR);
    }

    static get podeExcluirEventos(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_EVENTOS_EXCLUIR);
    }

    static get podeListarRegrasImputacao(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_GERAIS_REGRA_IMPUTACAO_LISTAR);
    }

    static get podeCadastrarRegrasImputacao(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_GERAIS_REGRA_IMPUTACAO_CADASTRAR);
    }

    static get podeEditarRegrasImputacao(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_GERAIS_REGRA_IMPUTACAO_EDITAR);
    }

    static get podeAtivarInativarRegrasImputacao(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_GERAIS_REGRA_IMPUTACAO_ATIVAR_INATIVAR);
    }

    static get podeListarOperadoresIntegracao(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_INTEGRACAO_OPERADORES_LISTAR);
    }

    static get podeCadastrarOperadoresIntegracao(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_INTEGRACAO_OPERADORES_CADASTRAR);
    }

    static get podeEditarOperadoresIntegracao(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_INTEGRACAO_OPERADORES_EDITAR);
    }

    static get podeExcluirOperadoresIntegracao(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_INTEGRACAO_OPERADORES_EXCLUIR);
    }

    static get podeListarFluxosIntegracao(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_INTEGRACAO_FLUXOS_LISTAR);
    }

    static get podeEditarFluxosIntegracao(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_INTEGRACAO_FLUXOS_EDITAR);
    }

    static get podeCadastrarFluxosIntegracao(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_INTEGRACAO_FLUXOS_CADASTRAR);
    }

    static get podeExcluirFluxosIntegracao(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONFIG_INTEGRACAO_FLUXOS_EXCLUIR);
    }

    static get podeAbrirContaCorrente(): boolean {
        return AcaoHelper.pode(AcoesContaCorrente.CONTA_CORRENTE_ABERTURA);
    }

}

<div class="layout-topbar">
    <a href="/" class="logo-container">
        <img class="logo" alt="harmony-layout" src="assets/layout/images/brasao-ma.png" />
        <span class="app-name">CCP</span>
    </a>

    <div class="topbar-content">
        <a href="#" id="topbar-sidebar-button" (click)="app.onMenuButtonClick($event)">
            <i class="pi pi-bars"></i>
        </a>

        <a href="#" id="topbar-quickmenu-button" (click)="app.onQuickMenuButtonClick($event)">
            QUICK MENU
        </a>
        <div class="logged-area">
            <div class="logged-info">
                <div>
                    <p class="user-info">
                        {{ nomeUsuario }}
                        <span class="profile-tag"> {{ perfilUsuario }} </span>
                    </p>
                </div>
            </div>
            <div>
                <a id="topbar-profile-menu-button" href="#" (click)="app.onTopbarMenuButtonClick($event)">
                    <img src="assets/layout/images/avatar6.png" alt="harmony-layout" class="topbar-profile" />
                </a>
                <ul id="topbar-profile-menu" class="animated fadeInDown" [ngClass]="{'topbar-profile-menu-active': app.topbarMenuActive}">
                    <li #screen >
                        <a href="#" (click)="logOff()">
                            <i class="topbar-icon material-icons animated swing pi pi-power-off"></i>
                            <span class="topbar-item-name">Sair</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<app-mensagens-nao-lida-dte #modal ></app-mensagens-nao-lida-dte>

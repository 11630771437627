<p-dialog
    header="Detalhe de Auto de Infração"
    [(visible)]="visible"
    [style]="{ width: '50vw' }"
>
    <div class="p-fluid p-grid">
        <div class="p-field p-col-12">
            <strong>Número:</strong>
            {{ dadosAutoInfracao?.numero || "-" }}
        </div>
        <br />
        <div class="p-field p-col-12">
            <strong>Tipo:</strong>
            {{ dadosAutoInfracao?.tipo || "-" }}
        </div>
        <br />
        <div class="p-field p-col-12">
            <strong>Data do Auto de Infração/NL:</strong>
            {{
                (dadosAutoInfracao?.dataAutoInfracao | date : "dd/MM/yyyy") ||
                    "-"
            }}
        </div>
        <br />
        <div class="p-field p-col-12">
            <strong>Data da Ciência:</strong>
            {{ (dadosAutoInfracao?.dataCiencia | date : "dd/MM/yyyy") || "-" }}
        </div>
        <br />
        <div class="p-field p-col-12">
            <strong>Data Limite de Pagamento:</strong>
            {{
                (dadosAutoInfracao?.dataLimitePagamento
                    | date : "dd/MM/yyyy") || "-"
            }}
        </div>
        <br />
        <div class="p-field p-col-12">
            <strong>Situação do Débito:</strong>
            {{ dadosAutoInfracao?.situacaoDebito || "-" }}
        </div>
        <br />
        <div class="p-field p-col-12">
            <strong>Situação do Auto:</strong>
            {{ dadosAutoInfracao?.situacaoAuto || "-" }}
        </div>
        <div class="p-field p-col-12">
            <strong>Data da Lavratura:</strong>
            {{
                (dadosAutoInfracao?.dataLavratura
                    | date : "dd/MM/yyyy") || "-"
            }}
        </div>
        <br />
    </div>
</p-dialog>

import {Component, OnInit} from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { KeycloakService } from 'keycloak-angular';
import {AppTopbarService} from "./app.topbar.service";
import {environment} from './../environments/environment';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./app.topbar.component.scss']
})
export class AppTopBarComponent implements OnInit {
    nomeUsuario: string = ' - ';
    perfilUsuario: string;

    constructor(
        public app: AppMainComponent,
        private keycloakService: KeycloakService,
        private appTopbarService: AppTopbarService
    ) { }

    async ngOnInit(): Promise<void> {
        if (!this.keycloakService.getKeycloakInstance().authenticated || this.keycloakService.isTokenExpired()) {
            this.logOff();
            return;
        }

        this.decodificaToken();
    }

    logOff(): void {
        this.keycloakService.logout(environment.redirectUrl);
        this.appTopbarService.clearProfile();
        localStorage.clear();
        sessionStorage.clear();
    }

    decodificaToken() {
        if (this.keycloakService.isLoggedIn()) {
            const token: any = this.keycloakService.getKeycloakInstance();
            const tokenDecode = token.tokenParsed;
            const clientId = token.clientId;

            try {
                this.nomeUsuario = tokenDecode.name;
                this.perfilUsuario = tokenDecode.resource_access[clientId]?.roles || 'Contribuinte';
            } catch (error) {
                this.perfilUsuario = 'Contribuinte';
            }
        }
    }

}

import { Component, ViewChild } from "@angular/core";
import { ConsultaContaVeiculoComponent } from "src/app/component/criacao-conta-corrente/consulta-conta-veiculo/consulta-conta-veiculo.component";
import { UtilsService } from "src/app/shared/utils.service";

@Component({
    selector: "modal-evento-conta-corrente",
    templateUrl: "./modal-evento-conta-corrente.component.html",
    styleUrls: ["./modal-evento-conta-corrente.component.scss"],
})
export class ModalEventoContaCorrenteComponent {
    visible: boolean;
    blockedDocument: boolean;
    @ViewChild(ConsultaContaVeiculoComponent, { static: false })
    consultaConta: ConsultaContaVeiculoComponent;
    detalheEvento: any;
    dataHora: string;

    constructor(private utilsService: UtilsService) {}

    showDialog(evento) {
        this.visible = true;
        this.detalheEvento = evento;
        this.converteDataHora(this.detalheEvento.dataCriacao);
    }

    hiddenDialog() {
        this.visible = false;
    }

    converteDataHora(dataCriacao) {
        if(!dataCriacao){
            this.dataHora = null;
            return;
        }
        this.dataHora = `${this.utilsService.convertToDateBr(
            dataCriacao.substring(0, 10)
        )} às ${dataCriacao.substring(11, 20)}`;
    }

    downloadFile(detalheEvento: any) {
        const formato = detalheEvento?.documentos?.formato;
        const base64Data = detalheEvento?.documentos?.documento;
        const nomeArquivo = detalheEvento?.documentos?.nomeArquivo;

        console.log(detalheEvento)

        if (formato === "PDF") {
            const base64 = base64Data.replace(
                /^data:application\/pdf;base64,/,
                ""
            );

            const byteCharacters = atob(base64);
            const byteNumbers = new Uint8Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const blob = new Blob([byteNumbers], { type: "application/pdf" });
            const objectUrl = window.URL.createObjectURL(blob);

            const a = document.createElement("a");
            a.href = objectUrl;
            a.download = nomeArquivo;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            window.URL.revokeObjectURL(objectUrl);
        } else if (formato === "JSON") {
            console.log("Entrou no JSON");
            const base64 = base64Data.replace(
                /^data:application\/json;base64,/,
                ""
            );
            try {
                const jsonContent = atob(base64);
                const blob = new Blob([jsonContent], {
                    type: "application/json",
                });

                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = nomeArquivo;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);
            } catch (error) {
                console.error("Erro ao decodificar Base64:", error);
            }
        }
    }
}

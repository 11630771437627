<p-card header="Pesquisa de Configuração de Eventos">
    <div class="p-field p-col-12">
        <!-- RADIO BUTONS -->

        <p-fieldset legend="Tipo de Pesquisa">
            <div
                class="p-fluid p-formgrid p-grid"
                [style]="{ 'margin-top': '20px' }"
            >
                <div
                    *ngFor="let tc of tipoPesquisaList"
                    class="p-col-12 p-md-4"
                >
                    <div class="p-field-radiobutton">
                        <p-radioButton
                            [name]="tc.chave"
                            [inputId]="tc.chave"
                            [value]="tc.chave"
                            [(ngModel)]="tipoPesquisaEvento"
                            (onChange)="updatePaginator()"
                        ></p-radioButton>
                        <label style="cursor: pointer" [for]="tc.chave">{{
                            tc.valor
                        }}</label>
                    </div>
                </div>
            </div>
        </p-fieldset>
    </div>

    <!-- FORMULÁRIO CATEGORIA -->
    <div
        *ngIf="tipoPesquisaEvento === 'CATEGORIA'"
        class="p-fluid p-formgrid p-grid"
    >
        <div class="p-field p-col-12 p-md-6">
            <label title="regraCriacaoContaId">Categoria do Evento</label>
            <input [(ngModel)]="descricaoCategoria" type="text" pInputText />
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label title="regraCriacaoContaId">Descrição</label>
            <input [(ngModel)]="descricaoEvento" type="text" pInputText />
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label>Operação</label>
            <p-dropdown
                optionLabel="valor"
                optionValue="chave"
                showClear="true"
                [autoDisplayFirst]="false"
                [options]="operacaoList"
                [(ngModel)]="operacaoEnum"
            ></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label>Tipo Evento</label>
            <p-dropdown
                optionLabel="descricaoTipoEvento"
                optionValue="tipoEventoId"
                showClear="true"
                [autoDisplayFirst]="false"
                [options]="tipoEventoList"
                [(ngModel)]="tipoEvento"
            ></p-dropdown>
        </div>
    </div>

    <!-- FORMULÁRIO EVENTO -->
    <div
        *ngIf="tipoPesquisaEvento === 'EVENTO'"
        class="p-fluid p-formgrid p-grid"
    >
        <div class="p-field p-col-12 p-md-6">
            <label title="codEvento">Código</label>
            <p-inputNumber
                [(ngModel)]="configContaId"
                mode="decimal"
                [useGrouping]="false"
            ></p-inputNumber>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label title="regraCriacaoContaId">Descrição Completa</label>
            <input [(ngModel)]="eventoDescricaoValue" type="text" pInputText />
        </div>
        <!-- <div class="p-field p-col-12 p-md-3">
            <label>Tipo de Processamento</label>
            <p-dropdown
                optionLabel="valor"
                optionValue="chave"
                showClear="true"
                [autoDisplayFirst]="false"
                [options]="tipoProcessamentoList"
                [(ngModel)]="tipoProcessamentoValue"
            ></p-dropdown>
        </div> -->
        <div class="p-field p-col-12 p-md-3">
            <label>Lança Honorários</label>
            <p-dropdown
                optionLabel="valor"
                optionValue="chave"
                showClear="true"
                [autoDisplayFirst]="falgetConfigEventosse"
                [options]="honorariosList"
                [(ngModel)]="honorariosValue"
            ></p-dropdown>
        </div>
        <div class="p-col-12">
            <div class="p-field-checkbox">
                <p-checkbox
                    [disabled]="checkboxDisabled"
                    binary="false"
                    trueValue="SIM"
                    falseValue="NAO"
                    [(ngModel)]="implantadoValue"
                    label="Implantado"
                    title="Implantado"
                ></p-checkbox>
            </div>
        </div>
    </div>

    <!-- FORMULÁRIO CONTA -->
    <div
        *ngIf="tipoPesquisaEvento === 'CONTA'"
        class="p-fluid p-formgrid p-grid"
    >
        <div class="p-field p-col-12 p-md-6">
            <label title="conta">Código da conta</label>
            <p-inputNumber
                [(ngModel)]="conta"
                mode="decimal"
                [useGrouping]="false"
            ></p-inputNumber>
        </div>
        <div class="p-field p-col-12 p-md-3">
            <label>Tributo</label>
            <p-dropdown
                optionLabel="sigla"
                optionValue="tributoId"
                showClear="true"
                [autoDisplayFirst]="false"
                [options]="tributoList"
                [(ngModel)]="tributoValue"
            ></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-3">
            <label>Tipo da Conta</label>
            <p-dropdown
                optionLabel="valor"
                optionValue="chave"
                showClear="true"
                [autoDisplayFirst]="falgetConfigEventosse"
                [options]="tipoContaList"
                [(ngModel)]="tipoContaValue"
            ></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label title="regraCriacaoContaId">Nome completo</label>
            <input [(ngModel)]="contaDescricao" type="text" pInputText />
        </div>
    </div>

    <!-- AÇÕES PESQUISAR E NOVA CONFIG -->
    <div
        style="
            margin-top: 10px;
            margin-bottom: 10px;
            text-align: right !important;
        "
    >
        <button
            pButton
            type="button"
            class="p-mr-2"
            label="Pesquisar"
            icon="pi pi-search"
            (click)="filtroEventosConfig()"
        ></button>
        <button
            pButton
            type="button"
            label="Nova Configuração"
            class="p-mr-2 p-button-success"
            [routerLink]="['/evento-gerenciar-config']"
            [disabled]="!podeCadastrarEventos()"
        ></button>
        <button
            pButton
            type="button"
            icon="pi pi-trash"
            label="Limpar"
            class="p-button-danger"
            (click)="limpar()"
        ></button>
    </div>

    <!-- TABELA CATEGORIA -->
    <div *ngIf="tipoPesquisaEvento === 'CATEGORIA'">
        <p-table
            [value]="pagination.content"
            dataKey="eventosId"
            [scrollable]="true"
            [globalFilterFields]="[
                'operacao',
                'tipoEvento.descricaoTipoEvento'
            ]"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 4rem"></th>
                    <th pSortableColumn="descricao">
                        Categoria do Evento
                        <p-sortIcon field="name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="descricaoCompleta">
                        Descrição/Categoria
                        <p-sortIcon field="name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="eventoCategoria.operacao">
                        Operações <p-sortIcon field="operacao"></p-sortIcon>
                    </th>
                    <th pSortableColumn="tipoEvento.descricaoTipoEvento">
                        Tipo Evento
                        <p-sortIcon
                            field="tipoEvento.descricaoTipoEvento"
                        ></p-sortIcon>
                    </th>
                    <th>Ações</th>
                </tr>
            </ng-template>
            <ng-template
                pTemplate="body"
                let-eventoCategoria
                let-expanded="expanded"
                let-rowIndex="rowIndex"
            >
                <tr>
                    <td>
                        <button
                            type="button"
                            pButton
                            pRipple
                            [pRowToggler]="eventoCategoria"
                            class="p-button-text p-button-rounded p-button-plain"
                            [icon]="
                                expanded
                                    ? 'pi pi-chevron-down'
                                    : 'pi pi-chevron-right'
                            "
                            (click)="
                                getConfigEventosByTipoEventoId(
                                    eventoCategoria,
                                    rowIndex
                                )
                            "
                        ></button>
                    </td>
                    <td>{{ eventoCategoria.descricao }}</td>
                    <td>{{ eventoCategoria.descricaoCompleta }}</td>
                    <td>
                        {{ converteValorOperacoes(eventoCategoria?.operacao) }}
                    </td>
                    <td>
                        {{ eventoCategoria?.tipoEvento.descricaoTipoEvento }}
                    </td>
                    <td>
                        <button
                            pButton
                            pRipple
                            title="Editar"
                            type="button"
                            icon="pi pi-pencil"
                            class="p-button-rounded btn-action"
                            [routerLink]="[ '/evento-gerenciar-config/' + eventoCategoria.eventosId ]"
                            [disabled]="!podeEditarEventos()"
                        ></button>
                        <!-- <button
                            pButton
                            pRipple
                            title="Excluir"
                            type="button"
                            icon="pi pi-trash"
                            class="p-button-rounded p-button-danger"
                            style="margin-left: 3px"
                            [disabled]="!podeExcluirEventos()"
                        ></button> -->
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-eventoCategoria>
                <div class="p-3">
                    <p-table
                        [value]="eventoCategoria.eventosConfig"
                        dataKey="configuracaoEventosId"
                        [tableStyle]="{ 'min-width': '100%' }"
                        [globalFilterFields]="[
                            'operconfiguracaoEventosIdacao',
                            'descricaoResumida',
                            'descricaoCompleta',
                            'tipoProcessamento'
                        ]"
                    >
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="configuracaoEventosId">
                                    Id
                                    <p-sortIcon
                                        field="configuracaoEventosId"
                                    ></p-sortIcon>
                                </th>
                                <th pSortableColumn="descricaoResumida">
                                    Descrição Evento
                                    <p-sortIcon
                                        field="descricaoResumida"
                                    ></p-sortIcon>
                                </th>
                                <th pSortableColumn="descricaoCompleta">
                                    Descrição Completa Evento
                                    <p-sortIcon
                                        field="descricaoCompleta"
                                    ></p-sortIcon>
                                </th>
                                <!-- <th pSortableColumn="tipoProcessamento">
                                    Tipo de Processamento
                                    <p-sortIcon
                                        field="tipoProcessamento"
                                    ></p-sortIcon>
                                </th> -->
                                <th pSortableColumn="">
                                    Implantado
                                    <p-sortIcon field=""></p-sortIcon>
                                </th>
                                <th style="width: 4rem">Ações</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-ev>
                            <tr style="background-color: azure">
                                <td>{{ ev.configuracaoEventosId }}</td>
                                <td>{{ ev.descricaoResumida }}</td>
                                <td>{{ ev.descricaoCompleta }}</td>
                                <!-- <td>{{ ev.tipoProcessamento }}</td> -->
                                <td>{{ ev.implantado }}</td>
                                <td>
                                    <button
                                        pButton
                                        pRipple
                                        title="Editar"
                                        type="button"
                                        icon="pi pi-pencil"
                                        class="p-button-rounded btn-action"
                                        [routerLink]="[
                                            '/evento-gerenciar-config/' +
                                                eventoCategoria.eventosId +
                                                '/' +
                                                ev.configuracaoEventosId
                                        ]"
                                    ></button>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td
                                    style="background-color: azure"
                                    class="p-text-center"
                                    colspan="10"
                                >
                                    Nenhum registro encontrado
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td class="p-text-center" colspan="10">
                        Nenhum registro encontrado
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <p-paginator
            [first]="0"
            [rows]="20"
            [rowsPerPageOptions]="[20, 50, 100]"
            [totalRecords]="pagination.totalElements || 0"
            (onPageChange)="onPageChange($event)"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="{first} - {last} de {totalRecords}"
        ></p-paginator>
    </div>

    <!-- TABELA EVENTO -->
    <div *ngIf="tipoPesquisaEvento === 'EVENTO'">
        <p-table
            [value]="paginationEvento.content"
            dataKey="configuracaoEventosId"
            [scrollable]="true"
            [globalFilterFields]="[
                'eventos?.descricao',
                'descricaoCompleta',
                'tipoProcessamento',
                'implantado'
            ]"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 4rem"></th>
                    <th pSortableColumn="eventos.descricao">
                        Categoria
                        <p-sortIcon field="eventos.descricao"></p-sortIcon>
                    </th>
                    <th pSortableColumn="descricaoCompleta">
                        Descrição
                        <p-sortIcon field="descricaoCompleta"></p-sortIcon>
                    </th>
                    <!-- <th pSortableColumn="tipoProcessamento">
                        Tipo de Processamento
                        <p-sortIcon field="tipoProcessamento"></p-sortIcon>
                    </th> -->
                    <th pSortableColumn="implantado">
                        Implantado <p-sortIcon field="implantado"></p-sortIcon>
                    </th>
                    <th>Ações</th>
                </tr>
            </ng-template>
            <ng-template
                pTemplate="body"
                let-evento
                let-expanded="expanded"
                let-rowIndex="rowIndex"
            >
                <tr>
                    <td>
                        <button
                            type="button"
                            pButton
                            pRipple
                            [pRowToggler]="evento"
                            class="p-button-text p-button-rounded p-button-plain"
                            [icon]="
                                expanded
                                    ? 'pi pi-chevron-down'
                                    : 'pi pi-chevron-right'
                            "
                            (click)="
                                getConfigByConfiguracaoEventosId(
                                    evento,
                                    rowIndex
                                )
                            "
                        ></button>
                    </td>
                    <td>{{ evento?.eventos?.descricao }}</td>
                    <td>{{ evento?.descricaoCompleta }}</td>
                    <!-- <td>{{ evento?.tipoProcessamento }}</td> -->
                    <td>{{ evento?.implantado }}</td>
                    <td>
                        <button
                            pButton
                            pRipple
                            title="Editar"
                            type="button"
                            icon="pi pi-pencil"
                            class="p-button-rounded btn-action"
                            [routerLink]="[
                                '/evento-gerenciar-config/' +
                                    evento?.eventos?.eventosId +
                                    '/' +
                                    evento?.configuracaoEventosId
                            ]"
                        ></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-evento>
                <p-table
                    [value]="evento.configEventos"
                    dataKey="configConta.configContaId"
                    [globalFilterFields]="[
                        'configConta?.configContaId',
                        'configConta?.nomeResumido',
                        'configConta?.nomeCompleto',
                        'configConta?.tributo?.sigla',
                        'configConta?.implantado'
                    ]"
                >
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="configConta.configContaId">
                                Id
                                <p-sortIcon
                                    field="configConta.configContaId"
                                ></p-sortIcon>
                            </th>
                            <th pSortableColumn="configConta.nomeResumido">
                                Descrição Evento
                                <p-sortIcon
                                    field="configConta.nomeResumido"
                                ></p-sortIcon>
                            </th>
                            <th pSortableColumn="configConta.nomeCompleto">
                                Descrição Completa Evento
                                <p-sortIcon
                                    field="configConta.nomeCompleto"
                                ></p-sortIcon>
                            </th>
                            <th pSortableColumn="configConta.tributo?.sigla">
                                Tipo
                                <p-sortIcon
                                    field="configConta.tributo?.sigla"
                                ></p-sortIcon>
                            </th>
                            <th pSortableColumn="configConta?.implantado">
                                Implantado
                                <p-sortIcon
                                    field="configConta.implantado"
                                ></p-sortIcon>
                            </th>
                            <th style="width: 4rem">Ações</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-ev>
                        <tr style="background-color: azure">
                            <td>{{ ev?.configConta?.configContaId }}</td>
                            <td>{{ ev?.configConta?.nomeResumido }}</td>
                            <td>{{ ev?.configConta?.nomeCompleto }}</td>
                            <td>{{ ev?.configConta?.tributo?.sigla }}</td>
                            <td>{{ ev?.configConta?.implantado }}</td>
                            <td>
                                <button
                                    pButton
                                    pRipple
                                    title="Editar"
                                    type="button"
                                    icon="pi pi-pencil"
                                    class="p-button-rounded btn-action"
                                    [routerLink]="[
                                        '/evento-gerenciar-config/' +
                                            evento?.eventos?.eventosId +
                                            '/' +
                                            evento?.configuracaoEventosId,
                                        {
                                            conta: true
                                        }
                                    ]"
                                ></button>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td
                                style="background-color: azure"
                                class="p-text-center"
                                colspan="5"
                            >
                                Nenhum registro encontrado
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td class="p-text-center" colspan="5">
                        Nenhum registro encontrado
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <p-paginator
            [first]="0"
            [rows]="20"
            [rowsPerPageOptions]="[20, 50, 100]"
            [totalRecords]="paginationEvento.totalElements || 0"
            (onPageChange)="onPageChange($event)"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="{first} - {last} de {totalRecords}"
        ></p-paginator>
    </div>

    <!-- TABELA CONTA -->
    <div *ngIf="tipoPesquisaEvento === 'CONTA'">
        <p-table
            [value]="paginationConta.content"
            dataKey="eventoContaId"
            [scrollable]="true"
            [globalFilterFields]="['implantado']"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 4rem"></th>
                    <th pSortableColumn="">
                        Categoria <p-sortIcon field=""></p-sortIcon>
                    </th>
                    <th pSortableColumn="configuracaoEventos.descricaoResumida">
                        Evento
                        <p-sortIcon
                            field="configuracaoEventos.descricaoResumida"
                        ></p-sortIcon>
                    </th>
                    <th pSortableColumn="configuracaoEventos.descricaoResumida">
                        Código
                        <p-sortIcon
                            field="configuracaoEventos.descricaoResumida"
                        ></p-sortIcon>
                    </th>
                    <th pSortableColumn="tipoProcessamento">
                        Conta
                        <p-sortIcon field="tipoProcessamento"></p-sortIcon>
                    </th>
                    <th pSortableColumn="implantado">
                        Tributo <p-sortIcon field="implantado"></p-sortIcon>
                    </th>
                    <th pSortableColumn="implantado">
                        Tipo da Conta
                        <p-sortIcon field="implantado"></p-sortIcon>
                    </th>
                    <th pSortableColumn="implantado">
                        Início Vigência
                        <p-sortIcon field="implantado"></p-sortIcon>
                    </th>
                    <th pSortableColumn="implantado">
                        Fim Vigência
                        <p-sortIcon field="implantado"></p-sortIcon>
                    </th>
                    <th>Ações</th>
                </tr>
            </ng-template>
            <ng-template
                pTemplate="body"
                let-evento
                let-expanded="expanded"
                let-rowIndex="rowIndex"
            >
                <tr>
                    <td>
                        <button
                            type="button"
                            pButton
                            pRipple
                            [pRowToggler]="evento"
                            class="p-button-text p-button-rounded p-button-plain"
                            [icon]="
                                expanded
                                    ? 'pi pi-chevron-down'
                                    : 'pi pi-chevron-right'
                            "
                        ></button>
                    </td>
                    <td>
                        {{ evento?.configuracaoEventos.eventos.eventosId }} -
                        {{ evento?.configuracaoEventos.eventos.descricao }}
                    </td>
                    <td>{{ evento?.configuracaoEventos.descricaoResumida }}</td>
                    <td>{{ evento?.configConta.configContaId }}</td>
                    <td>{{ evento?.configConta.nomeResumido }}</td>
                    <td>{{ evento?.configConta?.tributo?.sigla }}</td>
                    <td>
                        {{ evento?.configConta?.tipoConta?.replace("_", " ") }}
                    </td>
                    <td>
                        {{ evento?.dataInicioVigencia | date : "dd/MM/yyyy" }}
                    </td>
                    <td>{{ evento?.dataFimVigencia | date : "dd/MM/yyyy" }}</td>
                    <td>
                        <button
                            pButton
                            pRipple
                            title="Editar"
                            type="button"
                            icon="pi pi-pencil"
                            class="p-button-rounded btn-action"
                            [routerLink]="[
                                '/evento-gerenciar-config/' +
                                    evento?.configuracaoEventos.eventos
                                        .eventosId +
                                    '/' +
                                    evento?.configuracaoEventos
                                        ?.configuracaoEventosId,
                                {
                                    conta: true
                                }
                            ]"
                        ></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-evento>
                <p-table
                    [value]="[evento]"
                    dataKey="configContaId"
                    [globalFilterFields]="[]"
                >
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="">
                                Descrição <p-sortIcon field=""></p-sortIcon>
                            </th>
                            <th pSortableColumn="">
                                Cria Conta <p-sortIcon field=""></p-sortIcon>
                            </th>
                            <th pSortableColumn="">
                                Altera Receita?
                                <p-sortIcon field=""></p-sortIcon>
                            </th>
                            <th pSortableColumn="">
                                Receita Pós evento
                                <p-sortIcon field=""></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item>
                        <tr style="background-color: azure">
                            <td>{{ item.configConta.nomeCompleto }}</td>
                            <td>{{ item.criaConta }}</td>
                            <td>{{ item.alteraReceita }}</td>
                            <td>
                                {{
                                    item.codReceitaPosEvento
                                        ? item.codReceitaPosEvento
                                        : "-"
                                }}
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td
                                style="background-color: azure"
                                class="p-text-center"
                                colspan="10"
                            >
                                Nenhum registro encontrado
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td class="p-text-center" colspan="10">
                        Nenhum registro encontrado
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <p-paginator
            [first]="0"
            [rows]="20"
            [rowsPerPageOptions]="[20, 50, 100]"
            [totalRecords]="paginationConta.totalElements || 0"
            (onPageChange)="onPageChangeConta($event)"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="{first} - {last} de {totalRecords}"
        ></p-paginator>
    </div>
</p-card>

<!-- <p-button (click)="showDialog()" icon="pi pi-external-link" label="Show"></p-button>
<p-dialog header="Header" [(visible)]="visible" [style]="{width: '50vw'}" [maximizable]="true">
    <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    </p>
</p-dialog> -->

<p-dialog
    header="Evento da Conta Corrente"
    [(visible)]="visible"
    [style]="{ width: '34vw', height: '25vw' }"
    [maximizable]="true"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
            <strong>Data do Evento: </strong>
            <span>{{ detalheEvento?.dataEvento }}</span>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <strong>Nome: </strong>
            <span>{{
                detalheEvento?.configuracaoEventos.eventos?.descricao
            }}</span>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <strong>Data/Hora da criação: </strong>
            <span>{{ dataHora }}</span>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <strong>Permite DARE: </strong>
            <span> {{ detalheEvento?.permiteEmissaoDare }} </span>
        </div>
        <div
        class="p-field p-col-12 p-md-6"
        *ngIf="detalheEvento?.tipoNumeroDocumento?.descricao"
    >
        <strong>Tipo do Documento: </strong>
        <span>{{ detalheEvento?.tipoNumeroDocumento?.descricao }}</span>
    </div>
        <div
            class="p-field p-col-12 p-md-6"
            *ngIf="detalheEvento?.documentoOrigem"
        >
            <strong>Número do Documento: </strong>
            <span class="flex items-center">
                {{ detalheEvento?.documentoOrigem }}
                <i
                    class="fa-solid fa-download ml-2 cursor-pointer"
                    (click)="downloadFile(detalheEvento)"
                ></i>
            </span>
        </div>

        <div class="p-field p-col-12 p-md-6" *ngIf="detalheEvento?.lote">
            <strong>Lote: </strong>
            <span>{{ detalheEvento?.lote }}</span>
        </div>
        <div class="p-field p-col-12 p-md-6" *ngIf="detalheEvento?.ordem">
            <strong>Ordem: </strong>
            <span>{{ detalheEvento?.ordem }}</span>
        </div>
        <div class="p-field p-col-12 p-md-6" *ngIf="detalheEvento?.codReceita">
            <strong>Código de Receita: </strong>
            <span>{{ detalheEvento?.codReceita }}</span>
        </div>
        <div
            class="p-field p-col-12 p-md-6"
            *ngIf="detalheEvento?.situacaoCobranca"
        >
            <strong>Situação de Cobrança: </strong>
            <span>{{ detalheEvento?.situacaoCobranca?.descricao }}</span>
        </div>
        <div
        class="p-field p-col-12 p-md-6"
        *ngIf="detalheEvento?.baseLegal"
    >
        <strong>Base Legal: </strong>
        <span>{{ detalheEvento?.baseLegal }}</span>
    </div>
    </div>

    <ng-template pTemplate="footer">
        <p-button
            (click)="hiddenDialog()"
            icon="pi pi-angle-left"
            label="Voltar"
        ></p-button>
    </ng-template>
</p-dialog>

import { DatePipe } from "@angular/common";
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { ModalDetalheLancamentoContaComponent } from "src/app/modal/modal-detalhe-lancamento-conta/modal-detalhe-lancamento-conta.component";
import { ModalEventoContaCorrenteComponent } from "src/app/modal/modal-evento-conta-corrente/modal-evento-conta-corrente.component";
import { Pagination } from "src/app/shared/pagination/pagination";
import { AlertsUtil } from "src/app/utils/alerts/alerts.util";
import { ConsultaContaCorrenteService } from "../../services/consulta-conta-corrente.service";
@Component({
    selector: "ccp-detalhe-eventos-transacoes",
    templateUrl: "./detalhe-eventos-transacoes.component.html",
    styleUrls: ["./detalhe-eventos-transacoes.component.scss"],
    providers: [DatePipe],
})
export class DetalheEventosTransacoesComponent implements OnInit {
    @Input() conta;
    @Output() detalharConta: EventEmitter<any> = new EventEmitter();

    @ViewChild(ModalEventoContaCorrenteComponent, { static: false })
    modalEventoConta: ModalEventoContaCorrenteComponent;

    @ViewChild(ModalDetalheLancamentoContaComponent, { static: false })
    modalDetalheLancamentoConta: ModalDetalheLancamentoContaComponent;

    eventosAssociados: any[] = [];
    visibleAssociados: boolean = false;
    contaCorrenteId: number = null;
    transacaoList: any[] = [];
    eventosList: Pagination<any>;
    contaCorrenteEventoIdSelecionada: number = null;

    constructor(
        private consultaContaCorrenteService: ConsultaContaCorrenteService,
        private alertUtil: AlertsUtil,
        private datePipe: DatePipe
    ) {}

    ngOnInit(): void {
        this.conta = JSON.parse(localStorage.getItem("conta"));
        this.contaCorrenteId = this.conta?.contaCorrenteId;
        this.getDetalheEventos();
        this.getDetalheTransacoes();
    }

    getDetalheEventos(page = 0, size = 15, descricaoEvento = null) {
        this.consultaContaCorrenteService
            .getDetalheEventos(
                this.contaCorrenteId,
                page,
                size,
                descricaoEvento
            )
            .subscribe((response: any) => {
                this.eventosList = response;
                this.eventosList?.content?.forEach(
                    (e) =>
                        (e.dataEvento = this.datePipe.transform(
                            e.dataEvento,
                            "dd/MM/yyyy"
                        ))
                );
            });
    }

    paginar(event) {
        //const page = event.first / event.rows;
        //const descricaoEvento = event.filters?.global?.value;
        this.getDetalheEventos(event.page, event.rows, null);
    }

    /**cada clique de evento, exibe as transações por evento */
    getDetalheTransacoes() {
        this.consultaContaCorrenteService
            .getTransacoesAgrupadaPorEventoData(this.contaCorrenteId)
            .subscribe(
                (resp: any) => {
                    this.transacaoList = resp;
                },
                (error) => {
                    this.alertUtil.handleError(error);
                }
            );
    }

    buscaTransacoesVinculoEvento(contaCorrenteEventoId) {
        if (contaCorrenteEventoId === this.contaCorrenteEventoIdSelecionada) {
            this.contaCorrenteEventoIdSelecionada = null;
        } else {
            this.contaCorrenteEventoIdSelecionada = contaCorrenteEventoId;
        }
    }

    vinculoEvento(contaCorrenteEventoId) {
        return contaCorrenteEventoId === this.contaCorrenteEventoIdSelecionada;
    }

    openModalEvento(evento) {
        this.modalEventoConta.showDialog(evento);
    }

    openModalEventoAssociado(evento) {
        this.eventosAssociados = evento.eventosAssociados.map((e) => ({
            ...e,
            dataEvento: this.datePipe.transform(e.dataEvento, "dd/MM/yyyy"),
        }));
        this.visibleAssociados = true;
    }

    openModalLancamento() {
        this.modalDetalheLancamentoConta.showDialog();
    }

    converteMesAno(data) {
        let ano = data?.toString().substring(0, 4);
        let mes = data?.toString().substring(4, 6);

        return `${mes}/${ano}`;
    }

    detalharEventoConta(evento) {
        this.detalharConta.emit({
            contaCorrenteId: evento.contaCorrenteId,
            tipoConta: evento.tipoConta,
        });
    }
}
